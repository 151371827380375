import { Chip } from '@material-ui/core';
import Accordion from 'components/accordion';
import { AlertIcon } from 'components/icons/alert';
import React, { useEffect, useRef, useState } from 'react';
import { useBreakpoints } from 'utils/use-breakpoints';
import ResetFilter from '../all/reset-filter';
import { useFilters } from '../use-filters';
import { FilterAccordionProps } from './filter-accordion-props';
import './filter-accordion.scss';

export const FilterAccordion: React.FC<FilterAccordionProps> = ({
  title, id, chips, onChipDelete, children,
}) => {
  const { expandedFilter, setExpandedFilter, errors } = useFilters();
  const [expanded, setExpanded] = useState(false);
  const [overflowEnabled, setOverflowEnabled] = useState(false);
  const [overflowCount, setOverflowCount] = useState(0);
  const [actionProps, setActionProps] = useState<{ label: string, variant: 'outlined' | 'contained' }>({ variant: 'outlined', label: 'Close' });
  const [chipError, setChipError] = useState<number | undefined>(errors.get(id) || 0);
  const { phone, tablet, desktop } = useBreakpoints();
  const chipsRef = useRef<HTMLDivElement>(null);

  const getPanelSummaryContent = () => (
    <>
      {/** Display filters as chips on tablet+ */}
      {(!phone && !chipError)
        && (
          <div className="chips__container">
            <div
              className={`chips ${expanded ? 'chips--hidden' : ''}`}
              ref={chipsRef}
            >
              {
                chips.map(([key, value]: any) => (
                  // Map multi select dropdown values to multiple chips
                  Array.isArray(value)
                    ? value.map((v: any) => (
                      <Chip
                        key={key + v.id}
                        label={v.label || v.value}
                        onDelete={() => onChipDelete([key, v])}
                      />
                    ))
                    : (
                      <Chip
                        key={key + value.id}
                        label={value.label || value.value}
                        onDelete={() => onChipDelete([key, value])}
                      />
                    )
                ))
              }
            </div>
          </div>
        )}
      {/** "x filters applied" instead of chips on phone */}
      {!!(!expanded && phone && chips.length && !chipError)
        && (
          <div className="filters__container">{chips.length} filter{chips.length === 1 ? '' : 's'} applied</div>
        )}
      {/** Display the filter overflow count */}
      {!expanded && overflowEnabled && !chipError
        && (
          <div className="overflow-count">+{overflowCount}</div>
        )}
      {/** Display an error message if one of chips (filter inputs) didn't pass the validation */}
      {
        !expanded && !!chipError
        && (
          <div className="filters__container">
            <p className="error">
              <AlertIcon />
              {phone ? <>Error</> : <>Error. Please review search criteria entered.</>}
            </p>
          </div>
        )
      }
    </>
  );

  const handleExpand = (e: boolean) => {
    setExpanded(e);
    if (e) {
      setExpandedFilter(id);
    } else if (!e && phone) {
      setExpandedFilter('');
    }
  };

  /**
   * Sets the props for the action button (full screen mode)
   */
  useEffect(() => {
    if (chips.length) {
      setActionProps({ variant: 'contained', label: 'Apply' });
    } else {
      setActionProps({ variant: 'outlined', label: 'Close' });
    }
    setChipError(errors.get(id));
  }, [chips, errors, id]);

  /**
   * Gets the chip overflow count by using the chip element offsetTop prop.
   *
   * Includes `phone`, `tablet`, `desktop` as deps to ensure this is run
   * whenever a breakpoint changes.
   */
  useEffect(() => {
    if (!expanded && chipsRef.current) {
      const list = Array.from(chipsRef.current.childNodes as NodeList);
      // If a chip div has an offsetTop > 30 it means it's in the overflow
      // and no longer visible, filter the list for these to get the count.
      const count = list.filter((c: any) => c.offsetTop > c.clientHeight).length;
      setOverflowCount(count);
      setOverflowEnabled(count >= 1);
    }
  }, [expanded, chips, phone, tablet, desktop]);

  /**
   * Sets the expanded state for this component when the expanded filter changes.
   */
  useEffect(() => {
    setExpanded(expandedFilter === id);
  }, [expandedFilter, id]);

  return (
    <div className="sg-filter-accordion">
      <Accordion
        title={title}
        expanded={expanded}
        panelSummaryContent={getPanelSummaryContent()}
        fullScreenHeaderAction={(<ResetFilter filterKey={id} />)}
        fullScreenFooterActionProps={actionProps}
        fullScreenFooterActionLabel={actionProps.label}
        enableFullScreenStickyHeader
        enableFullScreenFooterAction
        onExpand={(e) => handleExpand(e)}
      >
        {children}
      </Accordion>
    </div>
  );
};
