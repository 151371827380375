import {Exclude, Expose} from 'class-transformer';
import {UserRole} from "../../../providers/user";

@Exclude()
export class AccountDetails {
  @Expose()
  userId: string;

  @Expose()
  email: string;

  @Expose()
  firstName: string;

  @Expose()
  lastName: string;

  @Expose()
  phoneNumber: string;

  @Expose()
  addressLine1: string;

  @Expose()
  addressLine2: string;

  @Expose()
  postcode: string;

  @Expose()
  suburb: string;

  @Expose()
  state: string;

  @Expose()
  country: string;

  @Expose()
  website: string;

  @Expose()
  roles: UserRole[];

}
