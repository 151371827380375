/**
 * Represents the database - "I want to search [Database]".
 */
export enum Database {
  All = 0,
  TerminalAll = 2,
  TerminalShedders = 30,
  MaternalAll = 3,
  MaternalFirstCrossSire = 31,
  MaternalCorriedale = 15,
  MaternalPrimeSAMMs = 7,
  Merino = 5,
  Dohne = 28,
  Boer = 10,
}
