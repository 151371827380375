import { Button, Link } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useActive, useNavigation } from 'react-navi';
import './page-not-found.scss';

/**
 * Displays a not found message with a link to go back to dashboard.
 *
 * If not already, navigates to the `/404` route.
 */
export const PageNotFound: React.FC = () => {
  const navigation = useNavigation();
  const isActive = useActive('/404');

  useEffect(() => {
    if (!isActive) {
      navigation.navigate('/404', { replace: true });
    }
  }, [isActive, navigation]);

  return (
    <div className="sg-page-not-found">
      <img
        src="/images/404.svg"
        alt="404"
      />
      <h5>Oops</h5>
      <p>We can’t find the page you’re looking for.</p>
      <Link
        href="/search/dashboard"
        underline="none"
      >
        <Button>Back to dashboard</Button>
      </Link>
    </div>
  );
};
