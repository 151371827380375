import { Container, Grid } from '@material-ui/core';
import Analytics from 'components/analytics';
import CookieBanner from 'components/cookie-banner';
import routes from 'index.routes';
import PageNotFound from 'pages/page-not-found';
import { PrimeFilterProvider } from 'pages/search/prime/prime-context';
import { useUser } from 'providers/user/use-user';
import React, { Suspense } from 'react';
import { NotFoundBoundary, Router, View } from 'react-navi';
import Footer from '../footer';
import Header from '../header';
import './app.scss';
import AccountLinkingRedirector from '../../pages/account/account-linking-redirector';
import EmailChangeWarning from '../../pages/account/email-change-warning';

/**
 * Root application component.
 */
const App: React.FC = () => {
  const user = useUser();
  return (
    <Router
      routes={routes}
      context={{ user }}
    >
      <>
        <Analytics />
        <div
          className="sg-app"
          id="app"
        >
          <AccountLinkingRedirector />
          <Header />
          <main className="main">
            <Container
              className="wrapper"
              maxWidth={false}
            >
              <Grid
                className="app__grid"
                item
                xs={12}
              >
                <Suspense fallback={null}>
                  <NotFoundBoundary render={() => <PageNotFound />}>
                    <PrimeFilterProvider>
                      <View />
                    </PrimeFilterProvider>
                  </NotFoundBoundary>
                </Suspense>
              </Grid>
            </Container>
          </main>
          <EmailChangeWarning />
          <CookieBanner />
          <Footer />
        </div>
      </>
    </Router>
  );
};

export default App;

(App as any).whyDidYouRender = {
  customName: 'App',
};
