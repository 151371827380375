import {Exclude, Expose} from 'class-transformer';

@Exclude()
export class Auth0LinkingState {
  /**
   * Is the user linked to a Sheep Genetics User?
   */
  @Expose({name: 'isLinked'})
  isLinked: boolean;

  /**
   * Does the email exist as a user in Sheep Genetics?
   */
  @Expose({name: 'doesEmailExist'})
  doesEmailExist: boolean;

  isLoading: boolean;

}
