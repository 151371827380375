import React, { useState, useEffect } from 'react';
import './tooltip-button.scss';
import { ClickAwayListener, Tooltip, IconButton } from '@material-ui/core';
import { InfoOutlinedIcon } from 'components/icons/info-outlined';
import { InfoIcon } from 'components/icons/info';
import Fade from '@material-ui/core/Fade';
import { TooltipButtonProps } from './tooltip-button-props';

/**
 * An info icon button that displays a tooltip on click.
 * @param props
 */
export const TooltipButton: React.FC<TooltipButtonProps> = ({
  tooltip, placement, onTooltipOpen, tooltipOpen = false, type = 'absolute', ...props
}) => {
  const [open, setOpen] = useState(tooltipOpen);

  const popperOptions = {
    disablePortal: true,
    // disabled filp function which change tooltip position on samller devices
    modifiers: {
      flip: {
        enabled: false,
      },
      preventOverflow: {
        enabled: true,
        boundariesElement: 'window',
      },
    },
  };

  /**
   * Sets the open flag based on the current open state.
   *
   * Optionally call the open callback, if one was provided.
   * @param isOpen
   */
  const onToggle = (isOpen: boolean) => {
    setOpen(isOpen);
    if (onTooltipOpen) {
      onTooltipOpen(isOpen);
    }
  };

  useEffect(() => {
    setOpen(tooltipOpen);
  }, [tooltipOpen]);

  return (
    <>
      {type === 'absolute' && (
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => setOpen(false)}>
          <Tooltip
            {...props}
            className="sg-tooltip sg-tooltip--absolute"
            title={tooltip}
            arrow
            PopperProps={{ ...popperOptions }}
            TransitionComponent={Fade}
            open={open}
            onClose={() => setOpen(false)}
            placement={placement || 'top'}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <IconButton
              className="sg"
              size="small"
              aria-label="info"
              onClick={() => setOpen(!open)}
            >
              {!open && (<InfoOutlinedIcon />)}
              {open && (<InfoIcon />)}
            </IconButton>
          </Tooltip>
        </ClickAwayListener>
      )}
      {type === 'relative' && (
        <Tooltip
          {...props}
          className="sg-tooltip sg-tooltip--relative"
          title={tooltip}
          arrow
          PopperProps={{ ...popperOptions }}
          TransitionComponent={Fade}
          open={open}
          onClose={() => setOpen(false)}
          placement={placement || 'right'}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <span>
            <IconButton
              className="sg"
              size="small"
              aria-label="info"
              onClick={() => onToggle(!open)}
            >
              {!open && (<InfoOutlinedIcon />)}
              {open && (<InfoIcon />)}
            </IconButton>
          </span>
        </Tooltip>
      )}
    </>
  );
};
