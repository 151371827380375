import {StorageItem} from './storage-value';

/**
 * Stores caching time in minutes.
 */
let cacheTime: number;

if (process.env.REACT_APP_CACHE_TIME) {
  cacheTime = Number(process.env.REACT_APP_CACHE_TIME);
} else {
  cacheTime = 43200; // 30 days
}

const ls = window.localStorage;

export enum StorageKey {
  BottomPercentile = 'bottomPercentile',
  CookiePolicy = 'cookiePolicy',
  CustomASBVs = 'customASBVs',
  Display = 'display',
  FirstName = 'firstName',
  LastName = 'lastName',
  Prime = 'prime',
  UserState = 'UserState',
  Auth0LinkingState = 'Auth0LinkingState',
  hideEmailChangedMessage = 'hideEmailChangedMessage',

  /**
   * The user roles (user, admin etc).
   */
  Roles = 'roles',
  /**
   * The user JWT.
   */
  TopPercentile = 'topPercentile',
  /**
   * The id of the the logged in user.
   */
  UserId = 'userId',
  /**
   * The current report context.
   */
  ReportContext = 'ReportContext',
}

/**
 * Returns new date that is further in future by selected amount of minutes
 * @param date base date
 * @param minutes amount of minutes to add to base date
 */
const addMinutes = (date: Date, minutes: number) => date.getTime() + minutes * 60000;

/**
 * Saves data in local storage
 * @param key data's key to be stored under
 * @param data set of elements to save in local storage
 * @param cacheDuration The number of minutes this is stored for.
 */
export const addToStorage = (key: string, data: any, cacheDuration: number = cacheTime) => {
  const date = new Date();
  // sets expiry date for x minutes upwards
  const expires = addMinutes(date, cacheDuration);

  // creates data object
  const storageItem: StorageItem = {
    expires,
    data,
  };
  // creates string to save on local storage
  const dataToStore = JSON.stringify(storageItem);
  ls.setItem(key, dataToStore);
};

/**
 * Removes the value from storage for the provided key.
 * @param key
 */
export const removeFromStorage = (key: string) => {
  ls.removeItem(key);
};

/**
 * Deletes selected data from local storage if expired
 * @param key key to recognize which data to delete
 */
export const deleteExpiredData = (key: string) => {
  const date = new Date();
  const data = ls.getItem(key);
  const now = date.getTime();
  if (data) {
    const dataObj = JSON.parse(data);

    const { expires } = dataObj;

    // checks if data expired
    if (expires && now > expires) {
      ls.removeItem(key);
    }
  }
};

/**
 * Gets selected data from local storage.
 *
 * If the value accessed has expired, it will be deleted from local storage.
 * @param key key to retrieved particular data set
 */
export const getFromStorage = <T>(key: string): T => {
  let storageItem;
  deleteExpiredData(key);
  const stringData = ls.getItem(key);
  if (stringData) {
    storageItem = JSON.parse(stringData) as StorageItem;
  }
  return storageItem?.data as T;
};

/**
 * Returns an array filtered by the api cache prefix - `sg/`.
 * @param keys
 */
export const getApiStorageKeys = (keys: string[]) => keys.filter((k) => k.includes('sg/'));

/**
 * Removes any user details from local storage.
 */
export const removeUserDetails = () => {
  removeFromStorage(StorageKey.UserId);
  removeFromStorage(StorageKey.FirstName);
  removeFromStorage(StorageKey.LastName);
  removeFromStorage(StorageKey.Roles);
  removeFromStorage(StorageKey.Auth0LinkingState);
  removeFromStorage(StorageKey.hideEmailChangedMessage);
};

/**
 * Removes API results from localStorage.
 */
export const removeApiResults = () => {
  // Filter local storage keys to only return api results (prefixed with sg//)
  const keys = getApiStorageKeys(Object.keys(window.localStorage));
  keys.forEach((k) => {
    window.localStorage.removeItem(k);
  });
};

/**
 * Removes the user details and the API results from localStorage.
 */
export const removeUserFromStorage = () => {
  removeUserDetails();
  removeApiResults();
};

/**
 * Compares the user details to those currently in local storage.
 * Clears all API results and user details from localStorage if:
 * - The user id is not the same as the stored id
 * @param userId The user id to compare to the one from storage.
 */
export const checkUserStorage = (userId: string) => {
  const id = getFromStorage<string>(StorageKey.UserId);

  const clear = id !== userId;

  if (clear) {
    removeUserDetails();
    removeApiResults();
  }
};
