import {getDefaultCache} from 'api/api-utils';
import axios from 'axios';
import {environment} from 'environment';
import qs from 'qs';

/**
 * Axios instance for all `auth` api requests.
 *
 * An `axios` instance utilising `axios-cache-adapter` and `localforage` for
 * caching responses to localStorage.
 */

const authApi = axios.create({
  adapter: getDefaultCache().adapter,
  baseURL: `${environment.endpoint('auth')}/auth/api`,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

export default authApi;
