import { Link, Snackbar, SnackbarContent } from '@material-ui/core';
import Button from 'components/button';
import React, { useEffect, useState } from 'react';
import { addToStorage, getFromStorage, StorageKey } from 'utils/storage-utils';
import './cookie-banner.scss';

/**
 * Displays a cookie policy banner at the bottom of the screen if the policy
 * has not been accepted. When accepted, a flag gets stored in local storage.
 */
export const CookieBanner: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);

  /**
   * Accepts the cookie policy, stores the flag in local storage and hides the toast.
   */
  const accept = () => {
    setShow(false);
    addToStorage(StorageKey.CookiePolicy, true, 9999999);
  };

  /**
   * Creates a ReactNode for the cookie policy text and links.
   */
  const getCookieMessage = () => (
    <>
      <span>We use cookies on our website (including third party cookies) to analyse traffic, share information with analytics partners and provide you with the best experience possible. For details see our </span>
      <Link
        href="http://www.sheepgenetics.org.au/Privacy-Policy"
        target="_blank"
        rel="noopener"
        color="secondary"
      >
        Privacy policy
      </Link>
      <span> and our </span>
      <Link
        href="https://www.mla.com.au/general/mla-cookies-policy/"
        target="_blank"
        rel="noopener"
        color="secondary"
      >
        Cookies policy
      </Link>
      <span> which were last updated in March 2019. By continuing to browse this site or by accepting below, you consent to the use of cookies.</span>
    </>
  );

  /**
   * Determines whether the policy has been accepted by getting the value from local storage.
   */
  useEffect(() => {
    const accepted = getFromStorage<boolean>(StorageKey.CookiePolicy);
    setShow(!accepted);
  }, []);

  return (
    <Snackbar
      className="sg-cookie-banner"
      open={show}
      autoHideDuration={null}
      ClickAwayListenerProps={{
        mouseEvent: false,
        touchEvent: false,
      }}
      onClose={() => setShow(false)}
    >
      <SnackbarContent
        message={getCookieMessage()}
        action={(
          <Button
            variant="text"
            onClick={() => accept()}
          >
            Accept
          </Button>
        )}
      />
    </Snackbar>
  );
};
