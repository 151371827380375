export enum ProgenyConstants {
  Title = 'Progeny',
  Id = 'progeny',
  SireDamLabel = 'Sire / Dam',
  SireDamValueAny = 'Any',
  SireDamValueSire = 'Sire',
  SireDamValueDam = 'Dam',
  NumberProgenyLabel = 'No. of Progeny',
  NumberProgenyPlaceholder = 'Min. number',
  CurrentDropLabel = 'Progeny in current drop',
  CurrentDropTooltip = 'Has progeny born in the current year drop in the database',
  MoreThanOneFlockLabel = 'Progeny in more than one flock',
  MoreThanOneFlockTooltip = 'Animal has progeny in more than one flock providing linkage across the database'
}
