/**
 * Represents the step of entering filters to viewing results.
 */
export enum FiltersStep {
  /**
   * Only the "prime" filters are shown, the user type, data base and traits
   */
  Initial = 0,
  /**
   * The full set of filters are visible.
   */
  Full = 1,
  /**
   * The filters are in a condensed state and the results are visible.
   */
  Results = 2,
  /**
   * Displays the recommended/saved searches.
   */
  Dashboard = 3,
}
