/**
 * Represents the sub index, a sub section from a selected database.
 */
export enum SubIndex {
  MaintainingEQ = 1,
  ImprovingEQ = 2,
  ImprovingWorm = 3,
  CarcaseGrowth = 4,
  ReproductionCarcaseGrowth = 5,
  FineWool = 6,
  WoolProduction = 15,
  SustainableMerino = 8,
  MerinoLamb = 7
}
