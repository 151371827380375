import { Exclude, Expose } from 'class-transformer';
import { ApiError } from '../api-error';

/**
 * The response of auth request.
 */
@Exclude()
export class AuthResponse {
  @Expose()
  success: boolean;

  @Expose()
  errors: ApiError[];
}

@Exclude()
export class AddPermissionResponse {
  @Expose()
  success: boolean;

  @Expose()
  errors: ApiError[];

  @Expose()
  impersonator: impersonator
}
@Exclude()
export class AddFlockAccessResponse {
  @Expose()
  success: boolean;

  @Expose()
  errors: ApiError[];

  @Expose()
  flockAccess: {
    flockCode: string;
    userId: number;
    firstName: string;
    lastName: string;
    email: string;
  };
}

@Exclude()
export class impersonator {
  @Expose()
  impersonatorId: number;

  @Expose()
  impersonatorEmail: string;

  @Expose()
  impersonatorName: string;
}
