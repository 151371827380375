export enum ErrorMessage {
  EmailRequired = 'Please enter your email address',
  EmailValid = 'Must be a valid email address',
  FieldMaxLength = 'Field cannot exceed {0} characters',
  InvalidSireDamID = 'Must be a 16 digit alphanumeric animal ID',
  InvalidStudNameFlockID = 'Must be either stud name up to 40 characters, or a 6 digit flock code',
  InvlaidAnimalID = 'Must be last 6 digit of animal ID if entered with Stud name, or a 16 digit animal alphanumeric ID. Multiple IDs must be separated by comma, with a maximum of 20 animals.',
  OptionsSelectOne = 'Please select one or more of the options',
  PasswordPattern = 'Password must be min 8 characters, containing at least 1 upper-case letter, 1 lower case letter and 1 number',
  PasswordRequired = 'Please enter your password',
  PostcodePattern = 'Must be a 4 digit postcode',
  PriceInvalidFormat = 'Price must be postive number up to 5 digits and can include 2 decimal places',
  RegisterFieldRequired = 'This field must be completed for registration',
  RegisterPasswordMatch = 'Retyped password must match password entered',
  RegisterTermsAgree = 'Please agree to the terms and conditions',
  RequiredField = 'This field is required',
  SaveSearchNameRequired = 'Please enter a name for your saved search',
  UploadRequiredTemplate = 'Please upload the supplied template ',
  UnknownTryAgain = 'We\'re experiencing some technical issues. Please try again',
  Auth0NotLoggedIn = 'We\'re experiencing issues with obtaining your login details. Please try again',
  Auth0EmailNotVerified = 'Your email address has not been verified with myMLA Please check your email for a verification link',
  InvalidNewPassword = 'Your new password must not be the same as old password',
}
