import {setupCache} from 'axios-cache-adapter';
import localforage from 'localforage';
import {isNullOrUndefined} from 'utils/utils';

/**
 * Gets the default store config for the api Axios instance.
 */
export const getDefaultStore = () => localforage.createInstance({
  driver: localforage.LOCALSTORAGE,
  name: 'sg',
});

/**
 * Gets the default cache config for the api Axios instance.
 */
export const getDefaultCache = () => setupCache({
  maxAge: 1000 * 60 * 60, // Default cache to 1 hour (same as session duration)
  exclude: { query: false },
  store: getDefaultStore(),
});

const isValidParam = (value: any) => {
  if (typeof value !== 'number') {
    return !isNullOrUndefined(value) && value !== '';
  }
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(value);
};

/**
 * Returns a new object where only params that are defined will be included.
 *
 * Excludes values that are:
 * - undefined
 * - null
 * - NaN
 * @param params
 */
export const createParams = (params: { [key: string]: any }) => {
  const p: any = {};
  Object.entries(params).forEach(([key, value]) => {
    if (isValidParam(value)) {
      p[key] = value;
    }
  });
  return p;
};
