export enum IndividualConstants {
  Title = 'Individual',
  Id = 'individual',
  FlockStudNameLabel = 'Stud/Flock name or digits',
  FlockStudNamePlaceholder = 'Stud name or flock code',
  BreederLabel = 'Breeder\'s Email',
  BreederPlaceholder = 'Breeder Email',
  AnimalIdLabel = 'Animal ID',
  AnimalIdPlaceholderLong = '16 digits or last 6 digits',
  AnimalIdPlaceholderShort = 'Last 6 digits',
  StudFlockIdNoResults = 'Sorry there are no animals matching your flock ID or name',
  BreederNoResults = 'Sorry there are no breeders matching your email or username',
}
