import { useCallback } from 'react';
import { FiltersAction } from '../filters/filters-action';
import { usePrimeContext } from './prime-context';

/**
 * Allows access to the prime filters state along with helper functions to dispatch updates.
 */
export const usePrime = () => {
  const { state, dispatch } = usePrimeContext();

  const updatePrime = useCallback((primeFilters: any) => dispatch({
    type: FiltersAction.Update,
    payload: primeFilters,
  }), [dispatch]);

  const resetPrime = useCallback(() => dispatch({
    type: FiltersAction.ResetAll,
  }), [dispatch]);

  /**
   * Resets the selected search type.
   */
  const resetSearchType = useCallback(() => dispatch({
    type: FiltersAction.ResetSearchType,
  }), [dispatch]);

  return {
    primeFilters: state,
    db: state.db,
    traits: state.traits,
    updatePrime,
    resetPrime,
    resetSearchType,
  };
};
