import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { validSireDamId, validStudFlockID, getErrorCount } from 'utils/validation';
import { ErrorMessage } from 'constants/error-message.enum';
import { TextInput } from 'components/text-input/text-input';
import FilterAccordion from '../../filter-accordion';
import { getChips, removeChip } from '../../filters-utils';
import { useFilters } from '../../use-filters';
import { pedigreeDefaultState } from './pedigree-utils';
import { PedigreeFilter } from './pedigree-filter.enum';
import { PedigreeConstants } from './pedigree-constants.enum';
import { Filters } from '../../filters.enum';


export const Pedigree: React.FC = () => {
  const {
    pedigree, updatePedigreeFilters, errors, updateFiltersError,
  } = useFilters();
  const actualErState: number = errors.get(Filters.Pedigree) || 0;

  /**
   * Updates the filters state when a filter value changes.
   * @param filter The filter to update.
   * @param value The new value from the filter.
   */
  const filterChange = (filter: PedigreeFilter, value: any) => {
    const pedigreeFilters = new Map(pedigree);
    pedigreeFilters.set(filter, value);
    updatePedigreeFilters(pedigreeFilters);
  };

  /**
   * Updates filters errors
   * @param isValid is value valid
   * @param wasValid was value valid
   */
  const updateFilterErrors = (isValid: any, wasValid: any) => {
    const errorCount = getErrorCount(isValid, wasValid, actualErState);
    const pedigreeError = new Map(errors);
    pedigreeError.set(Filters.Pedigree, errorCount);
    updateFiltersError(pedigreeError);
  };

  return (
    <Container>
      <FilterAccordion
        title={PedigreeConstants.Title}
        id={Filters.Pedigree}
        chips={getChips(pedigree, pedigreeDefaultState)}
        onChipDelete={([key, filter]) => filterChange(key, removeChip(key, filter, pedigree, pedigreeDefaultState))}
      >
        <Grid className="help-container" container item xs={12}>
          <div className="text--sm">Search for animals that have a sire with a particular stud/flock name, sire and/or dam in their pedigree</div>
        </Grid>
        <Grid className="container--margin">
          <Grid container item xs={12}>
            <Grid className="filter-grid" container item xs={12} sm={6} md={3}>
              <TextInput
                label={PedigreeConstants.SireStudNameLabel}
                placeholder={PedigreeConstants.SireStudNamePlaceholder}
                value={pedigree.get(PedigreeFilter.SireStudName)?.value}
                helperText={ErrorMessage.InvalidStudNameFlockID}
                validationRule={validStudFlockID}
                validatedValue={(value, isValid, wasValid) => {
                  filterChange(PedigreeFilter.SireStudName, {
                    ...pedigree.get(PedigreeFilter.SireStudName),
                    value,
                  });
                  updateFilterErrors(isValid, wasValid);
                }}
              />
            </Grid>
            <Grid className="filter-grid" container item xs={12} sm={6} md={3}>
              <TextInput
                label={PedigreeConstants.SireIdLabel}
                placeholder={PedigreeConstants.SirePlaceholder}
                value={pedigree.get(PedigreeFilter.SireID)?.value}
                helperText={ErrorMessage.InvalidSireDamID}
                validationRule={validSireDamId}
                validatedValue={(value, isValid, wasValid) => {
                  filterChange(PedigreeFilter.SireID, {
                    ...pedigree.get(PedigreeFilter.SireID),
                    value,
                  });
                  updateFilterErrors(isValid, wasValid);
                }}
              />
            </Grid>
            <Grid className="filter-grid" container item xs={12} sm={6} md={3}>
              <TextInput
                label={PedigreeConstants.DamIdLabel}
                placeholder={PedigreeConstants.DamIdPlaceholder}
                value={pedigree.get(PedigreeFilter.DamID)?.value}
                helperText={ErrorMessage.InvalidSireDamID}
                validationRule={validSireDamId}
                validatedValue={(value, isValid, wasValid) => {
                  filterChange(PedigreeFilter.DamID, {
                    ...pedigree.get(PedigreeFilter.DamID),
                    value,
                  });
                  updateFilterErrors(isValid, wasValid);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FilterAccordion>
    </Container>
  );
};
