/**
 * Id for a percentile highlight range.
 */
export enum PercentileHighlight {
  /**
   * Percentile <= 5%.
   */
  Top5 = 1,
  /**
   * Percentile <= 10%.
   */
  Top10 = 2,
  /**
   * Percentile <= 20%.
   */
  Top20 = 3,
  /**
   * Ignores percentile.
   */
  BottomNone = 4,
  /**
   * Percentile >= 90%.
   */
  Bottom10 = 5,
  /**
   * Percentile >= 80%.
   */
  Bottom20 = 6,
}
