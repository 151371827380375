import { getPercentileName } from 'pages/search/filters/all/asbvs/asbvs-utils';
import { assignPercentileRange, displayPercentileTag } from 'pages/search/results/results-utils';
import React, { memo, useEffect, useState } from 'react';
import { PercentileTagProps } from './percentile-tag-props';
import './percentile-tag.scss';

export const PercentileTag: React.FC<PercentileTagProps> = memo(({
  percentile, isRbv, hasLeader, selectedTop, selectedBottom, checkVisiblity, isRange,
}) => {
  const [show, setShow] = useState<boolean>(true);
  const [displayPercentile, setDisplayPercentile] = useState<number>();
  const [percentileLabel, setPercentileLabel] = useState<string>('');

  useEffect(() => {
    if (checkVisiblity) {
      const display = percentile !== null && (isRbv || displayPercentileTag(selectedTop, selectedBottom, percentile, hasLeader));
      setShow(display);
    }

    // When it is a range percentile tag, 0-5 percentile will labeled as 5%
    // When it is not range percentile tag, it will be lables as percentile%
    setDisplayPercentile(isRange ? assignPercentileRange(percentile) : percentile);
  }, [percentile, checkVisiblity, isRbv, hasLeader, selectedTop, selectedBottom, isRange]);

  useEffect(() => {
    setPercentileLabel(getPercentileName(displayPercentile));
  }, [displayPercentile]);

  return (
    <span className={`sg-percentile-tag highlight--${isRbv ? 'rbv' : displayPercentile} ${!show ? 'sg-percentile-tag--hidden' : ''} `}>
      {isRbv ? 'RBV' : percentileLabel}
    </span>
  );
});
