import { ASBVPercentiles } from 'api/query/asbv';
import { validASBVValue, validASBVPercentile } from 'utils/validation';
import { ASBVsFilterValue } from './asbvs-filter-value';
import { ASBVsConstants } from './asbvs-constants.enum';

export const getDisplayedPercentiles = (percentiles: ASBVPercentiles) => percentiles.percentiles.filter((p) => p.percentileType === 100
  || p.percentileType <= 50).reverse();

/**
 * Gets the percentile display value.
 * @param percentile
 */
export const getPercentileName = (percentile?: number) => {
  switch (percentile) {
    case 100:
      return 'BOTTOM';
    case 90:
      return 'BOTTOM 10%';
    case 80:
      return 'BOTTOM 20%';
    case 0:
      return 'TOP';
    default:
      return `TOP ${percentile}%`;
  }
};

/**
 * Gets the regex validation for the ASBV min/max values.
 * @param type
 */
export const validateASBVMinMax = (type: 'value' | 'percentile' | undefined, value: string) => {
  switch (type) {
    case 'value':
    default:
      return validASBVValue;
    case 'percentile':
      return validASBVPercentile;
  }
};

/**
 * Gets the placeholder text for the min/max inputs, by type.
 * @param type
 */
export const getMinMaxPlaceholder = (type: 'value' | 'percentile' | undefined) => {
  switch (type) {
    case 'value':
    default:
      return ASBVsConstants.MinMaxPlaceholderValue;
    case 'percentile':
      return ASBVsConstants.MinMaxPlaceholderPercentile;
  }
};

/**
 * Gets the error text for the ASBV min/max values.
 */
export const getMinMaxErrorText = (type: 'value' | 'percentile' | undefined) => {
  switch (type) {
    case 'value':
    default:
      return ASBVsConstants.ErrorMinMaxValue;
    case 'percentile':
      return ASBVsConstants.ErrorMinMaxPercentile;
  }
};

/**
 * Converts an array of ASBVs to a map.
 * @param asbvs
 */
export const asbvsToMap = (asbvs: ASBVsFilterValue[]) => {
  const mappedASBVs = asbvs.map((a) => [a.asbv, a]) as any;
  const filters = new Map<string, ASBVsFilterValue>(mappedASBVs);
  return filters;
};

export const asbvsDefaultState = new Map<string, ASBVsFilterValue>();
