import { Database } from '../../../../../shared/database.enum';
import { CheckboxFilter } from '../../models/checkbox-filter';
import { DropdownFilter } from '../../models/dropdown-filter';
import { DropdownFilterItem } from '../../models/dropdown-filter-item';
import { BasicConstants } from './basic-constants.enum';
import { BasicFilter } from './basic-filter.enum';
import { Sex } from './sex.enum';

/**
 * Gets the available breeds.
 */
export const getBreeds = (database: Database): DropdownFilterItem[] => {
  const breeds = [
    // Terminal - All
    { id: '23', value: 'WHITE SUFFOLK', db: Database.TerminalAll },
    { id: '16', value: 'POLL DORSET', db: Database.TerminalAll },
    { id: '25', value: 'AUSTRALIAN WHITE', db: Database.TerminalAll },
    { id: '39', value: 'BOOROOLA', db: Database.TerminalAll },
    { id: 'CH', value: 'CHAROLLAIS', db: Database.TerminalAll },
    { id: 'CT', value: 'COMPOSITE TERMINALS', db: Database.TerminalAll },
    { id: '05', value: 'COOLALEE', db: Database.TerminalAll },
    { id: '40', value: 'DORPER', db: Database.TerminalAll },
    { id: '33', value: 'DORSET DOWN', db: Database.TerminalAll },
    { id: '04', value: 'DORSET HORN', db: Database.TerminalAll },
    { id: '07', value: 'GROMARK', db: Database.TerminalAll },
    { id: '11', value: 'HAMPSHIRE DOWN', db: Database.TerminalAll },
    { id: '84', value: 'NZ DORSET HORN', db: Database.TerminalAll },
    { id: '86', value: 'NZ POLL DORSET', db: Database.TerminalAll },
    { id: '99', value: 'NZ SOUTHDOWN', db: Database.TerminalAll },
    { id: '88', value: 'NZ STH SUFFOLK', db: Database.TerminalAll },
    { id: '89', value: 'NZ SUFFOLK', db: Database.TerminalAll },
    { id: '87', value: 'NZ TEXEL', db: Database.TerminalAll },
    { id: '34', value: 'RYELAND', db: Database.TerminalAll },
    { id: '35', value: 'SOUTH HAMPSHIRE', db: Database.TerminalAll },
    { id: '21', value: 'SOUTH SUFFOLK', db: Database.TerminalAll },
    { id: '14', value: 'SOUTHDOWN', db: Database.TerminalAll },
    { id: '19', value: 'SUFFOLK', db: Database.TerminalAll },
    { id: '42', value: 'SUFFOLK CANADA', db: Database.TerminalAll },
    { id: '17', value: 'TEXEL', db: Database.TerminalAll },
    { id: '37', value: 'TEXEL DOWNS', db: Database.TerminalAll },
    { id: '24', value: 'ULTRAWHITE', db: Database.TerminalAll },
    { id: '47', value: 'WHITE DORPERS', db: Database.TerminalAll },
    { id: '12', value: 'WILTIPOLL', db: Database.TerminalAll },
    { id: '09', value: 'WILTSHIRE HORN', db: Database.TerminalAll },
    { id: 'XB', value: 'XBRED', db: Database.TerminalAll },
    { id: 'IF', value: 'ILE DE FRANCE', db: Database.TerminalAll },
    // Terminal - Shedders
    { id: '25', value: 'AUSTRALIAN WHITE', db: Database.TerminalShedders },
    { id: 'CS', value: 'COMPOSITE SHEDDING', db: Database.TerminalShedders },
    { id: '40', value: 'DORPER', db: Database.TerminalShedders },
    { id: '24', value: 'ULTRAWHITE', db: Database.TerminalShedders },
    { id: '47', value: 'WHITE DORPERS', db: Database.TerminalShedders },
    // Maternal - All
    { id: '02', value: 'BORDER LEICESTER', db: Database.MaternalAll },
    { id: 'CM', value: 'COMPOSITE MATERNALS', db: Database.MaternalAll },
    { id: '15', value: 'COOPWORTH', db: Database.MaternalAll },
    { id: '06', value: 'BOND', db: Database.MaternalAll },
    { id: '39', value: 'BOOROOLA', db: Database.MaternalAll },
    { id: 'CS', value: 'COMPOSITE SHEDDING', db: Database.MaternalAll },
    { id: '03', value: 'CORRIEDALE', db: Database.MaternalAll },
    { id: '49', value: 'DAMARA', db: Database.MaternalAll },
    { id: '38', value: 'EAST FRIESIAN', db: Database.MaternalAll },
    { id: '31', value: 'ENGLISH LEICESTER', db: Database.MaternalAll },
    { id: '43', value: 'FINN LEICESTERS', db: Database.MaternalAll },
    { id: '22', value: 'FINNSHEEP', db: Database.MaternalAll },
    { id: '07', value: 'GROMARK', db: Database.MaternalAll },
    { id: '08', value: 'HYFER', db: Database.MaternalAll },
    { id: '82', value: 'NZ BORDER LEICESTER', db: Database.MaternalAll },
    { id: '75', value: 'NZ COOPWORTH', db: Database.MaternalAll },
    { id: '83', value: 'NZ CORRIEDALE', db: Database.MaternalAll },
    { id: '92', value: 'NZ FINNSHEEP', db: Database.MaternalAll },
    { id: '41', value: 'NZ ROMNEY', db: Database.MaternalAll },
    { id: '32', value: 'PERENDALE', db: Database.MaternalAll },
    { id: '18', value: 'ROMNEY', db: Database.MaternalAll },
    { id: 'SI', value: 'SIL', db: Database.MaternalAll },
    { id: '23', value: 'WHITE SUFFOLK', db: Database.MaternalAll },
    { id: 'XB', value: 'XBRED', db: Database.MaternalAll },
    // Maternal - Corriedale
    { id: '03', value: 'CORRIEDALE', db: Database.MaternalCorriedale },
    { id: '83', value: 'NZ CORRIEDALE', db: Database.MaternalCorriedale },
    // Maternal - First cross sire breeds
    { id: '02', value: 'BORDER LEICESTER', db: Database.MaternalFirstCrossSire },
    { id: '38', value: 'EAST FRIESIAN', db: Database.MaternalFirstCrossSire },
    // Maternal - Prime SAMM
    { id: '48', value: 'PRIME SAMM', db: Database.MaternalPrimeSAMMs },
    // Merino
    { id: '50', value: 'MERINO', db: Database.Merino },
    { id: '60', value: 'POLL MERINO', db: Database.Merino },
    { id: '74', value: 'NZ MERINO', db: Database.Merino },
    { id: '85', value: 'NZ POLL MERINO', db: Database.Merino },
    { id: '71', value: 'NZ MERINO COMPOSITE', db: Database.Merino },
    { id: '90', value: 'NZ POLWARTH', db: Database.Merino },
    { id: '10', value: 'POLWARTH', db: Database.Merino },
    { id: '72', value: 'SOUTH AMERICAN MERINO ', db: Database.Merino },
    // Dohne
    { id: '51', value: 'DOHNE MERINOS', db: Database.Dohne },
    // Dohne
    { id: '36', value: 'GOATS', db: Database.Boer },
  ];
  return breeds.filter((b) => b.db === database);
};

/**
 * Gets the 'breeds' dropdown placeholder value based on the selected database.
 * @param database
 */
export const getBreedPlaceholder = (database: Database) => {
  switch (database) {
    case Database.TerminalShedders:
      return 'All Shedders';
    case Database.MaternalPrimeSAMMs:
      return 'Prime SAMM';
    case Database.Dohne:
      return 'Dohne Merino';
    case Database.Boer:
      return 'Goats';
    default:
      return 'All Breeds';
  }
};

/**
 * Returns a breed value if one exists in the filters, otherwise will return
 * the default value for Prime SAMM, Dohne or Boer.
 *
 * This is available because both Dohne and Boer dropdowns don't have any values
 * but the specific breed id is required for the API else other breeds for
 * those databases will be returned with the results.
 * @param database
 * @param filters
 */
export const getBreedOrDefault = (database: Database, filters: Map<BasicFilter, any>) => {
  const breeds = filters.get(BasicFilter.Breed);

  // If a breed exists in the filters, return it.
  if (breeds.length) {
    return breeds.map((b: any) => b.id).toString();
  }

  // If no breed was selected, get the default value for Prime SAMM, Dohne and Boer.
  switch (database) {
    case Database.MaternalPrimeSAMMs:
    case Database.Dohne:
    case Database.Boer:
      return getBreeds(database)[0].id;
    default:
      return '';
  }
};

/**
 * Gets the available birth years.
 */
export const getBirthYears = (): DropdownFilterItem[] => {
  const d = new Date();
  const years: DropdownFilterItem[] = [];
  while (d.getFullYear() >= 1987) {
    years.push({ id: d.getFullYear().toString(), value: d.getFullYear().toString() });
    d.setFullYear(d.getFullYear() - 1);
  }
  return years;
};

/**
 * Gets the available sexes.
 */
export const getSexes = (): DropdownFilterItem[] => [
  { id: '0', value: Sex.Any },
  { id: '1', value: Sex.Male },
  { id: '2', value: Sex.Female },
];

/**
 * Gets the wool type filter values.
 */
export const getWoolTypes = (): DropdownFilterItem[] => [
  { id: 'MERI_1', value: 'Ultra/Superfine' },
  { id: 'MERI_2', value: 'Fine/Fine-Medium' },
  { id: 'MERI_3', value: 'Medium/Strong' },
];

/**
 * Gets the breeder group filter values.
 * @param database
 */
export const getBreederGroupValues = (database: Database): DropdownFilterItem[] => {
  switch (database) {
    case Database.TerminalAll:
      return [
        { id: '0', value: 'All groups' },
        { id: '2', value: 'Meat Elite' },
        { id: '3', value: 'SuperWhites' },
      ];
    case Database.MaternalAll:
    case Database.MaternalFirstCrossSire:
      return [
        { id: '0', value: 'All groups' },
        { id: '1', value: 'SuperBorders' },
      ];
    default:
      return [];
  }
};

/**
 * Gets the registered status filter values.
 */
export const getRegisteredStatus = (): DropdownFilterItem[] => [
  { id: '0', value: 'Any status' },
  { id: '1', value: 'Unregistered' },
  { id: '2', value: 'Registered' },
  { id: '3', value: 'Commercial' },
  { id: '4', value: 'No status' },
];

/**
 * Gets the dropdown filters for the selected database.
 * @param database
 */
export const getDropdowns = (database: Database): DropdownFilter[] => {
  const breeds = getBreeds(database);
  const birthYears = getBirthYears();
  const sexes = getSexes();

  const dropdowns: DropdownFilter[] = [
    {
      key: BasicFilter.Breed,
      label: 'Breeds',
      placeholder: getBreedPlaceholder(database),
      values: breeds,
      multiple: true,
      disabled: breeds.length <= 1,
      db: [Database.All],
    },
    {
      key: BasicFilter.BirthYear,
      label: 'Birth Year',
      placeholder: 'Any year',
      values: birthYears,
      multiple: true,
      db: [Database.All],
    },
    {
      key: BasicFilter.Sex,
      label: 'Sex',
      values: sexes,
      db: [Database.All],
    },
    {
      key: BasicFilter.WoolType,
      label: 'Wool Types',
      placeholder: 'All types',
      values: getWoolTypes(),
      multiple: true,
      db: [Database.Merino],
    },
    {
      key: BasicFilter.BreederGroup,
      label: 'Breeder Group Member',
      values: getBreederGroupValues(database),
      db: [Database.TerminalAll, Database.MaternalAll, Database.MaternalFirstCrossSire],
    },
    {
      key: BasicFilter.RegisteredStatus,
      label: 'Registered Status',
      values: getRegisteredStatus(),
      db: [Database.Dohne],
    },
  ];

  return dropdowns.filter((d) => d.db!.includes(database) || d.db!.includes(Database.All));
};

/**
 * Gets the checkbox filters for the selected database.
 * @param database
 */
export const getCheckboxes = (database: Database): CheckboxFilter[] => {
  const checkboxes: CheckboxFilter[] = [
    {
      key: BasicFilter.ForSale,
      label: BasicConstants.ForSaleLabel,
      tooltip: BasicConstants.ForSaleTooltip,
      db: [
        Database.All,
      ],
    },
    {
      key: BasicFilter.SemenAvailable,
      label: BasicConstants.SemenAvailableLabel,
      tooltip: BasicConstants.SemenAvailableTooltip,
      db: [
        Database.All,
      ],
    },
    {
      key: BasicFilter.ProgenyInReferenceFlock,
      label: BasicConstants.ProgenyInReferenceFlockLabel,
      tooltip: BasicConstants.ProgenyInReferenceFlockTooltip,
      db: [
        Database.TerminalAll, Database.TerminalShedders,
        Database.MaternalAll, Database.MaternalFirstCrossSire,
        Database.MaternalCorriedale, Database.MaternalPrimeSAMMs,
        Database.Merino, Database.Dohne,
      ],
    },
    {
      key: BasicFilter.GenomeTested,
      label: BasicConstants.GenomeTestedLabel,
      tooltip: BasicConstants.GenomeTestedTooltip,
      db: [
        Database.TerminalAll, Database.TerminalShedders,
        Database.MaternalAll, Database.MaternalFirstCrossSire,
        Database.Merino,
      ],
    },
    {
      key: BasicFilter.MerinoSuperiorSire,
      label: BasicConstants.MerinoSuperiorSireLabel,
      tooltip: BasicConstants.MerinoSuperiorSireTooltip,
      db: [
        Database.Merino,
      ],
    },
    {
      key: BasicFilter.PollHornResult,
      label: BasicConstants.PollHornResultLabel,
      tooltip: BasicConstants.PollHornResultTooltip,
      db: [
        Database.Merino,
      ],
    },
    {
      key: BasicFilter.MerinoLifetimeProductivity,
      label: BasicConstants.MerinoLifetimeProductivityLabel,
      tooltip: BasicConstants.MerinoLifetimeProductivityTooltip,
      db: [
        Database.Merino,
      ],
    },
  ];

  return checkboxes.filter((c) => c.db!.includes(database) || c.db!.includes(Database.All));
};

/**
 * Gets the initial state for the `basic` filters.
 *
 * Important: BreederGroup and RegisteredStatus has a default value set so that the
 * correct dropdown value is selected by default, however this causes these values to
 * be applied to the querystring when searching. Further work is required to
 * prevent these values being applied without removing the default value.
 */
export const basicDefaultState = new Map<BasicFilter, any>([
  [BasicFilter.Breed, []],
  [BasicFilter.BirthYear, []],
  [BasicFilter.Sex, getSexes()[0]],
  [BasicFilter.WoolType, []],
  [BasicFilter.BreederGroup, { value: 'All groups' }],
  [BasicFilter.RegisteredStatus, { value: 'Any status' }],
  [BasicFilter.FlockId, []],
  [BasicFilter.ForSale, { value: false, label: BasicConstants.ForSaleLabel }],
  [BasicFilter.SemenAvailable, { value: false, label: BasicConstants.SemenAvailableLabel }],
  [BasicFilter.ProgenyInReferenceFlock, { value: false, label: BasicConstants.ProgenyInReferenceFlockLabel }],
  [BasicFilter.GenomeTested, { value: false, label: BasicConstants.GenomeTestedLabel }],
  [BasicFilter.MerinoSuperiorSire, { value: false, label: BasicConstants.MerinoSuperiorSireLabel }],
  [BasicFilter.PollHornResult, { value: false, label: BasicConstants.PollHornResultLabel }],
  [BasicFilter.MerinoLifetimeProductivity, { value: false, label: BasicConstants.MerinoLifetimeProductivityLabel }],
]);
