import React, {
  memo, useCallback, useState, useEffect,
} from 'react';
import { Divider } from '@material-ui/core';
import { useFilters } from 'pages/search/filters/use-filters';
import { useBreakpoints } from 'utils/use-breakpoints';
import { AppNumConstants } from 'constants/app-num-constants.enum';
import ASBV from '../asbv';
import { ASBVsFilterValue } from '../asbvs-filter-value';
import { ASBVsSectionProps } from './asbv-section-props';
import { useASBVs } from '../use-asbvs';
import { asbvsToMap } from '../asbvs-utils';

/**
 * Displays a list of ASBVs.
 *
 * The selected ASBVs display as a list, mapped from the selectedASBVs array.
 * An additional ASBV section displays below, allowing selection of a new ASBV, up to a maximum.
 */
export const ASBVsSection: React.FC<ASBVsSectionProps> = memo(({ asbvs, type, getActualErCount }) => {
  const { updateASBVsFilters: updateASBVFilters } = useFilters();
  const {
    items, selectedTab, selectedASBVs, setSelectedASBVs, setFocussedASBV, removeASBVById, toggleTooltip,
  } = useASBVs();
  const [addVisible, setAddVisible] = useState(true);
  const { phone } = useBreakpoints();

  /**
   * Updates an ASBV and filters state.
   *
   * If an ASBV value has changed, show the tooltip.
   */
  const updateASBV = useCallback((asbv: ASBVsFilterValue, index: number) => {
    const selected = [...selectedASBVs];
    selected[index] = asbv;
    // Only set focus if the dropdown value has changed
    if (asbv.asbv !== selectedASBVs[index].asbv) {
      if (!phone) {
        toggleTooltip(asbv);
      }
      setFocussedASBV(asbv);
    }
    setSelectedASBVs(selected);

    updateASBVFilters(asbvsToMap(selected), selectedTab);
  }, [phone, selectedASBVs, selectedTab, setFocussedASBV, setSelectedASBVs, toggleTooltip, updateASBVFilters]);

  /**
   * Adds a new ASBV to the selected ASBVs.
   */
  const addASBV = useCallback((asbv: ASBVsFilterValue) => {
    setAddVisible(false);
    const selected = [...selectedASBVs, asbv];
    setFocussedASBV(asbv);

    setSelectedASBVs(selected);
    if (!phone) {
      toggleTooltip(asbv);
    }
    updateASBVFilters(asbvsToMap(selected), selectedTab);
  }, [phone, selectedASBVs, selectedTab, setFocussedASBV, setSelectedASBVs, toggleTooltip, updateASBVFilters]);

  /**
   * Removes an ASBV and updates the filters.
   * @param asbvId
   */
  const deleteASBV = (asbvId: string) => {
    updateASBVFilters(asbvsToMap(removeASBVById(asbvId)), selectedTab);
  };

  /**
   * Shows the add new ASBV section.
   */
  useEffect(() => {
    setAddVisible(true);
  }, [selectedASBVs.length]);

  return (
    <>
      {selectedASBVs.map((a, index) => (
        <div key={a.asbv}>
          <ASBV
            asbv={a}
            asbvs={asbvs}
            items={items}
            index={index}
            type={type}
            onUpdate={(updatedASBV, i) => updateASBV(updatedASBV, i)}
            onDelete={(i) => deleteASBV(i)}
            getActualErCount={getActualErCount}
          />
          <Divider variant="middle" className="asbv-divider" />
        </div>
      ))}
      {addVisible && selectedASBVs.length < AppNumConstants.MaxASBVs && (
        <ASBV
          asbvs={asbvs}
          items={items}
          index={-1}
          onAdd={(newASBV) => addASBV(newASBV)}
          getActualErCount={getActualErCount}
        />
      )}
    </>
  );
});
