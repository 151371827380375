import { CascadingMenuItem } from '../../../components/cascading-menu/cascading-menu-item';
import { Database } from '../../../shared/database.enum';
import { SubIndex } from '../../../shared/sub-index.enum';
import { UserType } from '../filters/user-type.enum';
import { User } from './user.enum';

export const userTypes: CascadingMenuItem[] = [
  { value: User.Breeder, label: User.Breeder, id: UserType.Breeder },
  { value: User.Buyer, label: User.Buyer, id: UserType.Buyer },
  { value: User.ServiceProvider, label: User.ServiceProvider, id: UserType.ServiceProvider },
];

export const databaseTypes: CascadingMenuItem[] = [
  {
    id: 'Terminal breeds',
    label: 'Terminal breeds',
    value: 'Terminal breeds',
    subOptions: [
      { value: 'Terminal breeds - all', label: 'All', id: Database.TerminalAll },
      { value: 'Terminal breeds - shedders', label: 'Shedders', id: Database.TerminalShedders },
    ],
  },
  {
    id: 'Maternal breeds',
    label: 'Maternal breeds',
    value: 'Maternal breeds',
    subOptions: [
      { value: 'Maternal breeds - all', label: 'All', id: Database.MaternalAll },
      { value: 'Maternal breeds - first cross sire breeds', label: 'First cross sire breeds', id: Database.MaternalFirstCrossSire },
      { value: 'Maternal breeds - Corriedales', label: 'Corriedales', id: Database.MaternalCorriedale },
      { value: 'Maternal breeds - Prime SAMM', label: 'Prime SAMM', id: Database.MaternalPrimeSAMMs },
    ],
  },
  {
    value: 'Merinos',
    label: 'Merinos',
    id: Database.Merino,
  },
  {
    value: 'Dohnes',
    label: 'Dohnes',
    id: Database.Dohne,
  },
  {
    value: 'Goats',
    label: 'Goats',
    id: Database.Boer,
  },
];

export const getObjectives = (database: Database) => {
  const objectives = [
    {
      value: 'TCP Index - Maintaining eating quality and improving carcase, growth',
      label: 'Maintaining eating quality and improving carcase, growth',
      id: SubIndex.MaintainingEQ,
      db: Database.TerminalAll,
    },
    {
      value: 'EQ Index - Improving eating quality, carcase, growth',
      label: 'EQ Index - Improving eating quality, carcase, growth',
      id: SubIndex.ImprovingEQ,
      db: Database.TerminalAll,
    },
    {
      value: 'LEQ Index - Improving worm resistance, improving eating quality, carcase, growth',
      label: 'LEQ Index - Improving worm resistance, improving eating quality, carcase, growth',
      id: SubIndex.ImprovingWorm,
      db: Database.TerminalAll,
    },
    {
      value: 'Carcase, growth',
      label: 'Carcase, growth',
      id: SubIndex.CarcaseGrowth,
      db: Database.TerminalShedders,
    },
    {
      value: 'Reproduction, carcase, growth',
      label: 'Reproduction, carcase, growth',
      id: SubIndex.ReproductionCarcaseGrowth,
      db: Database.TerminalShedders,
    },
    {
      value: 'FW Index - Breeding finer wool',
      label: 'FW Index - Breeding finer wool',
      id: SubIndex.FineWool,
      db: Database.Merino,
    },
    {
      value: 'WP Index - Increased wool production',
      label: 'WP Index - Increased wool production',
      id: SubIndex.WoolProduction,
      db: Database.Merino,
    },
    {
      value: 'SM Index - Balanced wool and meat with a focus on welfare',
      label: 'SM Index - Balanced wool and meat with a focus on welfare',
      id: SubIndex.SustainableMerino,
      db: Database.Merino,
    },
    {
      value: 'ML Index - Prime lamb production from Merino ewes',
      label: 'ML Index - Prime lamb production from Merino ewes',
      id: SubIndex.MerinoLamb,
      db: Database.Merino,
    },
  ];

  return objectives.filter((o) => o.db === database);
};

/**
 * Gets the default user type from the menu items.
 * @param userType
 * @param userTypeItems
 */
export const getDefaultUserType = (userType: UserType, userTypeItems: CascadingMenuItem[]) => userTypeItems.find((u) => u.id === userType);

/**
 * Gets the default database from the menu items
 * @param database
 * @param databaseItems
 */
export const getDefaultDatabase = (database: Database, databaseItems: CascadingMenuItem[]) => {
  let db!: CascadingMenuItem;
  databaseItems.forEach((i) => {
    if (i.subOptions) {
      const found = i.subOptions.find((s) => s.id === database);
      if (found) {
        db = found;
      }
    } else if (i.id === database) {
      db = i;
    }
  });
  return db;
};

/**
 * Gets the default sub index (scenario) from the menu items.
 * @param subIndex
 * @param subIndexItems
 */
export const getDefaultSubIndex = (subIndex: SubIndex, subIndexItems: CascadingMenuItem[]) => subIndexItems.find((i) => i.id === subIndex);
