import { Action } from 'providers/action';
import { asbvsDefaultState } from './all/asbvs/asbvs-utils';
import { basicDefaultState } from './all/basic/basic-utils';
import { locationDefaultState } from './all/location/location-utils';
import { pedigreeDefaultState } from './all/pedigree/pedigree-utils';
import { progenyDefaultState } from './all/progeny/progeny-utils';
import { FilterCategory } from './filter-category.enum';
import { FiltersAction } from './filters-action';
import { FiltersState } from './filters-state';
import { errorsInitialState } from './filters-utils';
import { individualDefaultState } from './individual/individual-utils';

export const filtersReducer = (state: FiltersState, action: Action): FiltersState => {
  switch (action.type) {
    case FiltersAction.Update:
      return { ...state, ...action.payload };
    case FiltersAction.Reset:
      return {
        ...state,
        ...action.payload,
      };
    case FiltersAction.ResetAll:
      switch (action.payload) {
        case FilterCategory.Individual:
          return {
            ...state,
            individual: individualDefaultState,
            errors: errorsInitialState,
          };
        case FilterCategory.All:
        default:
          return {
            ...state,
            basic: basicDefaultState,
            progeny: progenyDefaultState,
            pedigree: pedigreeDefaultState,
            location: locationDefaultState,
            asbv: asbvsDefaultState,
            errors: errorsInitialState,
          };
      }
    case FiltersAction.SetASBVsTab:
      return {
        ...state,
        asbvsTab: action.payload,
      };
    case FiltersAction.SetExpandedFilter:
      return {
        ...state,
        expandedFilter: action.payload,
      };
    default:
      return state;
  }
};
