import { FilterValue } from 'api/query/shared/filter-value';
import { DropdownFilter, DropdownFilterItem } from '../..';
import { LocationConstants } from './location-constants.enum';
import { LocationFilter } from './location-filter.enum';

/**
 * Gets the values for the Location filter.
 */
export const getState = (): DropdownFilterItem[] => [
  { id: '0', value: LocationConstants.StateValueAny },
  { id: '1', value: LocationConstants.StateValueQLD },
  { id: '2', value: LocationConstants.StateValueNSW },
  { id: '3', value: LocationConstants.StateValueVIC },
  { id: '4', value: LocationConstants.StateValueTAS },
  { id: '5', value: LocationConstants.StateValueACT },
  { id: '6', value: LocationConstants.StateValueSA },
  { id: '7', value: LocationConstants.StateValueWA },
];

/**
 * Gets the dropdown filter configs.
 */
export const getDropdowns = (): DropdownFilter[] => [
  {
    key: LocationFilter.Location,
    label: LocationConstants.StateLabel,
    values: getState(),
  },
];

/**
 * The initial state for the Location filter.
 *
 * Resetting filters will return the location filters to this state.
 */
export const locationDefaultState = new Map<LocationFilter, FilterValue>([
  [LocationFilter.Location, { id: 0, value: LocationConstants.StateValueAny }],
]);
