import { plainToClass } from 'class-transformer';
import authApi from '../auth-api';
import { AuthResponse } from '../auth-response';

/**
 * Provides all methods for the `registration` resource.
 */
class RegistrationApi {
  resource = 'registration';

  /**
   * Registers a user.
   *
   * If there are any errors such as username already exists, it will be returned
   * in the `errors` property.
   */
  register = async (payload: any): Promise<AuthResponse> => {
    const response = await authApi.post(
      `/${this.resource}`,
      {
        ...payload,
      },
    );
    return plainToClass(AuthResponse, response.data);
  }
}

export default new RegistrationApi();
