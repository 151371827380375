import { plainToClass } from 'class-transformer';
import { Database } from 'shared/database.enum';
import queryApi from 'api/query';
import { SubIndex } from 'shared/sub-index.enum';
import { CancelToken } from 'axios';
import { createParams } from 'api/api-utils';
import { ASBVPercentiles } from './asbv-percentiles';
import { ASBV } from './asbv';
import { IndexInfo } from './index-info';

/**
 * Provides all methods for the `asbv` resource.
 */
class ASBVApi {
  resource = 'asbv';

  /**
   * Gets the ASBVs for a given database and optionally matching a keyword.
   */
  get = async (db: Database, keyword?: string): Promise<ASBV[]> => {
    const response = await queryApi.get(
      `/${this.resource}`,
      {
        params: {
          dbType: db,
          keyword,
        },
      },
    );
    return plainToClass(ASBV, response.data as any[]);
  }

  /**
   * Gets the ASBV percentiles for a given database and ASVB.
   */
  getPercentile = async (db: Database, keyword: string): Promise<ASBVPercentiles> => {
    const response = await queryApi.get(
      `/${this.resource}/percentile/?dbType=${db}&keyword=${keyword}`,
    );
    return plainToClass(ASBVPercentiles, response.data);
  }

  /**
   * Gets the ASBVs percentiles for a given database and ASVB.
   */
  getPercentiles = async (db: Database): Promise<ASBVPercentiles[]> => {
    const response = await queryApi.get(
      `/${this.resource}/percentiles/?dbType=${db}`,
    );
    return plainToClass(ASBVPercentiles, response.data as []);
  }

  /**
   * Gets the ASBVs for a specific database and subindex to allow grouping.
   */
  getGroup = async (db: Database, scenario?: SubIndex) => {
    let url = `/${this.resource}/group?dbType=${db}`;
    if (scenario) {
      url += `&scenario=${scenario}`;
    }
    const response = await queryApi.get(url);
    const asbvs = plainToClass(ASBV, response.data as []);

    return asbvs.sort((a, b) => (a.order > b.order ? 1 : -1));
  }

  /**
   * Gets detailed descriptions for indexes.
   */
  getIndexInfo = async (db: Database, subIndex?: SubIndex, token?: CancelToken) => {
    const response = await queryApi.get(
      `/${this.resource}/indexinfo`,
      {
        params: createParams({
          dbType: db,
          scenario: subIndex,
        }),
        cancelToken: token,
      },
    );

    return plainToClass(IndexInfo, response.data as []);
  }
}

export default new ASBVApi();
