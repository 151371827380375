import React, { useState, useEffect } from 'react';
import { ResetButton } from 'components/reset-button/reset-button';
import deepEqual from 'deep-equal';
import { useFilters } from '../../use-filters';
import { ResetFilterProps } from './reset-filter-props';
import { getInitialStateByKey } from '../../filters-utils';

/**
 * Wraps the `ResetButton` component to allow resetting a single filter's state.
 * @param props
 */
export const ResetFilter: React.FC<ResetFilterProps> = ({ filterKey, ...props }) => {
  const [enabled, setEnabled] = useState(false);
  const { resetFilter, updateFiltersError, errors } = useFilters();

  const { filters } = useFilters();

  /**
   * Resets state and errors of particular filter.
   */
  const reset = () => {
    const filterEr = new Map(errors);
    filterEr.set(filterKey, 0);
    updateFiltersError(filterEr);
    resetFilter(filterKey);
  };

  useEffect(() => {
    const e = deepEqual((filters as any)[filterKey], getInitialStateByKey(filterKey));
    setEnabled(!e);
  }, [filters, filterKey]);

  return (
    <ResetButton
      {...props}
      disabled={!enabled}
      onClick={() => reset()}
    >
      Reset Filter
    </ResetButton>
  );
};
