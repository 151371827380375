import { Params } from 'navi';
import { Action } from 'providers/action';
import React, { createContext, Dispatch, useContext, useMemo, useReducer } from 'react';
import { useNavigation } from 'react-navi';
import { getFiltersInitialState } from '../search-utils';
import { filtersReducer } from './filters-reducer';
import { FiltersState } from './filters-state';

type FiltersContext = {
  state: FiltersState,
  dispatch: Dispatch<Action>
};

const FiltersContext = createContext<FiltersContext>({} as FiltersContext);

export const FiltersProvider: React.FC = ({ children }): JSX.Element => {
  const navigation = useNavigation();
  let query: Params = {};
  if (navigation) {
    query = navigation.getCurrentValue().url.query;
  }

  const initialState: FiltersState = useMemo(() => getFiltersInitialState(query), [query]);

  const [state, dispatch] = useReducer(filtersReducer, initialState);
  return (
    <FiltersContext.Provider value={{ state, dispatch }}>
      {children}
    </FiltersContext.Provider>
  );
};

export const useFiltersContext = (): FiltersContext => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error('useFiltersContext must be used within FiltersProvider');
  }
  return context;
};
