import React, { useEffect, useState } from 'react';
import './asbv-tooltip.scss';
import asbvpercentile, { ASBVPercentiles, ASBVPercentile } from 'api/query/asbv';
import { Divider, Paper } from '@material-ui/core';
import { CloseOutlinedIcon } from 'components/icons/close-outlined';
import { ScrollShadow } from 'components/scroll-shadow/scroll-shadow';
import { usePrime } from 'pages/search/prime/use-prime';
import PercentileTag from 'components/percentile-tag';
import { ReactComponent as ASBVskeleton } from 'assets/images/ASBV-loading-skeleton.svg';
import { ASBVTooltipProps } from './asbv-tooltip-props';
import { getDisplayedPercentiles } from '../asbvs-utils';
import { useASBVs } from '../use-asbvs';
import { ASBVsFilterValue } from '../asbvs-filter-value';

export const ASBVTooltip: React.FC<ASBVTooltipProps> = ({ asbv }) => {
  const { primeFilters: { db } } = usePrime();
  const { setSelectedTooltip } = useASBVs();
  const [loading, setLoading] = useState(true);
  const [asbvPercentiles, setASBVPercentiles] = useState<ASBVPercentiles>({} as ASBVPercentiles);
  const [percentiles, setPercentiles] = useState<ASBVPercentile[]>([]);

  /**
   * Closes the tooltip.
   */
  const closeTooltip = () => {
    setSelectedTooltip({} as ASBVsFilterValue);
  };

  useEffect(() => {
    let cancelled = false;
    async function getPercentiles() {
      setLoading(true);

      if (asbv) {
        const percentileValues = await asbvpercentile.getPercentile(db.id, asbv.columnName);

        if (cancelled) {
          return;
        }

        setASBVPercentiles(percentileValues);
        setPercentiles(getDisplayedPercentiles(percentileValues));
      }

      setLoading(false);
    }
    getPercentiles();

    return () => {
      cancelled = true;
    };
  }, [asbv, db]);

  return (
    <div className={`sg-asbv-tooltip ${loading ? 'sg-asbv-tooltip--loading' : ''}`}>
      {loading
        && (
          <div className="sg-asbv-tooltip__skeleton">
            <ASBVskeleton />
          </div>
        )}
      {!loading && asbvPercentiles && (
        <>
          <h5 className="asbv__title">{`${asbv.label} (${asbv.abbrev})`}</h5>
          <div className="asbv__description">{asbv.description}</div>
          <Divider className="divider" />
          {asbvPercentiles.displayAverage && (
            <div className="industry-average">
              <h6>Industry average value: {asbvPercentiles.average}</h6>
            </div>
          )}
          <div className="how-to-search">{asbv.searchInfo}</div>
          <div className="percentile__table">
            <ScrollShadow>
              <Paper className="percentile__table-paper" elevation={0} square>
                <table>
                  <thead>
                    <tr>
                      {percentiles.map((p) => (
                        <th key={p.percentileType}>
                          <PercentileTag percentile={p.percentileType} />
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {percentiles.map((p) => (
                        <td key={p.percentileType}>{p.value}</td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </Paper>
            </ScrollShadow>
          </div>
        </>
      )}
      <CloseOutlinedIcon
        className="tooltip-close"
        onClick={() => closeTooltip()}
      />
    </div>
  );
};
