import React from 'react';
import { TabProps } from './tab-props';

/**
 * A simple tab component to pass custom attributes to CustomTabs component
 * @param props
 */
export const Tab: React.FC<TabProps> = (props) => (
  <>
  </>
);
