import {
  Checkbox, Container, Divider, FormControlLabel, Grid
} from '@material-ui/core';
import { FilterValue } from 'api/query/shared/filter-value';
import Dropdown from 'components/dropdown';
import Autocomplete from 'components/dropdown/autocomplete';
import { TooltipButton } from 'components/tooltip-button/tooltip-button';
import React, { useEffect, useState } from 'react';
import { usePrime } from '../../../prime/use-prime';
import { FilterCategory } from '../../filter-category.enum';
import { getStud } from '../../filters-utils';
import { CheckboxFilter } from '../../models/checkbox-filter';
import { DropdownFilter } from '../../models/dropdown-filter';
import { ResetFilters } from '../../reset-filters/reset-filters';
import { useFilters } from '../../use-filters';
import { BasicConstants } from './basic-constants.enum';
import { BasicFilter } from './basic-filter.enum';
import { basicDefaultState, getCheckboxes, getDropdowns } from './basic-utils';
import './basic.scss';

/**
 * Basic filters.
 *
 * The filters (dropdowns and checkboxes) that get displayed changed based
 * on the selected database, which is shared in the filters state.
 */
export const Basic: React.FC = () => {
  const [dropdownFilters, setDropdownFilters] = useState<DropdownFilter[]>([]);
  const [checkboxFilters, setCheckboxFilters] = useState<CheckboxFilter[]>([]);

  const { basic, updateBasicFilters } = useFilters();
  const { primeFilters: { db } } = usePrime();

  /**
   * Updates the filters state when a filter value changes.
   * @param filter The filter to update.
   * @param value The new value from the filter.
   */
  const filterChange = (filter: BasicFilter, value: any) => {
    // If handling a multiple select dropdown, flatten the objects to an array of their values.
    // If it's a single dropdown, simply use the item value.
    const basicFilters = new Map(basic);
    // If a value doesn't exist (probably from resetting), use the default value.
    const newValue = value || basicDefaultState.get(filter);

    basicFilters.set(filter, newValue);

    updateBasicFilters(basicFilters);
  };

  /**
   * Gets the dropdown/checkbox config when the db changes.
   */
  useEffect(() => {
    setDropdownFilters(getDropdowns(db.id));
    setCheckboxFilters(getCheckboxes(db.id));
  }, [db.id]);

  return (
    <Container className="basic-container">
      <Grid
        className="header header--basic"
        container
      >
        <h3>Basic search</h3>
        <ResetFilters category={FilterCategory.All} />
      </Grid>
      <Grid
        className="container--margin"
        container
      >
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={6}
        >
          {
            dropdownFilters.map((f: DropdownFilter) => (
              <Grid
                className="filter-grid"
                key={f.key}
                container
                item
                xs={12}
                sm={6}
              >
                <Dropdown
                  label={f.label}
                  placeholder={f.placeholder}
                  multiple={f.multiple}
                  disabled={f.disabled}
                  items={f.values}
                  item={basic.get(f.key) || f.values[0]}
                  onSelectionChange={(item) => filterChange(f.key, item)}
                />
              </Grid>
            ))
          }
          <Grid
            className="filter-grid"
            container
            item
            xs={12}
            sm={12}
          >
            <Autocomplete
              multipleSearch
              multiple
              items={[]}
              item={basic.get(BasicFilter.FlockId)}
              label={BasicConstants.StudFlockIdLabel}
              placeholder={BasicConstants.StudFlockIdPlaceholder}
              getItems={(keyword, token) => getStud(db.id, keyword, token)}
              noResultsText={BasicConstants.StudFlockIdNoResults}
              onSelectionChange={(item) => filterChange(BasicFilter.FlockId, item)}
            />
          </Grid>
        </Grid>
        <Grid
          className="checkbox-container"
          container
          item
          xs={12}
          md={6}
        >
          {
            checkboxFilters.map((f: CheckboxFilter) => (
              <Grid
                className="filter-grid grid__checkbox"
                key={f.key}
                container
                item
                xs={12}
                sm={6}
              >
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={(basic.get(f.key) as FilterValue)!.value as boolean}
                      onChange={(event) => filterChange(f.key, {
                        ...basic.get(f.key),
                        value: event.target.checked,
                      })}
                    />
                  )}
                  label={f.label}
                  labelPlacement="end"
                />
                { f.tooltip && <TooltipButton tooltip={f.tooltip} />}
              </Grid>
            ))
          }
        </Grid>
      </Grid>
      <Divider className="divider" />
    </Container>
  );
};
