import { createMuiTheme, Theme } from '@material-ui/core/styles';
import colours from '../styles/_colours.module.scss';
import { props } from './props';

/**
 * Material-UI theme based on SG UI kit.
 *
 * Any overrides supported by the Material-UI theme customization go here.
 * Sass variables can be exported and used here.
 *
 * Read more about theming: https://material-ui.com/customization/theming/
 */
const theme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: colours.green,
      light: colours.green60,
    },
    error: {
      main: colours.red,
    },
    text: {
      primary: colours.grey,
      disabled: colours.grey40,
    },
  },
  typography: {
    fontFamily: 'Chivo',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1280, // regarding ui kit there is drop between sm and lg
      lg: 1440,
      xl: 1440,
    },
  },
  props,
});

export default theme;
