import { TextFieldProps, InputAdornment } from '@material-ui/core';
import React from 'react';
import { CheckCircleIcon } from 'components/icons/check-circle';
import { AlertIcon } from 'components/icons/alert';

export const defaultTextFieldProps: TextFieldProps = {
  variant: 'outlined',
  fullWidth: true,
  InputProps: {
    notched: false,
    endAdornment: (
      <InputAdornment position="end">
        <AlertIcon className="input-adornment__icon input-adornment__icon--error" color="error" />
        <CheckCircleIcon className="input-adornment__icon input-adornment__icon--valid" color="primary" />
      </InputAdornment>
    ),
  },
  InputLabelProps: {
    shrink: true,
  },
};
