import { Action } from 'providers/action';
import React, { createContext, Dispatch, useContext, useEffect, useMemo, useReducer } from 'react';
import { useNavigation } from 'react-navi';
import { addToStorage, getFromStorage, StorageKey } from 'utils/storage-utils';
import { FilterCategory } from '../filters/filter-category.enum';
import { getPrimeInitialState } from '../search-utils';
import { primeReducer } from './prime-reducer';
import { PrimeState } from './prime-state';
import { userTypes } from './prime-utils';

type PrimeContext = {
  state: PrimeState,
  dispatch: Dispatch<Action>
};
const PrimeContext = createContext<PrimeContext>({} as PrimeContext);

/**
 * Gets the initial values for the prime filters from cached values.
 */
export const getPrimeDefaultState = (): PrimeState => {
  const cache = getFromStorage<PrimeState>(StorageKey.Prime) || {};

  return {
    userType: cache.userType ? cache.userType : userTypes[0],
    db: {
      id: cache.db ? cache.db.id : 0,
      value: cache.db ? cache.db.value : '',
    },
    traits: cache.traits || {},
    searchType: FilterCategory.None,
  };
};

export const PrimeFilterProvider: React.FC = ({ children }): JSX.Element => {
  const navigation = useNavigation();
  let query = {};
  if (navigation) {
    query = navigation.getCurrentValue().url.query;
  }
  const initialState: PrimeState = useMemo(() => getPrimeInitialState(query), [query]);

  const [state, dispatch] = useReducer(primeReducer, initialState);

  /**
   * Add the state to storage on mount to ensure cached value are in sync
   * with whatever comes from the querystring values.
   */
  useEffect(() => {
    addToStorage(StorageKey.Prime, initialState);
  }, [initialState]);

  return (
    <PrimeContext.Provider value={{ state, dispatch }}>
      {children}
    </PrimeContext.Provider>
  );
};

export const usePrimeContext = (): PrimeContext => {
  const context = useContext(PrimeContext);
  if (!context) {
    throw new Error('usePrimeContext must be used within PrimeFilterProvider');
  }
  return context;
};
