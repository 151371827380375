import { Action } from '../action';
import { ReducerAction } from '../reducer-action';
import { initialUserState } from './user-context';
import { UserState } from './user-state';
import { addToStorage, removeFromStorage, StorageKey } from '../../utils/storage-utils';

export const userReducer = (state: UserState, action: Action) => {
  switch (action.type) {
    case ReducerAction.Set:
      // eslint-disable-next-line no-case-declarations
      const userState = {
        ...state,
        ...action.payload,
      };
      addToStorage(StorageKey.UserId, userState.id);
      addToStorage(StorageKey.UserState, userState);
      return userState;
    case ReducerAction.Reset:
      removeFromStorage(StorageKey.UserState);
      return initialUserState;
    default:
      return state;
  }
};
