import { FilterValue } from 'api/query/shared/filter-value';
import { PedigreeFilter } from './pedigree-filter.enum';

/**
 * The initial state for the Pedigree filters.
 *
 * Resetting filters will return the pedigree filters to this state.
 */
export const pedigreeDefaultState = new Map<PedigreeFilter, FilterValue>([
  [PedigreeFilter.SireStudName, { id: '', value: '' }],
  [PedigreeFilter.SireID, { id: '', value: '' }],
  [PedigreeFilter.DamID, { id: '', value: '' }],
]);
