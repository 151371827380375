/**
 * Keys for the progeny filters map.
 *
 * These keys are also used for applying querystring values to the search api.
 */
export enum ProgenyFilter {
  SireDam = 'progenyType',
  Number = 'progenyNum',
  CurrentDrop = 'isCurrentDrop',
  MoreThanOneFlock = 'isMultipleFlocks',
}
