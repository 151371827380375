import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import './scroll-shadow.scss';
import { useBreakpoints } from 'utils/use-breakpoints';
import { ScrollShadowProps } from './scroll-shadow-props';

/**
 * Scrolling container that adds shadows on sides where content is not fully visible.
 * This should be the parent of element that has a scroll.
 * @param props
 */
export const ScrollShadow: React.FC<ScrollShadowProps> = (props) => {
  const {
    colour = 'grey',
  } = props;
  const { tablet, phone } = useBreakpoints();
  const [shadows, setShadows] = useState<{ left: boolean, right: boolean }>({ left: false, right: false });
  const [scrollingWidth, setScrollingWidth] = useState<number>(0);
  const [scrollPos, setScrollPos] = useState<number>(0);
  const scrollingContainerRef = useRef<HTMLDivElement>(null);
  const container: any = scrollingContainerRef.current;

  /**
   * Counts scrolling position to check where content is not visible
   * and assigns boolean value to left nad right shadows.
   */
  const setShadow = useCallback(() => {
    if (scrollPos < (0.05 * scrollingWidth)) {
      setShadows({ left: false, right: true });
    } else if (scrollPos > (0.95 * scrollingWidth)) {
      setShadows({ left: true, right: false });
    } else {
      setShadows({ left: true, right: true });
    }
  }, [scrollPos, scrollingWidth]);

  /**
   * Checks if it's not a desktop and what is scroll position
   * to determine if show or hide shadows.
   */
  const applyShadows = useCallback(() => {
    if (scrollingWidth > 0 && (tablet || phone)) {
      setShadow();
    } else {
      setShadows({ left: false, right: false });
    }
  }, [phone, scrollingWidth, setShadow, tablet]);

  /**
   * Checks scroll position and reassigns shadows values.
   */
  const updateShadows = () => {
    setScrollPos(container.firstChild.scrollLeft);
    setShadow();
  };

  /**
   * Updates data on container update.
   * It depends on props.child to update scrollingWidth when child is rendered.
   */
  useEffect(() => {
    if (container) {
      setScrollingWidth(container.firstChild.children[0].offsetWidth - container.firstChild.offsetWidth);
      applyShadows();
    }
  }, [applyShadows, container, props.children]);

  return (
    <div
      className={`sg-scroll-shadow ${shadows.left ? 'sg-scroll-shadow__left' : ''} ${shadows.right ? 'sg-scroll-shadow__right' : ''} sg-scroll-shadow--${colour} ${props.className}`}
      onScroll={updateShadows}
      ref={scrollingContainerRef}
    >
      {props.children}
    </div>
  );
};
