import React, {createContext, useCallback, useContext, useEffect, useReducer,} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {Auth0LinkingState} from './auth0-linking-state';
import AccountApi from '../../api/auth/account/account-api';
import {initialAuth0LinkingState} from './auth0-linking-inital-state';
import {ProviderContext} from '../provider-context';
import {auth0LinkingReducer} from './auth0-linking-reducer';
import {ReducerAction} from '../reducer-action';
import {addToStorage, getFromStorage, StorageKey} from '../../utils/storage-utils';

const Auth0LinkingContext = createContext<ProviderContext<Auth0LinkingState>>({} as any);

const getInitialState = () => {
  const stateBeforeRefresh: Auth0LinkingState = getFromStorage(StorageKey.Auth0LinkingState);
  return stateBeforeRefresh ?? initialAuth0LinkingState;
};

/**
 * A provider which gives the current status of a user's journey to link their Auth0 account to their
 * Sheep Genetics account.
 * @param children The children in the DOM tree.
 * @constructor
 */
export const Auth0LinkingProvider: React.FC = ({ children }): JSX.Element => {
  const { isLoading, isAuthenticated } = useAuth0();
  const [state, dispatch] = useReducer(auth0LinkingReducer, getInitialState());
  const value = { state, dispatch };

  const getAuth0LinkingState = useCallback(async () => {
    try {
      const linkingState = await AccountApi.getAuth0LinkingState();
      addToStorage(StorageKey.Auth0LinkingState, linkingState);
      dispatch({ type: ReducerAction.Set, payload: linkingState });
    } catch (error) {
      // Handle any errors that may occur during the API call
      console.error('Error fetching Auth0 linking status:', error);
    }
  }, []);

  useEffect(() => {
    if (!isLoading && (!state.isLoading || !state.isLinked)) {
      getAuth0LinkingState();
    }
  }, [getAuth0LinkingState, state.isLoading, isLoading, isAuthenticated]);

  return (
    <Auth0LinkingContext.Provider value={value}>
      {children}
    </Auth0LinkingContext.Provider>
  );
};

/**
 * Obtain the current state of a user linking their Auth0 account to their Sheep Genetics account.
 */
export const useAuth0Linking = (): ProviderContext<Auth0LinkingState> => {
  const context = useContext(Auth0LinkingContext);
  if (!context) {
    throw new Error('useAuth0LinkingContext must be used within Auth0LinkingProvider');
  }
  return context;
};
