import {Button, ClickAwayListener, Grow, Link, Paper, Popper,} from '@material-ui/core';
import AuthorizedContent from 'components/authorized-content';
import {AccountOutlinedIcon} from 'components/icons/account-outlined';
import {ReducerAction, UserRole, useUserContext} from 'providers/user';
import React, {memo} from 'react';
import {useNavigation} from 'react-navi';
import {removeUserFromStorage} from 'utils/storage-utils';
import {AccountMenuProps} from './account-menu-props';
import './account-menu.scss';
import {useAuth0} from '@auth0/auth0-react';
import {environment} from '../../environment';
import {useApiReadiness} from '../../providers/api-readiness/api-readiness-context';
import {useAuth0Linking} from '../../providers/auth0-linking-status/auth0-linking-context';

/**
 * Confirm a user email address page.
 * @param props
 */
export const AccountMenu: React.FC<AccountMenuProps> = memo((props) => {
  const {
    name,
    email,
    myMLAIconUrl,
  } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const navigation = useNavigation();
  const { dispatch: userDispatch } = useUserContext();
  const { dispatch: auth0LinkingDispatch } = useAuth0Linking();
  const { logout } = useAuth0();
  const isApiReady = useApiReadiness();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const logOut = () => {
    removeUserFromStorage();
    userDispatch({ type: ReducerAction.Reset });
    auth0LinkingDispatch({ type: ReducerAction.Reset });
    logout({ logoutParams: { returnTo: environment.auth0.logOutRedirectUri } });
  };

  const gotoAccountDetails = () => {
    navigation.navigate('/user/details');
  };

  return (
    <>
      {isApiReady && (
      <>
        <div className="sg-account-menu">
          <AuthorizedContent roles={[UserRole.Admin]}>
            <Button
              className="sg-account-menu__button"
              color="secondary"
              onClick={() => {
                navigation.navigate('/admin');
              }}
            >
              Admin Page
            </Button>
          </AuthorizedContent>
          <Button
            className={`sg-account-menu__button ${open ? 'sg-account-menu__button--selected' : ''}`}
            ref={anchorRef}
            onClick={handleToggle}
            endIcon={<AccountOutlinedIcon />}
          >
            <span className="sg-account-menu__button__label">{name}</span>
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement="bottom-start"
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <div className="sg-account-menu__items">
                      <Link
                        onClick={gotoAccountDetails}
                        component="a"
                      >
                        Account details
                      </Link>
                      <Button
                        className="sg-account-menu__items__logout"
                        onClick={logOut}
                        variant="outlined"
                      >Log out
                      </Button>
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </>
      )}
    </>
  );
});

(AccountMenu as any).whyDidYouRender = true;
