/**
 * Keys for the individual filters map.
 *
 * These keys are also used for applying querystring values to the search api.
 */
export enum IndividualFilter {
  FlockId = 'flockId',
  AnimalId = 'animalId',
  BreederId = 'breederId'
}
