import React, { useState, useEffect, useCallback } from 'react';
import { TextField } from '@material-ui/core';
import { useBreakpoints } from 'utils/use-breakpoints';
import { TextInputProps } from './text-input-props';

/**
 * Text field input with simple in-line validation.
 */
export const TextInput: React.FC<TextInputProps> = ({
  value, validation, validatedValue, validationRule, parentErr, ...props
}) => {
  const [val, setVal] = useState<string>(value);
  const { phone } = useBreakpoints();
  /**
   * Checks if the input is valid by using the `validationRule` callback
   * or the `validation` string/regex.
   */
  const isValid = useCallback(() => {
    if (validationRule) {
      return validationRule(val) as boolean;
    }
    if (validation) {
      return val ? !!val.match(validation as RegExp) : true;
    }
    return true;
  }, [validationRule, validation, val]);

  const [error, setError] = useState<boolean>(phone ? !isValid() : false);

  const [parentError, setParentError] = useState<boolean>(false);

  const checkInput = (valueToCheck: string = value) => {
    if (valueToCheck === value) {
      return;
    }
    // If a validation prop was provided, validate the input
    const valid = isValid();
    setError(!valid);
    // Callback with the updated value, whether it is valid and whether it was valid.
    validatedValue(valueToCheck, !!valid, !error);
  };

  /**
   * Sets the local input value whenever the prop changes.
   */
  useEffect(() => {
    setVal(value);
  }, [value]);

  /**
   * Validates the input if the value or validation props change.
   */
  useEffect(() => {
    if (validation && val === value) {
      const valid = isValid();
      if (valid === error) {
        setError(!valid);
        validatedValue(val, valid, !error);
      }
    }

    if (parentErr !== undefined && !error && parentErr !== parentError) {
      setParentError(parentErr);
      validatedValue(val, !parentErr, parentErr);
    }


    /**
     * Hides error state when value is empty
     */
    if (value === '') {
      setError(false);
    }
  }, [parentErr, error, parentError, isValid, val, validatedValue, validation, value]);

  return (
    <TextField
      value={val}
      label={props.label}
      inputRef={props.inputRef}
      placeholder={props.placeholder}
      error={error || parentError}
      helperText={error ? props.helperText : ''}
      onChange={(event) => setVal(event.target.value)}
      onBlur={(event) => checkInput(event.target.value)}
    />
  );
};

// (TextInput as any).whyDidYouRender = true;
