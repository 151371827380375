import axios from 'axios';
import {environment} from 'environment';
import qs from 'qs';

/**
 * Axios instance for all `submission` api requests.
 *
 * An `axios` instance utilising `axios-cache-adapter` and `localforage` for
 * caching responses to localStorage.
 */
const submissionsApi = axios.create({
  baseURL: `${environment.endpoint('submission')}/submission/api`,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

export default submissionsApi;

