import { Container, Grid } from '@material-ui/core';
import Autocomplete from 'components/dropdown/autocomplete';
import { TextInput } from 'components/text-input/text-input';
import { ErrorMessage } from 'constants/error-message.enum';
import deepEqual from 'deep-equal';
import { useFilters } from 'pages/search/filters/use-filters';
import React, { useCallback, useEffect, useState } from 'react';
import { isNullOrUndefined } from "utils/utils";
import { getErrorCount, validAnimalIdLongRegex, validAnimalIdShortRegex } from 'utils/validation';
import { getStud } from '..';
import { IndividualFilter } from '../../../shared/individual-filter.enum';
import { usePrime } from '../../prime/use-prime';
import { FilterCategory } from '../filter-category.enum';
import { Filters } from '../filters.enum';
import { ResetFilters } from '../reset-filters/reset-filters';
import { IndividualConstants } from './individual-contstants.enum';
import { IndividualProps } from './individual-props';
import './individual.scss';

/**
 * Individual animals filters.
 */
export const Individual: React.FC<IndividualProps> = (props) => {
  const {
    individual, updateIndividualFilters, updateFiltersError, errors,
  } = useFilters();
  const [animalPlaceholder, setAnimalPlaceholder] = useState<string>(IndividualConstants.AnimalIdPlaceholderLong);
  const actualErState: number = errors.get(Filters.Individual) || 0;
  const { primeFilters: { db } } = usePrime();

  /**
   * Updates the filters state when a filter value changes.
   * @param filter The filter to update.
   * @param value The new value from the filter.
   */
  const filterChange = useCallback((filter: IndividualFilter, value: any) => {
    const filterValue = individual.get(filter);
    if (deepEqual(filterValue, value)) {
      return;
    }

    const individualFilters = new Map(individual);
    const val = isNullOrUndefined(value) ? "" : value;
    individualFilters.set(filter, val);
    updateIndividualFilters(individualFilters);
  }, [individual, updateIndividualFilters]);


  /**
   * Saves animal ID as string and object of strings.
   * @param value The new animal id.
   */
  const saveAnimalId = useCallback((value: string) => {
    const id = individual.get(IndividualFilter.AnimalId);
    if (value !== id?.value) {
      filterChange(IndividualFilter.AnimalId, { value });
    }
  }, [filterChange, individual]);

  /**
   * Updates filters errors
   * @param isValid is value valid
   * @param wasValid was value valid
   */
  const updateFilterErrors = useCallback((isValid: boolean, wasValid: boolean) => {
    const errorCount = getErrorCount(isValid, wasValid, actualErState);
    const individualError = new Map(errors);
    individualError.set(Filters.Individual, errorCount);
    updateFiltersError(individualError);
  }, [actualErState, errors, updateFiltersError]);

  /**
   * Handles the change in value of the Animal ID input.
   */
  const animalIdChange = useCallback((value: string, isValid: boolean, wasValid: boolean) => {
    saveAnimalId(value);

    if (isValid !== wasValid) {
      updateFilterErrors(isValid, wasValid);
    }
  }, [saveAnimalId, updateFilterErrors]);

  useEffect(() => {
    if (individual) {
      const isStudName = individual.get(IndividualFilter.FlockId)?.value;

      // updates animal id placeholder if stud name is entered
      if (isStudName) {
        setAnimalPlaceholder(IndividualConstants.AnimalIdPlaceholderShort);
      } else {
        setAnimalPlaceholder(IndividualConstants.AnimalIdPlaceholderLong);
      }
    }
  }, [individual]);

  return (
    <Container className="sg-individual">
      <Grid container>
        <Grid className="sg-individual__title" item xs={12}>
          <h3>Individual animal or stud&nbsp;search</h3>
          <ResetFilters category={FilterCategory.Individual} />
        </Grid>
        <Grid item xs={12}>
          <div className="text--sm">Search animals by entering stud name or flock code and a 6 digit animal code, or by entering the animals 16 digit animal ID. Multiple IDs can be entered, separated by comma.</div>
        </Grid>
        <Grid className="sg-individual__container" container item xs={12}>
          <Grid className="sg-individual__grid" container item xs={12} sm={6} md={5}>
            <Autocomplete
              items={[]}
              item={individual.get(IndividualFilter.FlockId)}
              value={individual.get(IndividualFilter.FlockId)?.value}
              label={IndividualConstants.FlockStudNameLabel}
              placeholder={IndividualConstants.FlockStudNamePlaceholder}
              noResultsText={IndividualConstants.StudFlockIdNoResults}
              getItems={(keyword, token) => getStud(db.id, keyword, token)}
              onSelectionChange={([item]) => filterChange(IndividualFilter.FlockId, item)}
            />
          </Grid>
          <Grid className="sg-individual__grid" container item xs={12} sm={6} md={5}>
            <TextInput
              value={individual.get(IndividualFilter.AnimalId)?.value}
              label={IndividualConstants.AnimalIdLabel}
              placeholder={animalPlaceholder}
              helperText={ErrorMessage.InvlaidAnimalID}
              validation={individual.get(IndividualFilter.FlockId)?.value ? validAnimalIdShortRegex : validAnimalIdLongRegex}
              validatedValue={animalIdChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
