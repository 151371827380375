/**
 * Keys for the basic filters map.
 *
 * These keys are also used for applying querystring values to the search api.
 */
export enum BasicFilter {
  Breed = 'breeds',
  BirthYear = 'birthYear',
  Sex = 'sex',
  WoolType = 'woolTypes',
  BreederGroup = 'breederGroups',
  RegisteredStatus = 'registerStatus',
  FlockId = 'flockId',
  ForSale = 'forSale',
  SemenAvailable = 'semenAvailable',
  ProgenyInReferenceFlock = 'referenceFlock',
  GenomeTested = 'genoTestedAndUsed',
  MerinoSuperiorSire = 'isMss',
  PollHornResult = 'isDoublePolled',
  MerinoLifetimeProductivity = 'isMlp'
}
