import { CssBaseline, StylesProvider, ThemeProvider } from '@material-ui/core';
import { environment } from 'environment';
import React from 'react';
import ReactDOM from 'react-dom';
import 'reflect-metadata';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './components/app';
import './index.scss';
import UserProvider from './providers/user';
import * as serviceWorker from './serviceWorker';
import theme from './theme/theme';
import { Auth0LinkingProvider } from './providers/auth0-linking-status/auth0-linking-context';
import { ApiReadinessProvider } from './providers/api-readiness/api-readiness-context';

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

const root = document.getElementById('root') as HTMLElement;
root.setAttribute('data-sg-version', environment.version!);

ReactDOM.render(
  <StylesProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Auth0Provider
        domain={environment.auth0.domain}
        clientId={environment.auth0.clientId}
        useRefreshTokens
        useRefreshTokensFallback={false}
        cacheLocation="localstorage"
        authorizationParams={{
          audience: environment.auth0.audience,
          redirect_uri: environment.auth0.logInRedirectUri,
          scope: 'openid profile email offline_access',
        }}
      >
        <ApiReadinessProvider>
          <UserProvider>
            <Auth0LinkingProvider>
              <App />
            </Auth0LinkingProvider>
          </UserProvider>
        </ApiReadinessProvider>
      </Auth0Provider>
    </ThemeProvider>
  </StylesProvider>,
  root,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
