import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class IndexInfo {
  @Expose()
  title: string;

  @Expose({ name: 'explanation' })
  description: string;
}
