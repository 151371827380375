/**
 * Represents the filters.
 */
export enum Filters {
  Individual = 'individual',
  Basic = 'basic',
  Pedigree = 'pedigree',
  Progeny = 'progeny',
  ASBVS = 'asbv',
  Location = 'location'
}
