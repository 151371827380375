/**
 * String constants for the `Basic` component.
 */
export enum BasicConstants {
  StudFlockIdNoResults = 'Sorry there are no animals matching your flock ID or name',
  StudFlockIdLabel = 'Stud or Flock ID',
  StudFlockIdPlaceholder = 'Stud name or 6 digit ID',
  ForSaleLabel = 'Currently for sale',
  ForSaleTooltip = 'Search animals listed as currently for sale',
  SemenAvailableLabel = 'Semen available',
  SemenAvailableTooltip = 'Search animals with semen listed as available',
  ProgenyInReferenceFlockLabel = 'Progeny in reference flock',
  ProgenyInReferenceFlockTooltip = 'Reference flock: industry research flocks where genomics and performance has been collected',
  GenomeTestedLabel = 'Genomics tested & used',
  GenomeTestedTooltip = 'Animal\'s genomic data is included used in calculation of ASBVs reported',
  MerinoSuperiorSireLabel = 'Merino Superior Sire',
  MerinoSuperiorSireTooltip = 'Sire evaluation program run by The Australia Merino Sire Evaluation Association (AMSEA)',
  PollHornResultLabel = 'PP Poll/Horn Result',
  PollHornResultTooltip = 'Animals with a PP result will produce more progeny with polled status than sires with PH or HH results',
  MerinoLifetimeProductivityLabel = 'MLP Sire',
  MerinoLifetimeProductivityTooltip = 'Sire was used in the Merino Lifetime Productivity Project'
}
