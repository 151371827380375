import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class MateSelResponse {
  @Expose()
  hasAccess: boolean;

  @Expose()
  isAdmin: boolean;

  @Expose()
  flocks: MateSelFlock[];
}

@Exclude()
export class MateSelFlock {
  @Expose()
  breerderId: number;

  @Expose()
  breederWebSite: string;

  @Expose()
  flockCode: string;

  @Expose()
  flockName: string;

  @Expose()
  role: number;

  @Expose()
  serviceProviders: string;
}
