import { Container, Grid } from '@material-ui/core';
import Dropdown from 'components/dropdown';
import React, { useEffect, useState } from 'react';
import { DropdownFilter } from '../..';
import FilterAccordion from '../../filter-accordion';
import { getChips, removeChip } from '../../filters-utils';
import { Filters } from '../../filters.enum';
import { useFilters } from '../../use-filters';
import { LocationConstants } from './location-constants.enum';
import { LocationFilter } from './location-filter.enum';
import { getDropdowns, locationDefaultState } from './location-utils';


export const Location: React.FC = () => {
  const {
    location, updateLocationFilters,
  } = useFilters();
  const [dropdownFilters, setDropdownFilters] = useState<DropdownFilter[]>([]);

  /**
   * Updates the filters state when a filter value changes.
   * @param filter The filter to update.
   * @param value The new value from the filter.
   */
  const filterChange = (filter: LocationFilter, value: any) => {
    const locationFilters = new Map(location);
    locationFilters.set(filter, value);
    updateLocationFilters(locationFilters);
  };

  /**
   * Gets the dropdown/checkbox config when the component mounts.
   */
  useEffect(() => {
    setDropdownFilters(getDropdowns());
  }, []);

  return (
    <Container>
      <FilterAccordion
        title={LocationConstants.Title}
        id={Filters.Location}
        chips={getChips(location, locationDefaultState)}
        onChipDelete={([key, filter]) => filterChange(key, removeChip(key, filter, location, locationDefaultState))}
      >
        <Grid
          className="help-container"
          container
          item
          xs={12}
        >
          <div className="text--sm">Search for animals from flocks within a state near you. By selecting a state the search will return animals from breeders within this state.
          </div>
        </Grid>
        <Grid className="container--margin">
          <Grid
            container
            item
            xs={12}
            md={6}
          >
            {
              dropdownFilters.map((f: DropdownFilter) => (
                <Grid
                  className="filter-grid"
                  key={f.key}
                  container
                  item
                  xs={12}
                  sm={6}
                >
                  <Dropdown
                    label={f.label}
                    disabled={!f.values.length}
                    items={f.values}
                    item={location.get(f.key)}
                    onSelectionChange={([item]) => filterChange(f.key, item)}
                  />
                </Grid>
              ))
            }
          </Grid>
        </Grid>
      </FilterAccordion>
    </Container>
  );
};
