import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class Permission {
  @Expose()
  impersonatorId: number;

  @Expose()
  targetUserid: number;

  @Expose()
  impersonatorEmail: string;

  @Expose()
  impersonatorName: string;

  @Expose()
  roleId: number;

  @Expose()
  role: string;
}
