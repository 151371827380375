export enum LocationConstants {
  Title = 'Location',
  Id = 'location',
  StateLabel = 'State',
  StatePlaceholder = 'Postcode',
  StateValueAny = 'Any state',
  StateValueQLD = 'QLD',
  StateValueNSW = 'NSW',
  StateValueVIC = 'VIC',
  StateValueTAS = 'TAS',
  StateValueACT = 'ACT',
  StateValueSA = 'SA',
  StateValueWA = 'WA',
}
