import { Checkbox, Container, FormControlLabel, Grid } from '@material-ui/core';
import { FilterValue } from 'api/query/shared/filter-value';
import Dropdown from 'components/dropdown';
import { TextInput } from 'components/text-input/text-input';
import { TooltipButton } from 'components/tooltip-button/tooltip-button';
import { useFilters } from 'pages/search/filters/use-filters';
import React, { useEffect, useState } from 'react';
import { getErrorCount, positiveNumeric } from 'utils/validation';
import { FilterAccordion } from '../../filter-accordion/filter-accordion';
import { getChips, removeChip } from '../../filters-utils';
import { Filters } from '../../filters.enum';
import { CheckboxFilter } from '../../models/checkbox-filter';
import { DropdownFilter } from '../../models/dropdown-filter';
import { ProgenyConstants } from './progeny-constants.enum';
import { ProgenyFilter } from './progeny-filter.enum';
import { getCheckboxes, getDropdowns, progenyDefaultState } from './progeny-utils';

/**
 * Progeny filters.
 *
 */
export const Progeny: React.FC = () => {
  const [dropdownFilters, setDropdownFilters] = useState<DropdownFilter[]>([]);
  const [checkboxFilters, setCheckboxFilters] = useState<CheckboxFilter[]>([]);
  const {
    progeny, updateProgenyFilters, updateFiltersError, errors,
  } = useFilters();
  const actualErState: number = errors.get(Filters.Progeny) || 0;

  /**
   * Updates the filters state when a filter value changes.
   * @param filter The filter to update.
   * @param value The new value from the filter.
   */
  const filterChange = (filter: ProgenyFilter, value: any) => {
    const progenyFilters = new Map(progeny);
    progenyFilters.set(filter, value);
    updateProgenyFilters(progenyFilters);
  };

  /**
   * Updates filters errors
   * @param isValid is value valid
   * @param wasValid was value valid
   */
  const updateFilterErrors = (isValid: any, wasValid: any) => {
    const errorCount = getErrorCount(isValid, wasValid, actualErState);
    const porgenyError = new Map(errors);
    porgenyError.set(Filters.Progeny, errorCount);
    updateFiltersError(porgenyError);
  };

  /**
   * Gets the dropdown/checkbox config when the component mounts.
   */
  useEffect(() => {
    setDropdownFilters(getDropdowns());
    setCheckboxFilters(getCheckboxes());
  }, []);

  return (
    <Container>
      <FilterAccordion
        title={ProgenyConstants.Title}
        id={Filters.Progeny}
        chips={getChips(progeny, progenyDefaultState)}
        onChipDelete={([key, filter]) => filterChange(key, removeChip(key, filter, progeny, progenyDefaultState))}
      >
        <Grid className="help-container" container item xs={12}>
          <div className="text--sm">Search for animals that have been used as parents and have progeny in the database</div>
        </Grid>
        <Grid className="container--margin" container item>
          <Grid container item xs={12} md={6}>
            {
              dropdownFilters.map((f: DropdownFilter) => (
                <Grid className="filter-grid" key={f.key} container item xs={12} sm={6}>
                  <Dropdown
                    label={f.label}
                    disabled={!f.values.length}
                    items={f.values}
                    item={progeny.get(f.key)}
                    onSelectionChange={([item]) => filterChange(f.key, item)}
                  />
                </Grid>
              ))
            }
            <Grid className="filter-grid" container item xs={12} sm={6}>
              <TextInput
                label={ProgenyConstants.NumberProgenyLabel}
                placeholder={ProgenyConstants.NumberProgenyPlaceholder}
                value={progeny.get(ProgenyFilter.Number)?.value}
                helperText="Must be a postive whole number"
                validationRule={positiveNumeric}
                validatedValue={(value, isValid, wasValid) => {
                  filterChange(ProgenyFilter.Number, {
                    ...progeny.get(ProgenyFilter.Number),
                    value,
                  });
                  updateFilterErrors(isValid, wasValid);
                }}
              />
            </Grid>
          </Grid>
          <Grid className="checkbox-container" container item xs={12} md={6}>
            {
              checkboxFilters.map((f: CheckboxFilter) => (
                <Grid className="filter-grid grid__checkbox" key={f.key} container item xs={12} sm={6}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={(progeny.get(f.key) as FilterValue)!.value as boolean}
                        onChange={(event) => filterChange(f.key, {
                          ...progeny.get(f.key),
                          value: event.target.checked,
                        })}
                      />
                    )}
                    label={f.label}
                    labelPlacement="end"
                  />
                  { f.tooltip && <TooltipButton tooltip={f.tooltip} /> }
                </Grid>
              ))
            }
          </Grid>
        </Grid>
      </FilterAccordion>

    </Container>
  );
};
