export enum ASBVsConstants {
  Title = 'ASBVs',
  Id = 'asbv',
  HelpText = 'Search for animals on ASBVs that meet your breeding objective by setting a minimum and maximum',
  ASBVLabel = 'ASBV',
  ASBVPlaceholder = 'Add ASBV',
  MinimumLabel = 'Minimum',
  MaximumLabel = 'Maximum',
  MinMaxPlaceholderValue = 'Value',
  MinMaxPlaceholderPercentile = 'Percentiles',
  ErrorMinMaxValue = 'Must be a number with 2 decimal',
  ErrorMinMaxPercentile = 'Must be a number between 0 to 100',
  ErrorComparison = 'Minimum value must not exceed maximum value',
  AutocompleteNoResults = 'No ASBVs match your search',
}
