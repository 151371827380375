import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import './asbv.scss';
import { Grid } from '@material-ui/core';
import { DropdownItem } from 'components/dropdown/dropdown-item';
import { TooltipButton } from 'components/tooltip-button/tooltip-button';
import { useBreakpoints } from 'utils/use-breakpoints';
import { TextInput } from 'components/text-input/text-input';
import { getErrorCount } from 'utils/validation';
import { ASBV as ASBVModel } from 'api/query/asbv/asbv';
import Autocomplete from 'components/dropdown/autocomplete';
import { ASBVTooltip } from '../asbv-tooltip/asbv-tooltip';
import { ASBVsConstants } from '../asbvs-constants.enum';
import { ASBVProps } from './asbv-props';
import { useASBVs } from '../use-asbvs';
import { ASBVsFilterValue } from '../asbvs-filter-value';
import { getMinMaxPlaceholder, validateASBVMinMax, getMinMaxErrorText } from '../asbvs-utils';
import { useFilters } from '../../../use-filters';
import { Filters } from '../../../filters.enum';
import { ASBVsTab } from '../asbvs-tab.enum';

/**
 * Allows selection of an ASBV and a min/max value.
 *
 * When an ASBV is selected, the tooltip will automatically open and display
 * the percentile details.
 */
export const ASBV: React.FC<ASBVProps> = ({
  items, asbv, asbvs, onUpdate, onAdd, onDelete, index, type, getActualErCount,
}) => {
  const { errors, updateFiltersError } = useFilters();
  const [selectedItem, setSelectedItem] = useState<DropdownItem>();
  const [values, setValues] = useState({ min: asbv?.min || '', max: asbv?.max || '' });
  const [comparisonError, setcomparisonError] = useState<boolean>();
  const minInput = useRef<HTMLInputElement>(null);
  const {
    focussedASBV, unfocus, toggleTooltip, selectedTooltip, selectedTab,
  } = useASBVs();
  const { phone } = useBreakpoints();

  /**
   * Sets focus to the "Minimum" input.
   */
  const focusMinInput = useCallback(() => {
    if (minInput && minInput.current) {
      minInput.current.focus();
    }
  }, []);

  /**
   * Handles the dropdown item selection.
   *
   * If this component uses a selected ASBV value, focus the min input.
   *
   * If this section is the placeholder (at the bottom) to add new values,
   * reset the selected item and callback to the parent to add a new section.
   * @param param0
   */
  const itemSelected = ([item]: DropdownItem[]) => {
    if (!item) {
      return;
    }

    const selected = asbvs.find((a) => a.asbvId === item.id) as ASBVModel;

    const a: ASBVsFilterValue = {
      asbv: selected.asbvId,
      abbrev: selected.abbrev,
      min: values.min,
      max: values.max,
      isPercentile: selectedTab === ASBVsTab.Percentiles,
    };
    if (asbv && onUpdate) {
      onUpdate(a, index);
    }
    if (onAdd) {
      onAdd(a);
    }
  };

  /**
   * Handles the item deletion.
   */
  const itemDeleted = () => {
    if (onDelete && asbv) {
      onDelete(asbv.asbv);
    }
  };

  /**
   * Updates the ASBV with the min/max values when either of the text
   * inputs are changed.
   * @param key
   * @param value
   */
  const updateValues = (key: string, value: string) => {
    const v = { ...values, [key]: value };
    setValues(v);
    const getMinValidation = validateASBVMinMax(type, v.min);
    const getMaxValidation = validateASBVMinMax(type, v.max);
    const a: ASBVsFilterValue = {
      asbv: asbv?.asbv as string,
      abbrev: asbv?.abbrev as string,
      min: v.min,
      max: v.max,
      isPercentile: selectedTab === ASBVsTab.Percentiles,
      errors: !getMinValidation(v.min) || !getMaxValidation(v.max),
    };

    if (asbv && onUpdate) {
      onUpdate(a, index);
    }
  };

  /**
   * Validate the min by regex and compare it with max
   * @param value
   */
  const validateMin = (value: string) => {
    const v = { ...values };
    const getMinMaxValidation = validateASBVMinMax(type, v.min);
    const valid = getMinMaxValidation(value);
    const comparisonValidation = value === '' || v.max === '' || parseFloat(value) < parseFloat(v.max);

    if (valid && getMinMaxValidation(v.max)) {
      setcomparisonError(!comparisonValidation);
    } else {
      setcomparisonError(false);
    }

    return valid;
  };

  /**
   * Validate the max by regex and compare it with min
   * @param value
   */
  const validateMax = (value: string) => {
    const v = { ...values };
    const getMinMaxValidation = validateASBVMinMax(type, v.max);
    const valid = getMinMaxValidation(value);
    const comparisonValidation = v.min === '' || value === '' || parseFloat(v.min) < parseFloat(value);

    if (valid && getMinMaxValidation(v.min)) {
      setcomparisonError(!comparisonValidation);
    } else {
      setcomparisonError(false);
    }

    return valid;
  };

  /**
   * Sets the open tooltip and removes the selected ASBV.
   *
   * Removing the ASBV allows losing focus on the selected input.
   * @param asbv
   */
  const onTooltipOpen = (asbvFilter: ASBVsFilterValue) => {
    unfocus();
    toggleTooltip(asbvFilter);
  };

  /**
   * Updates filters errors
   * @param isValid is value valid
   * @param wasValid was value valid
   */
  const updateFilterErrors = (isValid: any, wasValid: any) => {
    const errorCount = getErrorCount(isValid, wasValid, getActualErCount);
    const asbvError = new Map(errors);
    asbvError.set(Filters.ASBVS, errorCount);
    updateFiltersError(asbvError);
  };

  /**
   * Sets the selected dropdown item from the provided ASBV.
   */
  useEffect(() => {
    const x = items.find((i: DropdownItem) => i.id.toString() === asbv?.asbv.toString());
    setSelectedItem(x);
  }, [asbv, items]);

  /**
   * Auto focus the "Minimum" input when a dropdown value is selected.
   */
  useEffect(() => {
    if (asbv === focussedASBV) {
      focusMinInput();
    }
  }, [asbv, focusMinInput, focussedASBV]);

  return (
    <div className="sg-asbv">
      <Grid container item xs={12}>
        <Grid className={`filter-grid ${selectedItem ? 'tooltipBtn-visible' : ''}`} container item xs={12}>
          <Autocomplete
            items={items}
            item={selectedItem}
            label={ASBVsConstants.ASBVLabel}
            placeholder={ASBVsConstants.ASBVPlaceholder}
            noResultsText={ASBVsConstants.AutocompleteNoResults}
            onSelectionChange={(item) => itemSelected(item)}
            onDelete={() => itemDeleted()}
          />
          {(phone && asbv) && (
            <span className="sg-asbv__tooltip">
              <Grid className="tooltip-container" container item xs={12}>
                <Grid className="tooltip-container__grid">
                  <TooltipButton
                    type="relative"
                    placement="bottom-start"
                    tooltipOpen={selectedTooltip.asbv === asbv.asbv}
                    tooltip={<ASBVTooltip asbv={(asbvs.find((s) => s.asbvId.toString() === asbv.asbv.toString())) as ASBVModel} />}
                    onTooltipOpen={() => onTooltipOpen(asbv)}
                  />
                </Grid>
              </Grid>
            </span>
          )}
        </Grid>
      </Grid>
      {asbv && (
        <Grid container item xs={12}>
          <Grid className="filter-grid" container item xs={6}>
            <TextInput
              value={values.min}
              label={ASBVsConstants.MinimumLabel}
              inputRef={minInput}
              placeholder={getMinMaxPlaceholder(type)}
              helperText={!comparisonError ? getMinMaxErrorText(type) : ''}
              parentErr={comparisonError}
              validationRule={validateMin}
              validatedValue={(value, isValid, wasValid) => {
                updateValues('min', value);
                updateFilterErrors(isValid, wasValid);
              }}
            />
          </Grid>
          <Grid className="filter-grid" container item xs={6}>
            <TextInput
              value={values.max}
              label={ASBVsConstants.MaximumLabel}
              placeholder={getMinMaxPlaceholder(type)}
              helperText={!comparisonError ? getMinMaxErrorText(type) : ''}
              parentErr={comparisonError}
              validationRule={validateMax}
              validatedValue={(value, isValid, wasValid) => {
                updateValues('max', value);
                updateFilterErrors(isValid, wasValid);
              }}
            />
          </Grid>
          {comparisonError && (
            <p className="sg-asbv__error Mui-error">
              {ASBVsConstants.ErrorComparison}
            </p>
          )}
        </Grid>
      )}
    </div>
  );
};
