import React from 'react';
import ASBVs from './asbvs';
import { ASBVsProvider } from './asbvs/asbvs-context';
import Basic from './basic';
import Location from './location';
import Pedigree from './pedigree';
import Progeny from './progeny';

export const All: React.FC = () => (
  <>
    <Basic />
    <Progeny />
    <Pedigree />
    <Location />
    <ASBVsProvider>
      <ASBVs />
    </ASBVsProvider>
  </>
);
