import React from 'react';
import { AlertProps } from '@material-ui/lab/Alert';
import { AlertIcon } from 'components/icons/alert';
import { CheckCircleIcon } from 'components/icons/check-circle';

export const alert: AlertProps = {
  iconMapping: {
    error: <AlertIcon color="error" />,
    success: <CheckCircleIcon color="primary" />,
  },

};
