import { Pagination } from 'api/pagination';
import { ASBV } from 'api/query/asbv/asbv';
import { ASBVType } from 'api/query/asbv/asbv-type.enum';
import { SearchAnimal } from 'api/query/search/search-animal';
import { SearchResponse } from 'api/query/search/search-response';
import { Trait } from 'api/query/shared';
import { CancelToken } from 'axios';
import { DropdownItem } from 'components/dropdown/dropdown-item';
import { PercentileHighlight } from 'components/percentile-tag/percentile-highlight.enum';
import { RelativeTooltipContent } from 'components/relative-tooltip/relative-tooltip-content';
import { RelativeTooltipContentSection } from 'components/relative-tooltip/relative-tooltip-content-section';
import { Column } from 'components/table/column';
import { AnimalId } from 'shared/animal-id.constant';
import { Database } from 'shared/database.enum';
import { DisplayFormat } from 'shared/display-format.enum';
import { SubIndex } from '../../../shared/sub-index.enum';
import { FiltersState } from '../filters';
import { FilterCategory } from '../filters/filter-category.enum';
import { UserType } from '../filters/user-type.enum';
import { PercentileLevel } from './percentile-level.enum';
import { Percentile } from './percentile.enum';
import { SortDir } from './sort-dir.enum';

export const createColumns = (results: SearchAnimal[], sortBy: string, sortDirection: SortDir, showPedigree: boolean = false): Column[] => {
  const sortDir = sortDirection === SortDir.BestToWorst ? 'desc' : 'asc';

  const animalId: Column = {
    id: AnimalId,
    label: AnimalId,
    enableSort: true,
    // camel case id returned by API - cannot be used in the rest of the app
    isSorted: sortBy === AnimalId || sortBy === 'animalId',
    sortDir: sortBy === AnimalId || sortBy === 'animalId' ? sortDir : undefined,
    align: 'left',
    isSticky: true,
  };

  const dataColumns: Column[] = [];

  if (showPedigree) {
    dataColumns.push({
      id: '0',
      label: 'Pedigree',
      enableSort: false,
      isSorted: false,
      sortDir: undefined,
      align: 'center',
      hasInfo: false,
      isSticky: false,
    });
  }
  // Column headers for the data fields in the animal results
  results[0].asbvs.forEach((asbv: Trait) => {
    dataColumns.push({
      id: asbv.id,
      label: asbv.name,
      enableSort: true,
      isSorted: sortBy === asbv.name,
      sortDir: sortBy === asbv.name ? sortDir : 'asc',
      align: 'center',
      hasInfo: true,
      isSticky: false,
    });
  });
  const actions: Column = {
    id: 'actions',
    label: '',
    enableSort: false,
    isSorted: false,
    sortDir: undefined,
    align: 'center',
    isSticky: true,
  };

  const cols = [animalId, ...dataColumns, actions];

  return cols;
};

/**
   * Creates the content sections based on the asbv type.
   * @param asbv
   */
const getASBVTooltipContentSections = (asbv: ASBV): RelativeTooltipContentSection[] => {
  const sections: RelativeTooltipContentSection[] = [];

  switch (asbv.type) {
    case ASBVType.Trait:
      sections.push(
        {
          type: 'The free lunches',
          content: {
            list: asbv.freeLunches.split(', '),
          },
        },
        {
          type: 'Things to watch',
          content: {
            list: asbv.thingsToWatch.split(', '),
          },
        },
        {
          content: {
            desc: [asbv.message],
          },
        },
      );
      break;
    case ASBVType.Index:
      sections.push(
        {
          type: 'Suitable for',
          content: {
            list: asbv.freeLunches.split(', '),
          },
        },
        {
          type: 'Traits used to calculate index',
          content: {
            list: asbv.thingsToWatch.split(', '),
            link: asbv.message,
          },
        },
      );
      break;
    default:
      break;
  }

  return sections;
};

/**
   * Gets the config object for the ASBV tooltip.
   * @param asbvs The list of ASBV details
   * @param id The ASBV id.
   */
export const getASBVTooltipContent = (asbvs: ASBV[], id: string): RelativeTooltipContent | undefined => {
  const asbv = asbvs.find((a) => a.asbvId === id);
  if (asbv) {
    const content: RelativeTooltipContent = {
      title: `${asbv.label} (${asbv.abbrev})`,
      summary: asbv.definition,
      tabletColumns: [6, 6, 8],
      desktopColumns: [4, 4, 8],
      sections: getASBVTooltipContentSections(asbv),
    };
    return content;
  }
  return undefined;
};

export const displayPercentileTag = (selectedTopPercentile: any | undefined,
  selectedBottomPercentile: any | undefined, percentile: number | undefined,
  hasLeader: boolean | undefined): boolean => {
  let result = false;

  if (percentile !== null && percentile !== undefined && hasLeader && selectedTopPercentile && selectedBottomPercentile) {
    result = (selectedTopPercentile === PercentileHighlight.Top5 && percentile <= 5)
      || (selectedTopPercentile === PercentileHighlight.Top10 && percentile <= 10)
      || (selectedTopPercentile === PercentileHighlight.Top20 && percentile <= 20)
      || (selectedBottomPercentile === PercentileHighlight.Bottom10 && percentile >= 90)
      || (selectedBottomPercentile === PercentileHighlight.Bottom20 && percentile >= 80);
  }

  return result;
};

/**
   * Returns percentile number that is assign with highlight colour.
   * @param percentile pervcentile
   */
export const assignPercentileRange = (percentile: number) => {
  let displayPercentile;
  if (percentile < PercentileLevel.TopFive) {
    displayPercentile = PercentileLevel.TopFive;
  } else if (percentile > PercentileLevel.BottomTen) {
    displayPercentile = PercentileLevel.BottomTen;
  } else {
    displayPercentile = percentile;
  }
  return displayPercentile;
};

export const dataViewLabels: DropdownItem[] = [
  { id: '1', value: DisplayFormat.Data },
  { id: '2', value: DisplayFormat.Graph },
  { id: '3', value: DisplayFormat.Condensed },
  /* { id: '3', value: DisplayFormat.Group }, */ // uncomment when developing group view
];

export const topPercentileLabels: DropdownItem[] = [
  { id: PercentileHighlight.Top5, value: Percentile.Top5 },
  { id: PercentileHighlight.Top10, value: Percentile.Top10 },
  { id: PercentileHighlight.Top20, value: Percentile.Top20 },
];

export const bottomPercentileLabels: DropdownItem[] = [
  { id: PercentileHighlight.BottomNone, value: Percentile.BottomNone },
  { id: PercentileHighlight.Bottom10, value: Percentile.Bottom10 },
  { id: PercentileHighlight.Bottom20, value: Percentile.Bottom20 },
];

export interface attrAPIcall {
  searchType: FilterCategory,
  filters: FiltersState,
  db: Database,
  userType: UserType,
  traits: SubIndex,
  customASBVs: ASBV[],
  token: CancelToken,
}

export const setResponse = (newData: SearchResponse, currentData: SearchAnimal[], pagination: Pagination) => {
  const response = { ...newData };

  if (pagination.pageNumber !== 1) {
    response.results = currentData.concat(newData.results);
  }

  return response;
};

/**
   * Creates list of sorting options that includes asbvs returned with results. Option id must be a string that will be passed to API.
   * @param result first object returned from results
   */
export const getSortOptions = (result: SearchAnimal) => {
  const { asbvs } = result;
  const asbvsItems: DropdownItem[] = asbvs.map((asbv: Trait) => ({ id: asbv.name, value: asbv.name }));
  const animalIdOption: DropdownItem = { id: AnimalId, value: AnimalId };

  return [animalIdOption, ...asbvsItems];
};
