/**
 * Keys for the pedigree filters map.
 *
 * These keys are also used for applying querystring values to the search api.
 */
export enum PedigreeFilter {
  SireStudName = 'sireStudName',
  SireID = 'sire',
  DamID = 'dam'
}
