export enum PedigreeConstants {
  Title = 'Pedigree',
  Id = 'pedigree',
  SireStudNameLabel = 'Sire Stud Name',
  SireStudNamePlaceholder = 'Enter sire name',
  SireIdLabel = 'Sire ID',
  SirePlaceholder = '16 digit ID',
  DamIdLabel = 'Dam ID',
  DamIdPlaceholder = '16 digit ID',
}
