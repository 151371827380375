/**
 * Global number constants.
 */
export enum AppNumConstants {
  /**
   * Delay to show a loading indicator if data is loading. The indicator
   * should only display if it is still loading after this time.
   */
  LoadIndicatorDelay = 300,
  InputDebounce = 300,
  /**
   * Maximum number of ASBVs (either through filters or customise ASBVs).
   */
  MaxASBVs = 12,
}
