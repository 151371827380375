import { Action } from 'providers/action';
import React, {
  createContext, Dispatch, useContext, useReducer
} from 'react';
import { errorsInitialState, FiltersState } from './filters';
import { ASBVsTab } from './filters/all/asbvs/asbvs-tab.enum';
import { asbvsDefaultState } from './filters/all/asbvs/asbvs-utils';
import { basicDefaultState } from './filters/all/basic';
import { pedigreeDefaultState } from './filters/all/pedigree/pedigree-utils';
import { progenyDefaultState } from './filters/all/progeny/progeny-utils';
import { FiltersStep } from './filters/filters-step.enum';
import { individualDefaultState } from './filters/individual/individual-utils';
import { getPrimeDefaultState } from './prime/prime-context';
import { searchReducer } from './search-reducer';
import { SearchState } from './search-state';

type SearchContext = {
  state: SearchState,
  dispatch: Dispatch<Action>
};

const Search = createContext<SearchContext>({} as SearchContext);

/**
 * The initial state of the `Search` component.
 */
export const getSearchInitialState = (): SearchState => {
  const currentPrimeState = getPrimeDefaultState();

  return {
    filtersStep: FiltersStep.Initial,
    currentFilters: {
      basic: basicDefaultState,
      individual: individualDefaultState,
      progeny: progenyDefaultState,
      pedigree: pedigreeDefaultState,
      asbv: asbvsDefaultState,
      asbvsTab: ASBVsTab.Values,
      expandedFilter: '',
      errors: errorsInitialState,
    } as FiltersState,
    currentPrime: currentPrimeState,
    dataLoading: false,
  };
};

/**
 * Provides the `Search` state and the dispatch function to update it.
 * @param param0
 */
export const SearchProvider: React.FC = ({ children }): JSX.Element => {
  const [state, dispatch] = useReducer(searchReducer, getSearchInitialState());

  const value = { state, dispatch };
  return (
    <Search.Provider value={value}>
      {children}
    </Search.Provider>
  );
};

// (SearchProvider as any).whyDidYouRender = {
//   customName: 'SearchProvider',
// };

/**
 * A helper function to provide the `Search` context.
 */
export const useSearchContext = (): SearchContext => {
  const context = useContext(Search);
  if (!context) {
    throw new Error('useSearchContext must be used within SearchProvider');
  }
  return context;
};
