import { FilterValue } from 'api/query/shared/filter-value';
import { Stud } from 'api/query/stud/stud';
import StudApi from 'api/query/stud/stud-api';
import { CancelToken } from 'axios';
import { DropdownItem } from 'components/dropdown/dropdown-item';
import { ASBVsFilterValue } from './all/asbvs/asbvs-filter-value';
import { ASBVsTab } from './all/asbvs/asbvs-tab.enum';
import { asbvsDefaultState } from './all/asbvs/asbvs-utils';
import { locationDefaultState } from './all/location/location-utils';
import { pedigreeDefaultState } from './all/pedigree/pedigree-utils';
import { progenyDefaultState } from './all/progeny/progeny-utils';
import { Filters } from './filters.enum';

/**
 * Returns true if the filter value for the provided key is the same
 * as the filter value for its initial state.
 * @param key The key to lookup.
 * @param filters The current filters.
 * @param initial The filters initial state.
 */
export const isDefault = (key: any, filters: Map<any, any>, initial: Map<any, any>) => {
  const filterValue = filters.get(key);
  const initialValue = initial.get(key);
  if (filterValue && initialValue) {
    return filterValue.value === initialValue.value;
  }
  return false;
};
/**
 * Gets the default value for a filter.
 * @param key
 * @param initial
 */
export const getDefault = (key: any, initial: Map<any, any>) => initial.get(key);

/**
 * Check if array changed.
 * TODO: change it to compare objects inside than depend on array length.
 * WHY: dropdown in basic search returns selected value as an array even if value's initial type was different
 * and cause mismatch if selected option is default -> sex input,
 * other inputs that are initially an array don't have initial value.
 * @param length
 * @param key
 * @param filters
 * @param initial
 */
const checkArray = (filter: any, key: any, filters: Map<any, any>, initial: Map<any, any>) => {
  let isD: boolean;
  if (filter.length === 1 && initial.get(key).length) {
    isD = filters.get(key)[0].value !== initial.get(key)[0].value;
  } else if (filter.length > 0 && !initial.get(key).length) {
    isD = true;
  } else {
    isD = false;
  }
  return isD;
};

/**
 * Gets a filtered list of filters to be displayed as chips.
 *
 * This only returns filters where they have a value and it is not the default value.
 * @param filters
 * @param initial
 */
export const getChips = (filters: Map<any, any>, initial: Map<any, any>) => Array.from(filters)
  .filter(([key, filter]) => (Array.isArray(filter) ? checkArray(filter, key, filters, initial) : filter && filter.value && !isDefault(key, filters, initial)));

/**
 * Gets the ASBV chip label
 * @param asbv
 */
const getASBVChipLabel = (asbv: ASBVsFilterValue, tab: ASBVsTab) => {
  const suffix = `${tab === ASBVsTab.Percentiles ? '%' : ''}`;

  if (asbv.min && asbv.max) {
    return `${asbv.abbrev} ${asbv.min} - ${asbv.max}${suffix}`;
  }
  if (asbv.min) {
    return `${asbv.abbrev} > ${asbv.min}${suffix}`;
  }
  if (asbv.max) {
    return `${asbv.abbrev} < ${asbv.max}${suffix}`;
  }
  return asbv.abbrev;
};

/**
 * Gets the chips for ASBV filters.
 * @param filters
 */
export const getASBVChips = (filters: Map<string, ASBVsFilterValue>, tab: ASBVsTab) => Array.from(filters).map(([key, filter]) => ([key, { id: key, label: getASBVChipLabel(filter, tab) }]));

/**
 * Removes a chip by returning the default value (single selection controls)
 * or by returning a new list, removing it from the list of values (multiple selection controls)
 * @param key The filter key, used to access the value from the map.
 * @param chip The filter value.
 * @param filters The current filters state.
 * @param initial The intiial filters state.
 */
export const removeChip = (key: any, chip: FilterValue, filters: Map<any, any>, initial: Map<any, any>) => {
  const value = filters.get(key);
  if (Array.isArray(value)) {
    return value.filter((f) => f !== chip);
  }
  return getDefault(key, initial);
};

export const getInitialStateByKey = (key: Filters) => {
  switch (key) {
    case Filters.Progeny:
      return progenyDefaultState;
    case Filters.Pedigree:
      return pedigreeDefaultState;
    case Filters.Location:
      return locationDefaultState;
    case Filters.ASBVS:
      return asbvsDefaultState;
    default:
      return undefined;
  }
};

/**
 * Initial state for the filters errors count.
 * Resetting filters will return the filters errors to this state.
 */
export const errorsInitialState = new Map<Filters, number>([
  [Filters.Individual, 0],
  [Filters.Basic, 0],
  [Filters.Pedigree, 0],
  [Filters.Progeny, 0],
  [Filters.Location, 0],
  [Filters.ASBVS, 0],
]);

export const getStud = async (db: any, keyword?: string, token?: CancelToken) => {
  let studs: Stud[] = [];
  try {
    studs = await StudApi.getByNameOrId(db, keyword, token);
  } catch (e) {
    throw new Error(e);
  }


  const items: DropdownItem[] = studs.map((s) => ({
    id: s.flockId,
    value: `${s.studName}/${s.flockId}`,
  }));

  return items;
};
