import {lazy, mount, redirect, route,} from 'navi';
import React from 'react';
import Login from './pages/account/login';

export default mount({
  '/': redirect('/search/dashboard'),
  '/login': route({
    view: <Login />,
  }),
  '/admin': lazy(() => import('./pages/admin/admin-routes')),
  '/account': lazy(() => import('./pages/account/account-routes')),
  '/animal': lazy(() => import('./pages/animal/animal-routes')),
  '/analysis': lazy(() => import('./pages/analysis/analysis-routes')),
  '/billing': lazy(() => import('./pages/billing/billing-routes')),
  '/catalogue': lazy(() => import('./pages/catalogues/catalogue-routes')),
  '/uploads': lazy(() => import('./pages/submissions/submissions-routes')),
  '/rugg': lazy(() => import('./pages/rugg/rugg-routes')),
  '/report': lazy(() => import('./pages/report/report-routes')),
  '/search': lazy(() => import('./pages/search/search-routes')),
  '/searches': lazy(() => import('./pages/searches/searches-routes')),
  '/user': lazy(() => import('./pages/user/user-routes')),
  '/404': lazy(() => import('./pages/page-not-found/page-not-found-routes')),
  '/401': lazy(() => import('./pages/page-not-authorized/page-not-authorized-routes')),
});
