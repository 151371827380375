import { useCallback } from 'react';
import { FiltersState } from './filters';
import { FiltersStep } from './filters/filters-step.enum';
import { PrimeState } from './prime/prime-state';
import { SearchAction } from './search-action';
import { useSearchContext } from './search-context';

/**
 * Allows access to the search state along with helper functions to dispatch updates.
 */
export const useSearch = () => {
  const { state, dispatch } = useSearchContext();

  const setFiltersStep = useCallback((step: FiltersStep) => dispatch({
    type: SearchAction.SetFiltersStep,
    payload: step,
  }), [dispatch]);

  const setCurrentFilters = useCallback((filters: FiltersState) => dispatch({
    type: SearchAction.SetCurrentFilters,
    payload: filters,
  }), [dispatch]);

  const setCurrentPrime = useCallback((prime: PrimeState) => dispatch({
    type: SearchAction.SetCurrentPrime,
    payload: prime,
  }), [dispatch]);

  const setDataLoading = useCallback((isLoading: boolean) => dispatch({
    type: SearchAction.SetDataLoading,
    payload: isLoading,
  }), [dispatch]);

  /**
   * Resets the search context to its initial state.
   */
  const resetSearch = useCallback(() => dispatch({
    type: SearchAction.Reset,
  }), [dispatch]);

  return {
    filtersStep: state.filtersStep,
    currentFilters: state.currentFilters,
    dataLoading: state.dataLoading,
    currentPrime: state.currentPrime,
    setFiltersStep,
    setCurrentFilters,
    setDataLoading,
    setCurrentPrime,
    resetSearch,
  };
};
