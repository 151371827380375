import { Button, IconButton } from '@material-ui/core';
import { ResetOutlinedIcon } from 'components/icons/reset-outlined';
import React, { useEffect, useState } from 'react';
import { useBreakpoints } from 'utils/use-breakpoints';
import { ResetButtonProps } from './reset-button-props';
import styles from './reset-button.module.scss';

/**
 * A simple button that displays and animates the reset icon.
 *
 * Mobile: Displays as an `IconButton`.
 * Tablet/Desktop: Displays as a `Button` with a start icon.
 * @param props
 */
export const ResetButton: React.FC<ResetButtonProps> = (props) => {
  const {
    variant,
    disabled,
    onClick,
    children,
  } = props;

  const [buttonVariant, setButtonVariant] = useState<'icon' | 'button'>();
  const [rotate, setRotate] = useState<boolean>(false);
  const { phone } = useBreakpoints();

  let timeout: any;

  /**
   * Adds a class to rotate the button icon.
   * @param event
   */
  const rotateIcon = (event: any) => {
    if (onClick) {
      onClick(event);
    }

    setRotate(true);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setRotate(false);
    }, 300); // 300 = animation time defined in stylesheet
  };

  /**
   * Sets the button variant when the component mounts.
   *
   * If a `variant` prop is provided, use that, else default to:
   * - Icon for phone
   * - Button for tablet+
   */
  useEffect(() => {
    const v = phone ? 'icon' : 'button';
    setButtonVariant(variant || v);
  }, [phone, variant]);

  return (
    <>
      <span className={`reset-filters ${rotate ? styles.rotate : ''}`}>
        {buttonVariant === 'icon' && (
          <IconButton
            color="secondary"
            disabled={disabled}
            onClick={(event) => rotateIcon(event)}
          >
            <ResetOutlinedIcon />
          </IconButton>
        )}
        {buttonVariant === 'button' && (
          <Button
            variant="text"
            startIcon={<ResetOutlinedIcon />}
            disabled={disabled}
            onClick={(event) => rotateIcon(event)}
          >
            {children}
          </Button>
        )}
      </span>
    </>
  );
};
