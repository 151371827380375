import { DefaultComponentProps } from './default-component-props';
import { accordionSummary } from './props/accordion-summary';
import { alert } from './props/alert';
import { button } from './props/button';
import { checkbox } from './props/checkbox';
import { chipProps } from './props/chip';
import { circularProgress } from './props/circular-progress';
import { dialogProps } from './props/dialog';
import { iconButton } from './props/icon-button';
import { link } from './props/link';
import { radio } from './props/radio';
import { selectProps } from './props/select';
import { defaultSnackbarProps } from './props/snackbar';
import { switchComponent } from './props/switch';
import { tabs } from './props/tabs';
import { defaultTextFieldProps } from './props/text-field';

/**
 * Default props.
 */
export const props: DefaultComponentProps = {
  MuiAlert: alert,
  MuiButton: button,
  MuiCheckbox: checkbox,
  MuiChip: chipProps,
  MuiCircularProgress: circularProgress,
  MuiDialog: dialogProps,
  MuiAccordionSummary: accordionSummary,
  MuiIconButton: iconButton,
  MuiLink: link,
  MuiRadio: radio,
  MuiSelect: selectProps,
  MuiSnackbar: defaultSnackbarProps,
  MuiSwitch: switchComponent,
  MuiTabs: tabs,
  MuiTextField: defaultTextFieldProps,
};
