import { useCallback, useEffect } from 'react';
import { DropdownItem } from 'components/dropdown/dropdown-item';
import deepEqual from 'deep-equal';
import { useASBVsContext } from './asbvs-context';
import { ASBVsAction } from './asbvs-action.enum';
import { ASBVsFilterValue } from './asbvs-filter-value';
import { asbvsDefaultState } from './asbvs-utils';
import { useFilters } from '../../use-filters';
import { ASBVsTab } from './asbvs-tab.enum';

export const useASBVs = () => {
  const { state, dispatch } = useASBVsContext();
  const { asbv } = useFilters();

  const setItems = useCallback((items: DropdownItem[]) => dispatch({
    type: ASBVsAction.SetItems,
    payload: items,
  }), [dispatch]);

  /**
   * Sets the ASBV currently focussed, used to set focus on
   * the "minimum" input when a ASBV is selected.
   * @param a
   */
  const setFocussedASBV = (a: ASBVsFilterValue | undefined) => dispatch({
    type: ASBVsAction.SetFocussedASBV,
    payload: a,
  });

  /**
   * Sets the list of ASBVs currently selected.
   * @param asbvs
   */
  const setSelectedASBVs = (asbvs: ASBVsFilterValue[]) => dispatch({
    type: ASBVsAction.SetSelectedASBVs,
    payload: asbvs,
  });

  /**
   * Sets the curerntly open tooltip.
   * @param asbv
   */
  const setSelectedTooltip = useCallback((a: ASBVsFilterValue) => dispatch({
    type: ASBVsAction.SetSelectedTooltip,
    payload: a,
  }), [dispatch]);

  /**
   * Toggles the open tooltip.
   *
   * If a tooltip has been clicked and is already open, set the tooltip as nothing.
   * Otherwise set the new opened tooltip id.
   * @param asbv
   */
  const toggleTooltip = useCallback((a: ASBVsFilterValue) => {
    if (state.selectedTooltip === a) {
      setSelectedTooltip({} as ASBVsFilterValue);
    } else {
      setSelectedTooltip(a);
    }
  }, [setSelectedTooltip, state.selectedTooltip]);

  /**
   * Removes an ASBV by removing the chip.
   * @param asbvId
   */
  const removeASBVById = (asbvId: string): ASBVsFilterValue[] => {
    const selected = [...state.selectedASBVs];
    const selectedASBV = selected.find((s) => s.asbv === asbvId) as ASBVsFilterValue;
    selected.splice(selected.indexOf(selectedASBV), 1);
    setSelectedASBVs(selected);

    return selected;
  };

  /**
   * Sets the selected tab.
   */
  const setSelectedTab = useCallback((tab: ASBVsTab) => dispatch({
    type: ASBVsAction.SetSelectedTab,
    payload: tab,
  }), [dispatch]);

  const unfocus = () => {
    if (state.focussedASBV) {
      setFocussedASBV(undefined);
    }
  };

  /**
   * Resets the selected ASBVs.
   */
  const resetASBVs = useCallback(() => dispatch({
    type: ASBVsAction.Reset,
  }), [dispatch]);

  /**
   * Resets the ASBV selected items when a reset filters has occurred.
   */
  useEffect(() => {
    if (deepEqual(asbv, asbvsDefaultState)) {
      resetASBVs();
    }
  }, [asbv, resetASBVs]);

  return {
    state,
    selectedASBVs: state.selectedASBVs,
    focussedASBV: state.focussedASBV,
    selectedTooltip: state.selectedTooltip,
    selectedTab: state.tab,
    items: state.items,
    setItems,
    setFocussedASBV,
    setSelectedASBVs,
    setSelectedTooltip,
    toggleTooltip,
    removeASBVById,
    setSelectedTab,
    unfocus,
    resetASBVs,
  };
};
