import { UserRole } from 'providers/user';
import { hasRole } from 'providers/user/user-utils';
import React, { useEffect, useState } from 'react';
import { useUser } from '../../providers/user/use-user';
import { AuthorizedContentProps } from './authorized-content-props';

/**
 * Renders the children only if the user has the correct permission
 * to view the content based on the user's roles.
 *
 * If no `roles` are provided, it will default to `[UserRole.User]`.
 * @param props
 */
export const AuthorizedContent: React.FC<AuthorizedContentProps> = (props) => {
  const {
    roles = [UserRole.User],
    children,
  } = props;

  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const user = useUser();

  /**
   * Checks if the user has one of the required roles and updates
   * the state to render the children.
   */
  useEffect(() => {
    const isAuthenticated = hasRole(roles, user.roles);

    setAuthenticated(isAuthenticated);
  }, [roles, user.roles]);

  return (
    <>
      {authenticated && children}
    </>
  );
};
