import {UserState, useUserContext} from 'providers/user';

/**
 * Returns the `User` state and any associated actions.
 */
export const useUser = ():UserState => {
  const { state } = useUserContext();

  return {
    ...state,
  };
};
