import { Action } from 'providers/action';
import { SearchAction } from './search-action';
import { getSearchInitialState } from './search-context';
import { SearchState } from './search-state';

export const searchReducer = (state: SearchState, action: Action): SearchState => {
  switch (action.type) {
    case SearchAction.SetFiltersStep:
      return {
        ...state,
        filtersStep: action.payload,
      };
    case SearchAction.SetCurrentFilters:
      return {
        ...state,
        currentFilters: action.payload,
      };
    case SearchAction.SetDataLoading:
      return {
        ...state,
        dataLoading: action.payload,
      };
    case SearchAction.SetCurrentPrime:
      return {
        ...state,
        currentPrime: action.payload,
      };
    case SearchAction.Reset:
      return getSearchInitialState();
    default:
      return state;
  }
};
