import React, {useCallback} from 'react';
import {useNavigation} from 'react-navi';
import {useAuth0} from '@auth0/auth0-react';
import {useAuth0Linking} from '../../../providers/auth0-linking-status/auth0-linking-context';

/**
 * Redirect the user if they have not linked their Auth0 ID to a Sheep Genetics account to the linking / register page
 * Or if they have linked their Auth0 ID with a Sheep Genetics account redirect them to home if they try to link / register
 *
 * This will be placed high up in the DOM.
 *
 * DESIGN CONSIDERATIONS:
 * API Modules are outside the DOM tree
 * Auth0Provider & the Auth0Linking Provider have to work in sequence to obtain this information
 * Putting the call to get the Auth0Linking info is not smart here, as we have to reuse it a lot of places.
 */
export const AccountLinkingRedirector: React.FC = () => {
  const { state: auth0Linking } = useAuth0Linking();
  const { isAuthenticated } = useAuth0();
  const navigation = useNavigation();

  const redirectUserIfRequired = useCallback(async () => {
    if (isAuthenticated) {
      const route = await navigation.getRoute();
      const auth0IsLinked = auth0Linking.isLinked;
      const pathIsForLinkingOnly = route.url.pathname.startsWith('/account');

      if (auth0IsLinked && pathIsForLinkingOnly) {
        await navigation.navigate('/search/dashboard');
      }

      if (!auth0IsLinked && !pathIsForLinkingOnly) {
        if (auth0Linking.doesEmailExist) {
          await navigation.navigate('/account/link');
        } else {
          await navigation.navigate('/account/register');
        }
      }
    }
  }, [auth0Linking.doesEmailExist, auth0Linking.isLinked, isAuthenticated, navigation]);

  if (!auth0Linking.isLoading && !auth0Linking.isLinked) {
    redirectUserIfRequired();
  }

  return (
    <>

    </>
  );
};
