import React, { useState } from 'react';
import './tabs.scss';
import { Tabs as MaterialTabs, Tab } from '@material-ui/core';
import { TabsProps } from './tabs-props';
import { TabPanel } from './tab-panel';

/**
 * A simple custom tabs component. Wrappes tabs buttons and tabs content.
 * Tabs are passed in as props.
 * @param props
 */
export const Tabs: React.FC<TabsProps> = ({ value = 0, onTabChange, ...props }) => {
  const [activeTab, setTab] = useState(value);

  /**
   * Switches active tab
   */
  const switchTab = (event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);
    if (onTabChange) {
      onTabChange(newTab);
    }
  };

  return (
    <>
      <div className="sg-tabs">
        <div className="tabs__buttons">
          <MaterialTabs value={activeTab} onChange={switchTab}>
            {props.children.map((tab, i) => {
              const { label } = tab.props;
              const { isDisabled } = tab.props;

              return (
                <Tab label={label} tabIndex={i} key={label} disabled={isDisabled} />
              );
            })}
          </MaterialTabs>
        </div>
        <div className="tabs__content">
          {React.Children.map(props.children, (child, i) => (
            <TabPanel value={activeTab} index={i}>{child.props.children}</TabPanel>
          ))}
        </div>
      </div>
    </>
  );
};
