import { useTheme, useMediaQuery } from '@material-ui/core';

export const breakpoints = {

};

/**
 * Provides flags for mobile/tablet/desktop media queries.
 *
 * https://material-ui.com/components/use-media-query/#usemediaquery
 */
export const useBreakpoints = () => {
  const theme = useTheme();

  const phone = useMediaQuery(theme.breakpoints.down('xs'));
  const tablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  return { phone, tablet, desktop };
};
