import { Exclude, Expose } from 'class-transformer';
import { ASBVCategory } from './asbv-category.enum';
import { ASBVType } from './asbv-type.enum';

@Exclude()
export class ASBV {
  @Expose({ name: 'asbvType' })
  type: ASBVType;

  @Expose()
  dbType: number;

  @Expose()
  label: string;

  @Expose()
  abbrev: string;

  @Expose()
  description: string;

  @Expose()
  asbvId: string;

  @Expose()
  columnName: string;

  @Expose()
  searchInfo: string;

  @Expose()
  category: ASBVCategory;

  @Expose({ name: 'displayOrder' })
  order: number;

  @Expose({ name: 'traitDefinition' })
  definition: string;

  @Expose()
  freeLunches: string;

  @Expose()
  thingsToWatch: string;

  @Expose()
  message: string;

  /* Describes how the percentiles are directed. Values are:
    NA, bigger,easier,fatter,finer,greater,heavier,higher,improved,longer
    lower,more,more consistent,plainer,shorter,stronger,tender
  */
  @Expose()
  topPercentileDirection: string;
}
