import React, {
  createContext, useReducer, useContext, Dispatch,
} from 'react';
import { ASBVsState } from './asbvs-state';
import { asbvsReducer } from './asbvs-reducer';
import { ASBVsFilterValue } from './asbvs-filter-value';
import { ASBVsTab } from './asbvs-tab.enum';
import { useFilters } from '../../use-filters';

type ASBVsContext = {
  state: ASBVsState,
  dispatch: Dispatch<any>,
}

const ASBVsContext = createContext<ASBVsContext>({} as ASBVsContext);

export const ASBVsProvider: React.FC = ({ children }): JSX.Element => {
  const { asbvsTab } = useFilters();
  const asbvsInitialState: ASBVsState = {
    selectedASBVs: [],
    selectedTooltip: {} as ASBVsFilterValue,
    items: [],
    tab: asbvsTab || ASBVsTab.Values,
  };

  const [state, dispatch] = useReducer(asbvsReducer, asbvsInitialState);
  return (
    <ASBVsContext.Provider value={{ state, dispatch }}>
      {children}
    </ASBVsContext.Provider>
  );
};

export const useASBVsContext = (): ASBVsContext => {
  const context = useContext(ASBVsContext);
  if (!context) {
    throw new Error('useASBVsContext must be used within ASBVsProvider');
  }
  return context;
};
