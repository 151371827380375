import queryApi from 'api/query';
import { CancelToken } from 'axios';
import { plainToClass } from 'class-transformer';
import { Database } from 'shared/database.enum';
import { Stud } from './stud';

/**
 * Handles all requests to the `/stud` resource.
 */
class StudApi {
  private resource = 'stud';

  /**
   * Gets a stud by name or id.
   */
  getByNameOrId = async (db: Database, keyword?: string, token?: CancelToken) => {
    const response = await queryApi.get(
      `/${this.resource}`,
      {
        params: {
          dbType: db,
          keyword,
        },
        cancelToken: token,
      },
    );

    return plainToClass(Stud, response.data as any[]);
  }
}

export default new StudApi();
