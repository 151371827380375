import { Container, Grid, Link } from '@material-ui/core';
import React from 'react';
import { ReactComponent as Logo } from '../../assets/images/logo-mla-white.svg';
import './footer.scss';

export const Footer: React.FC = () => {
  const footerLinks = [
    {
      value: 'Sheep genetics website',
      id: '1',
      url: 'http://www.sheepgenetics.org.au',
    },
    {
      value: 'About us',
      id: '2',
      url: 'http://www.sheepgenetics.org.au/About-Us',
    },
    {
      value: 'Contact us',
      id: '3',
      url: 'http://www.sheepgenetics.org.au/About-us/Contact-us',
    },
    {
      value: 'Terms of use',
      id: '4',
      url: 'https://www.mla.com.au/general/Terms-and-conditions',
    },
    {
      value: 'Privacy policy',
      id: '5',
      url: 'https://www.mla.com.au/general/privacy/',
    },
    {
      value: 'Cookie Policy',
      id: '6',
      url: 'https://www.mla.com.au/general/mla-cookies-policy/',
    },
  ];
  return (
    <>
      <footer className="sg-footer">
        <Container>
          <Grid
            container
            item
            xs={12}
            spacing={0}
          >
            <Grid
              item
              md={4}
              className="sg-footer__container"
            >
              <div>
                <p>© {new Date().getFullYear()} Meat & Livestock Australia Limited</p>
              </div>
              <div className="sg-footer__logo">
                <Link
                  target="_blank"
                  href="http://www.sheepgenetics.org.au/Home"
                  rel="noopener"
                >
                  <Logo />
                </Link>
              </div>
            </Grid>
            <Grid
              item
              md={8}
              className="sg-footer__container"
            >
              <ul className="sg-footer__list">
                {footerLinks.map((item) => (
                  <li
                    className="sg-footer__list-item"
                    key={item.id}
                  >
                    <Link
                      target="_blank"
                      href={item.url}
                      rel="noopener"
                    > {item.value}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </>
  );
};
