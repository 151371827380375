import { CancelToken } from 'axios';
import { plainToClass } from 'class-transformer';
import authApi from '../auth-api';
import { AddFlockAccessResponse, AddPermissionResponse, AuthResponse } from '../auth-response';
import { AccountDetails } from './account-details';
import { MateSelResponse } from './matesel-response';
import { Permission } from './permission';
import { LinkAccountPayload } from './link-account-payload';
import { LinkAccountResponse } from './link-account-response';
import { Auth0LinkingState } from '../../../providers/auth0-linking-status/auth0-linking-state';
import { ResetPasswordPayload } from './reset-password-payload';
import { ValidateTokenPayload } from './validate-token-payload';

/**
 * Provides all methods for the `account` resource.
 */
class AccountApi {
  private resource = 'account';

  /**
   * Validates and confirms an email.
   */
  validateToken = async (payload: ValidateTokenPayload, token?: CancelToken) => {
    const response = await authApi.post(
      `${this.resource}/validate`,
      {
        ...payload,
      },
      {
        cancelToken: token,
      },
    );

    return plainToClass(AuthResponse, response.data);
  }

  /**
   * Requests a password reset email.
   */
  requestResetPasswordEmail = async (email: string) => {
    const response = await authApi.post(
      `${this.resource}/reset`,
      {
        email,
      },
    );

    return plainToClass(AuthResponse, response.data);
  }

  /**
   * Change a users password.
   */
  changePassword = async (payload: ResetPasswordPayload, token?: CancelToken) => {
    const response = await authApi.post(
      `${this.resource}/changepassword`,
      {
        ...payload,
      },
      {
        cancelToken: token,
      },
    );

    return plainToClass(AuthResponse, response.data);
  }

  getAuth0LinkingState = async () => {
    const response = await authApi.get(
      `${this.resource}/auth0/linkingStatus`,
      {
        headers: {
          'Cache-Control': 'no-cache',
        },
      },
    );
    const passedResponse = plainToClass(Auth0LinkingState, response.data);
    passedResponse.isLoading = false;
    return passedResponse;
  };

   linkAccount = async (payload: LinkAccountPayload, token?: CancelToken) => {
     const response = await authApi.post(
       `${this.resource}/auth0/linkAccount`,
       {
         ...payload,
       },
       {
         cancelToken: token,
       },
     );

     return plainToClass(LinkAccountResponse, response.data);
   }

   /**
     * Updates the contact email of the user given their email address in the access token.
     */
   HandleMyMLAEmailChange = async () => {
     await authApi.get(
       `${this.resource}/auth0/handleEmailChange`,
     );
   };

  getUserDetails = async (token?: CancelToken) => {
    const response = await authApi.get(
      `${this.resource}`,
      {
        cache: { maxAge: 0 },
        cancelToken: token,
      },
    );

    return plainToClass(AccountDetails, response.data);
  }

  getPermissionsList = async (token?: CancelToken) => {
    const response = await authApi.get(
      `${this.resource}/permissions`,
      {
        cache: { maxAge: 0 },
        cancelToken: token,
      },
    );

    return plainToClass(Permission, response.data as []);
  }

  addPermission = async (email: string, token?: CancelToken) => {
    const response = await authApi.put(
      `${this.resource}/add-permission`,
      {
        impersonatorId: 0,
        impersonatorEmail: email,
        targetUserId: 0,
        impersonatorName: '',
        roleId: -1,
        role: '',
      },
      {
        cancelToken: token,
      },
    );

    return plainToClass(AddPermissionResponse, response.data);
  }

  revokePermission = async (userId: number, token?: CancelToken) => {
    const response = await authApi.post(
      `${this.resource}/revoke-permission`,
      {
        impersonatorId: userId,
        impersonatorEmail: '',
        targetUserId: 0,
        impersonatorName: '',
        roleId: -1,
        role: '',
      },
      {
        cancelToken: token,
      },
    );

    return plainToClass(AuthResponse, response.data);
  }

  revokeFlockAccess = async (userId: number, flockCode: string, token?: CancelToken) => {
    const response = await authApi.post(
      `${this.resource}/revoke-flock-access`,
      {
        userId,
        flockCode,
      },
      {
        cancelToken: token,
      },
    );

    return plainToClass(AuthResponse, response.data);
  }

  addFlockAccess = async (email: string, flockCode: string, token?: CancelToken) => {
    const response = await authApi.put(
      `${this.resource}/add-flock-access`,
      {
        email,
        flockCode,
      },
      {
        cancelToken: token,
      },
    );

    return plainToClass(AddFlockAccessResponse, response.data);
  }

  updateUserDetails = async (payload: AccountDetails, token?: CancelToken) => {
    const response = await authApi.put(
      `${this.resource}`,
      {
        ...payload,
      },
      {
        cancelToken: token,
        cache: { maxAge: 0 },
      },
    );

    return plainToClass(AuthResponse, response.data);
  }

  /*
    get MateSel info
  */
  getMateSelInfo = async (token?: CancelToken) => {
    const response = await authApi.get(
      `${this.resource}/matesel/access`,
      {
        cache: { maxAge: 0 },
        cancelToken: token,
      },
    );

    return plainToClass(MateSelResponse, response.data);
  }
}

export default new AccountApi();
