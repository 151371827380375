import React from 'react';
import './button.scss';
import { CircularProgress, Button as MaterialButton } from '@material-ui/core';
import { ButtonProps } from './button-props';


/**
 * Extends the Material-UI Button component and supports a loading state.
 * @param props
 */
export const Button: React.FC<ButtonProps> = (props) => {
  const {
    disabled,
    loading = false,
    className,
    children,
    ...buttonProps // Props object with only valid ButtonProps props.
  } = props;

  return (
    <MaterialButton
      {...buttonProps}
      className={`sg-button ${className} ${loading ? 'sg-button--loading' : ''}`}
      disabled={disabled || loading}
    >
      <span className="label-container">
        {children}
      </span>
      <span className="load-container">
        {loading && <CircularProgress color="secondary" size={27} />}
      </span>
    </MaterialButton>
  );
};
