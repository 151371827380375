import React, {
  createContext, useCallback, useContext, useLayoutEffect,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AxiosRequestConfig } from 'axios';
import { useNavigation } from 'react-navi';
import analysisApi from '../../api/analysis';
import authApi from '../../api/auth';
import queryApi from '../../api/query';
import reportApi from '../../api/report';
import submissionsApi from '../../api/submissions';
import { environment } from '../../environment';
import { ApiReadinessState } from './api-readiness-state';

const initialApiReadinessState: ApiReadinessState = {
  isApiReady: false,
};

const ApiReadinessContext = createContext(initialApiReadinessState);

/**
 * This provider checks the status of the users Auth0 authentication and then obtains the access_token.
 * and injects it into all API requests.
 * @param children All children DOM components
 */
export const ApiReadinessProvider: React.FC = ({ children }): JSX.Element => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const apis = [analysisApi, authApi, queryApi, reportApi, submissionsApi];
  const navigation = useNavigation();

  const configureApiToken = useCallback(() => {
    const onRequestFulfillment = async (config: AxiosRequestConfig) => {
      try {
        const token = await getAccessTokenSilently(
          {
            authorizationParams: {
              audience: environment.auth0.audience,
            },
          },
        );
        config.headers.Authorization = `Bearer ${token}`;
      } catch (e) {
      }
      return config;
    };

    apis.forEach((api) => {
      api.interceptors.request.use(
        onRequestFulfillment,
        (error) => Promise.reject(error),
      );
    });
  }, [apis, getAccessTokenSilently]);

  const configureRejectionResponse = useCallback(() => {
    const onResponseRejection = (error: any) => {
      if (error?.response?.status === 401 && isAuthenticated) {
        navigation.navigate('/search/dashboard');
      }
      return Promise.reject(error);
    };
    apis.forEach((api) => {
      api.interceptors.response.use((response) => response, onResponseRejection);
    });
  }, [apis, isAuthenticated, navigation]);

  useLayoutEffect(() => {
    configureRejectionResponse();
    if (isAuthenticated && !isLoading) {
      configureApiToken();
    }
  }, [configureApiToken, configureRejectionResponse, isAuthenticated, isLoading]);

  if (isLoading) {
    return (
      <></>
    );
  }

  return (
    <>
      <ApiReadinessContext.Provider value={{ isApiReady: true }}>
        {children}
      </ApiReadinessContext.Provider>
    </>
  );
};

/**
 * Provide information to the app if the Api is ready to be used.
 * There is a period of time when we are Authenticated with Auth0, but we cannot use the APIs.
 * This is because the getAccessTokenSilently() method takes time to respond.
 *
 * See https://stackoverflow.com/questions/74287876/bearer-token-is-not-set-in-call-to-backend-via-axios-interceptor
 */
export const useApiReadiness = (): ApiReadinessState => {
  const context = useContext(ApiReadinessContext);
  if (context === undefined) {
    throw new Error('useApiReadiness must be used within a ApiReadinessProvider');
  }
  return context;
};
