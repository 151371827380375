import { ProviderContext } from 'providers/provider-context';
import React, {
  createContext, useCallback, useContext, useEffect, useReducer, useState,
} from 'react';
import {
  addToStorage, checkUserStorage, getFromStorage, StorageKey,
} from 'utils/storage-utils';
import { useAuth0 } from '@auth0/auth0-react';
import { ReducerAction } from '../reducer-action';
import { userReducer } from './user-reducer';
import { UserState } from './user-state';
import { UserRole } from './user-role.enum';
import AccountApi from '../../api/auth/account/account-api';
import { AccountDetails } from '../../api/auth/account/account-details';

/**
 * The initial state for the user provider.
 */
export const initialUserState: UserState = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  isAdmin: false,
  isBreeder: false,
  isServiceProvider: false,
  roles: [],
  hasLoadedData: false,
};

const getInitialState = () => {
  const stateBeforeRefresh = getFromStorage(StorageKey.UserState);
  return stateBeforeRefresh ?? initialUserState;
};

const UserContext = createContext<ProviderContext<UserState>>({} as any);

export const UserProvider: React.FC = ({ children }): JSX.Element => {
  const [ready, setReady] = useState<boolean>(false);
  const [state, dispatch] = useReducer(userReducer, getInitialState());
  const value = { state, dispatch };

  const { isLoading, isAuthenticated, user: auth0User } = useAuth0();

  const getUserStateFromDetails = (accountDetails: AccountDetails) :UserState => ({
    id: accountDetails.userId,
    email: accountDetails.email,
    firstName: accountDetails.firstName,
    lastName: accountDetails.lastName,
    isAdmin: accountDetails.roles.includes(UserRole.Admin),
    isBreeder: accountDetails.roles.includes(UserRole.Breeder),
    isServiceProvider: accountDetails.roles.includes(UserRole.ServiceProvider),
    roles: accountDetails.roles,
    hasLoadedData: true,
  });

  // If the user has updated their email in Auth0 we should update it in the Sheep Genetics Database
  const updateEmailIfRequired = useCallback(async (userStateFromApi: UserState) => {
    if (auth0User && auth0User.email && auth0User.email !== userStateFromApi.email) {
      await AccountApi.HandleMyMLAEmailChange();
    }
  }, [auth0User]);

  /**
   * Gets the user details from the AccountApi.
   * If are not returned, return undefined.
   */
  const getUserDetailsFromApi = async () : Promise<UserState | undefined> => {
    const accountDetails = await AccountApi.getUserDetails();
    if (accountDetails) {
      const userState = getUserStateFromDetails(accountDetails);
      updateEmailIfRequired(userState);
      return userState;
    }
    return undefined;
  };

  /**
   * Attempt to get the user details from the Api
   * If the user details are not returned login as guest.
   */

  const setUserDetails = async () => {
    const user = await getUserDetailsFromApi();
    if (user) {
      dispatch({ type: ReducerAction.Set, payload: user });
    } else {
      addToStorage(StorageKey.UserId, '-1');
      checkUserStorage('-1');
    }
  };

  /**
   * Sets the user context by the following priority
   * 1. If exists in local storage use that. (Before page refresh)
   * 2. Get from the API
   */
  useEffect(() => {
    // User State has been retrieved from local storage
    if (state.hasLoadedData) {
      setReady(true);
      return;
    }

    // User is not logged in
    if (!state.hasLoadedData && !isLoading && !isAuthenticated) {
      // Initial state is fine... Just set UserId in local storage
      addToStorage(StorageKey.UserId, '-1');
      checkUserStorage('-1');
      setReady(true);
      return;
    }

    // User is authenticated, but no user data
    if (!state.hasLoadedData && !isLoading && isAuthenticated) {
      setUserDetails();
      setReady(true);
    }
  }, [isLoading, isAuthenticated, state.hasLoadedData, setUserDetails]);

  return (
    <>
      {ready && (
        <UserContext.Provider value={value}>
          {children}
        </UserContext.Provider>
      )}
    </>
  );
};

/**
 * Get the user details from the Account API
 * If the user is not an Authenticated or Authorised users with Sheep Genetics.
 * This will return Guest user details.
 */
export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within UserProvider');
  }
  return context;
};
