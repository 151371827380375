import React from 'react';
import { SelectProps } from '@material-ui/core';
import { ChevronDownOutlinedIcon } from 'components/icons/chevron-down-outlined';

export const selectProps: SelectProps = {
  variant: 'outlined',
  fullWidth: true,
  IconComponent: (props: any) => (<ChevronDownOutlinedIcon {...props} color="primary" />),
  MenuProps: {
    variant: 'menu',
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  },
};
