import { DropdownItem } from 'components/dropdown/dropdown-item';
import { Params } from 'navi';
import { Database } from 'shared/database.enum';
import { SubIndex } from 'shared/sub-index.enum';
import { asBoolean, isNullOrUndefined } from 'utils/utils';
import { IndividualFilter } from '../shared/individual-filter.enum';
import { errorsInitialState, FiltersState } from './filters';
import { ASBVsFilterValue } from './filters/all/asbvs/asbvs-filter-value';
import { ASBVsTab } from './filters/all/asbvs/asbvs-tab.enum';
import { asbvsDefaultState } from './filters/all/asbvs/asbvs-utils';
import {
  basicDefaultState, BasicFilter, getBirthYears, getBreederGroupValues, getBreeds, getRegisteredStatus, getSexes, getWoolTypes
} from './filters/all/basic';
import { LocationFilter } from './filters/all/location/location-filter.enum';
import { getState, locationDefaultState } from './filters/all/location/location-utils';
import { PedigreeFilter } from './filters/all/pedigree/pedigree-filter.enum';
import { pedigreeDefaultState } from './filters/all/pedigree/pedigree-utils';
import { ProgenyFilter } from './filters/all/progeny/progeny-filter.enum';
import { getSireDam, progenyDefaultState } from './filters/all/progeny/progeny-utils';
import { FilterCategory } from './filters/filter-category.enum';
import { individualDefaultState } from './filters/individual/individual-utils';
import { getPrimeDefaultState } from './prime/prime-context';
import { PrimeFilter } from './prime/prime-filter.enum';
import { PrimeState } from './prime/prime-state';
import {
  databaseTypes, getDefaultDatabase, getDefaultSubIndex, getDefaultUserType, getObjectives, userTypes
} from './prime/prime-utils';

/**
 * Converts the filter values to a string, such as `I'm a ram breeder, I want to search Dohnes`.
 */
export const getPrimeSummary = (filters: PrimeState) => {
  let text = `I'm a ${filters.userType.value}, I want to search ${filters.db.value}`;
  if (filters.traits.value) {
    text += ` & I'm interested in ${filters.traits.value}.`;
  } else {
    text += '.';
  }
  return text;
};

/**
 * Returns an array of strings split by `,`.
 *
 * Useful for splitting a querystring that has multiple values, such as `birthYear=2020,2019`.
 * @param params
 */
export const splitParam = (params: string | undefined) => (params ? params.split(',') : '');

/**
 * Gets a value from a querystring param.
 *
 * If the param is falsey
 * - Return nothing.
 *
 * If the param exists and is an array
 * - Loop the param array and find the values from the provided items, return the items.
 *
 * If the param exists and items exist:
 * - Find the item with an id corresponding to the param value.
 * @param param
 * @param items
 */
export const getStateValue = (param: any | string[], items?: any[]) => {
  // If no param value, return
  if (!param) {
    return undefined;
  }

  // If a list of items exist, find the item matching the id from the params.
  if (items && Array.isArray(param)) {
    const values: any[] = [];
    param.forEach((k) => {
      const item = items.find((i) => i.id.toString() === k.toString());
      if (item) {
        values.push(item);
      }
    });
    if (values.length) {
      return values;
    }
  }
  if (items) {
    const item = items.find((i) => i.id === param);
    if (item) {
      return item;
    }
    return undefined;
  }
  return undefined;
};

/**
 * Sets the value of the filter map for a querystring value.
 *
 * - If there is no querystring value, do nothing
 * - If the querystring value is an array (likely a split string),
 * @param filter The filter key
 * @param state The state (map) object
 * @param param The querystring value
 * @param items The items to lookup
 */
export const setStateValue = (filter: any, state: Map<any, any>, param: any | string[], items?: any[]) => {
  // Gets new state for array type filters if param and items exist
  const stateValue = getStateValue(param, items);

  if (stateValue) {
    state.set(filter, stateValue);
  } else {
    const value = state.get(filter);

    // If array type
    if (Array.isArray(value)) {
      if (!param) {
        // But no param leaves state as it is
        state.set(filter, value);
      } else {
        // If array type but with param and no items, sets new state with id and value
        const newValue = param.map((el: string) => ({ id: el, value: el } as DropdownItem));
        state.set(filter, newValue);
      }
    } else if (!isNullOrUndefined(param) && value) {
      state.set(filter, { ...value, value: param });
    }
  }
};

/**
 * Gets the initial state for the individual filters by applying any querystring
 * values to the default values.
 * @param query
 */
export const getIndividualInitialState = (query: Params) => {
  const state = new Map(individualDefaultState);

  setStateValue(IndividualFilter.FlockId, state, query[IndividualFilter.FlockId]);
  setStateValue(IndividualFilter.AnimalId, state, query[IndividualFilter.AnimalId]);

  return state;
};

/**
 * Gets the initial state for the basic filters by applying any querystring
 * values to the default state.
 * @param query
 */
export const getBasicInitialState = (query: Params) => {
  const state = new Map(basicDefaultState);
  const db = parseInt(query.db);

  if (db) {
    setStateValue(BasicFilter.Breed, state, splitParam(query[BasicFilter.Breed]), getBreeds(db));
    setStateValue(BasicFilter.BirthYear, state, splitParam(query[BasicFilter.BirthYear]), getBirthYears());
    setStateValue(BasicFilter.Sex, state, query[BasicFilter.Sex], getSexes());
    setStateValue(BasicFilter.WoolType, state, splitParam(query[BasicFilter.WoolType]), getWoolTypes());
    setStateValue(BasicFilter.BreederGroup, state, query[BasicFilter.BreederGroup], getBreederGroupValues(db));
    setStateValue(BasicFilter.RegisteredStatus, state, query[BasicFilter.RegisteredStatus], getRegisteredStatus());
    setStateValue(BasicFilter.FlockId, state, splitParam(query[BasicFilter.FlockId]));
    setStateValue(BasicFilter.ForSale, state, asBoolean(query[BasicFilter.ForSale]));
    setStateValue(BasicFilter.SemenAvailable, state, asBoolean(query[BasicFilter.SemenAvailable]));
    setStateValue(BasicFilter.ProgenyInReferenceFlock, state, asBoolean(query[BasicFilter.ProgenyInReferenceFlock]));
    setStateValue(BasicFilter.GenomeTested, state, asBoolean(query[BasicFilter.GenomeTested]));
    setStateValue(BasicFilter.MerinoSuperiorSire, state, asBoolean(query[BasicFilter.MerinoSuperiorSire]));
    setStateValue(BasicFilter.PollHornResult, state, asBoolean(query[BasicFilter.PollHornResult]));
    setStateValue(BasicFilter.MerinoLifetimeProductivity, state, asBoolean(query[BasicFilter.MerinoLifetimeProductivity]));
  }

  return state;
};

/**
 * Gets the initial state for the progeny filters by applying any querystring
 * values to the default state.
 * @param query
 */
export const getProgenyInitialState = (query: Params) => {
  const state = new Map(progenyDefaultState);
  const db = parseInt(query.db);

  if (db) {
    setStateValue(ProgenyFilter.SireDam, state, query[ProgenyFilter.SireDam], getSireDam());
    setStateValue(ProgenyFilter.Number, state, query[ProgenyFilter.Number]);
    setStateValue(ProgenyFilter.CurrentDrop, state, asBoolean(query[ProgenyFilter.CurrentDrop]));
    setStateValue(ProgenyFilter.MoreThanOneFlock, state, asBoolean(query[ProgenyFilter.MoreThanOneFlock]));
  }
  return state;
};

/**
 * Gets the initial state for the pedigree filters by applying any querystring
 * values to the default state.
 * @param query
 */
export const getPedigreeInitialState = (query: Params) => {
  const state = new Map(pedigreeDefaultState);
  const db = parseInt(query.db);

  if (db) {
    setStateValue(PedigreeFilter.SireStudName, state, query[PedigreeFilter.SireStudName]);
    setStateValue(PedigreeFilter.SireID, state, query[PedigreeFilter.SireID]);
    setStateValue(PedigreeFilter.DamID, state, query[PedigreeFilter.DamID]);
  }

  return state;
};

/**
 * Gets the initial state for the location filters by applying any querystring
 * values to the default state.
 * @param query
 */
export const getLocationInitialState = (query: Params) => {
  const state = new Map(locationDefaultState);
  const db = parseInt(query.db);

  if (db) {
    setStateValue(LocationFilter.Location, state, query[LocationFilter.Location], getState());
  }

  return state;
};

/**
 * Gets the initial state for the asbv filters by applying any querystring
 * values to the default state.
 * @param query
 */
export const getASBVInitialState = (query: Params) => {
  const state = new Map(asbvsDefaultState);
  const db = parseInt(query.db);
  const tab = parseInt(query.asbvsTab);

  if (db) {
    const asbvParams = query.asbvs ? query.asbvs.split(',') : [];
    if (asbvParams.length) {
      const asbvs: any[] = Array.from({ length: Math.ceil(asbvParams.length / 4) }, (v, i) => asbvParams.slice(i * 4, i * 4 + 4));
      asbvs.forEach((asbv: string[]) => {
        const [abbrev, id, min, max] = asbv;
        state.set(id, {
          asbv: id,
          abbrev,
          min,
          max,
          isPercentile: tab === ASBVsTab.Percentiles,
        });
      });
    }
  }
  return state;
};

/**
 * Gets the initial state for the prime filters by applying any querystring
 * values to the cached or default values.
 *
 * Value priority is querystring > cache > default.
 * @param query
 */
export const getPrimeInitialState = (query: Params) => {
  const state = { ...getPrimeDefaultState() };
  const userType = parseInt(query[PrimeFilter.UserType]);
  const db = parseInt(query[PrimeFilter.Database]);
  // TODO: Update areas where scenario is used in querystring values to use traits instead
  // For now we will expect traits but fallback to scenario if it doesn't exist.
  const traits = parseInt(query[PrimeFilter.Traits]) || parseInt(query.scenario);
  const searchType = query[PrimeFilter.SearchType];

  state[PrimeFilter.UserType] = userType ? getDefaultUserType(userType, userTypes)! : state[PrimeFilter.UserType];
  state[PrimeFilter.Database] = db ? getDefaultDatabase(db, databaseTypes) : state[PrimeFilter.Database];
  if (db) {
    // If a db exists, get the default trait by the querystring value.
    // if no querystring exists just default to an empty object.
    state[PrimeFilter.Traits] = getDefaultSubIndex(traits, getObjectives(db)) || {} as any;
  }
  state[PrimeFilter.SearchType] = searchType ? searchType as FilterCategory : state[PrimeFilter.SearchType];

  return state;
};

/**
 * Gets the initial state for the filters by applying any querystring
 * values to the default state values.
 * @param query
 */
export const getFiltersInitialState = (query: Params) => {
  const state: FiltersState = {
    basic: getBasicInitialState(query),
    individual: getIndividualInitialState(query),
    progeny: getProgenyInitialState(query),
    pedigree: getPedigreeInitialState(query),
    location: getLocationInitialState(query),
    asbv: getASBVInitialState(query),
    asbvsTab: parseInt(query.asbvsTab) || ASBVsTab.Values,
    expandedFilter: '',
    errors: errorsInitialState,
  };
  return state;
};

/**
 * Gets a `Param` value for a filter.
 * @param value
 */
const getParamByFilter = (value: any) => {
  if (Array.isArray(value)) {
    return value.map((v: any) => v.id).toString();
  }
  if (value) {
    return value.id || value.value;
  }
  return '';
};

/**
 * Creates a `Params` object from the filters.
 * @param prime
 * @param filters
 */
export const getParamsByFilters = (prime: PrimeState, filters: FiltersState) => {
  const params: Params = {};

  params[PrimeFilter.UserType] = prime.userType.id.toString();
  params[PrimeFilter.Database] = prime.db.id.toString();
  params[PrimeFilter.Traits] = prime.traits?.id?.toString();
  params[PrimeFilter.SearchType] = prime.searchType;

  const asbvs: string[] = [];

  switch (prime.searchType) {
    case FilterCategory.All:
      filters.basic.forEach((value: any, key: BasicFilter) => {
        params[key] = getParamByFilter(value);
      });
      filters.progeny.forEach((value: any, key: ProgenyFilter) => {
        params[key] = getParamByFilter(value);
      });
      filters.pedigree.forEach((value: any, key: PedigreeFilter) => {
        params[key] = getParamByFilter(value);
      });
      filters.location.forEach((value: any, key: LocationFilter) => {
        params[key] = getParamByFilter(value);
      });
      filters.asbv.forEach((value: ASBVsFilterValue) => {
        asbvs.push(`${value.abbrev},${value.asbv},${value.min},${value.max}`);
      });
      params.asbvs = asbvs.toString();
      params.asbvsTab = filters.asbvsTab.toString();
      break;
    case FilterCategory.Individual:
      filters.individual.forEach((value: any, key: IndividualFilter) => {
        params[key] = getParamByFilter(value);
      });
      break;
    default:
      throw Error('Invalid search type filter');
  }

  return params;
};

/**
 * Checks if all required filters (db or db and trait) were selected.
 * @param db selected database from prime filters
 * @param trait selected trait from prime filters
 */
export const hasDbAndTrait = (db: Database, trait: SubIndex) => {
  const dbsWithTraits = [Database.TerminalAll, Database.TerminalShedders, Database.Merino];

  const expectTrait = dbsWithTraits.filter((database) => database === db);
  let showCard: boolean;

  if (expectTrait.length) {
    showCard = !!(db && trait);
  } else {
    showCard = !!db;
  }

  return showCard;
};
