import {Exclude, Expose} from 'class-transformer';
import {ApiError} from 'api/api-error';

@Exclude()
export class LinkAccountResponse {
  @Expose()
  linkSuccessful: boolean;

  @Expose()
  errors: ApiError[];

}
