import { Snackbar, SnackbarContent } from '@material-ui/core';
import Button from 'components/button';
import React, { useCallback, useEffect, useState } from 'react';
import { addToStorage, getFromStorage, StorageKey } from 'utils/storage-utils';
import './email-change-warning.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { useUser } from '../../../providers/user/use-user';

/**
 * Displays a warning that the user has changed their email address in myMLA.
 */
export const EmailChangeWarning: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);

  const user = useUser();
  const { user: auth0User, isLoading, isAuthenticated } = useAuth0();

  /**
     * Accepts the cookie policy, stores the flag in local storage and hides the toast.
     */
  const accept = () => {
    setShow(false);
    addToStorage(StorageKey.hideEmailChangedMessage, true);
  };

  /**
     * Creates a ReactNode for the cookie policy text and links.
     */
  const getEmailChangeMessage = useCallback(() => (
    <>
      <h1>Email changed message</h1>
      <h4>You have changed your email address with myMLA.
        <br />
        We will continue to use your old email address: {user.email}.
        <br />
        <br />
        Please contact the Sheep Genetics team on 02 8055 1818 to change this.
      </h4>
    </>
  ), [user.email]);

  /**
     * Determines whether the policy has been accepted by getting the value from local storage.
     */
  useEffect(() => {
    const shouldShowMessage = !getFromStorage<boolean>(StorageKey.hideEmailChangedMessage);
    if (!isLoading && isAuthenticated && auth0User && user.hasLoadedData) {
      if (user.email.toUpperCase() !== auth0User.email?.toUpperCase() && shouldShowMessage) {
        setShow(true);
      }
    }
  }, [auth0User, isAuthenticated, isLoading, user.email, user.hasLoadedData]);

  return (
    <Snackbar
      className="sg-email-warning-banner"
      open={show}
      autoHideDuration={null}
      ClickAwayListenerProps={{
        mouseEvent: false,
        touchEvent: false,
      }}
      onClose={() => setShow(false)}
    >
      <SnackbarContent
        message={getEmailChangeMessage()}
        action={(
          <>
            <Button
              variant="text"
              onClick={() => accept()}
            >
              Accept
            </Button>
          </>
                )}
      />
    </Snackbar>
  );
};
