import {
  Checkbox, CircularProgress, FormControl, InputLabel, MenuItem, MenuList, Popper, PopperProps, TextField
} from '@material-ui/core';
import MaterialAutocomplete from '@material-ui/lab/Autocomplete';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import axios, { CancelTokenSource } from 'axios';
import { DropdownItem } from 'components/dropdown/dropdown-item';
import { ChevronDownOutlinedIcon } from 'components/icons/chevron-down-outlined';
import { CloseOutlinedIcon } from 'components/icons/close-outlined';
import { AppNumConstants } from 'constants/app-num-constants.enum';
import React, {
  useEffect, useImperativeHandle, useMemo, useRef, useState
} from 'react';
import { asArray } from 'utils/utils';
import { AutocompleteHandler, AutocompleteProps } from './autocomplete-props';
import './autocomplete.scss';

const AutocompleteRenderFunction: React.ForwardRefRenderFunction<AutocompleteHandler, AutocompleteProps> = (props:AutocompleteProps, forwardedRef) => {
  const {
    multiple,
    grouped,
    items,
    getItems,
    placeholder,
    label,
    item = null,
    noResultsText,
    value = '',
    disabled,
    minLength = 3,
    onDelete,
    onSelectionChange,
    multipleSearch,
    placement = 'bottom-start',
    popperWidth,
    disabledList = [],
  } = props;

  const [isSingle] = useState<boolean>(!multiple);
  const [isMultiple] = useState<boolean>(!!multiple);
  const autocompleteRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<DropdownItem[]>(items);
  const [selectedValue, setSelectedValue] = useState<string>(value);
  const [selectedItem, setSelectedItem] = useState<DropdownItem[]>(asArray(item));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [inputDebounce, setInputDebounce] = useState<number>();
  const [tokenSource, setTokenSource] = useState<CancelTokenSource>(axios.CancelToken.source());
  const [multipleSearchSelection, setMultipleSearchSelection] = useState<DropdownItem[]>(asArray(item));
  const inputRef = useRef<HTMLDivElement>(null);
  const [tagLimit, setTagLimit] = useState<number>(-1);

  /**
   * Gets the rendered elements in the list.
   *
   * Supports highlighting through `autosuggest-highlight`.
   * @param dropdownItem The dropdown item to render.
   * @param val The value entered in the input.
   */
  const getRenderOption = (dropdownItem: DropdownItem, val: string) => {
    const matches = match(dropdownItem.value, val);
    const parts = parse(dropdownItem.value, matches);
    let isChecked = false;
    if (multiple) {
      const si = selectedItem.find((i) => i.id === dropdownItem.id);
      isChecked = !!si;
    }
    return (
      <MenuItem component="div">
        {multiple && <Checkbox checked={isChecked} />}
        <div>
          {parts.map((part) => (
            <span
              key={`${part.text}${Math.random()}`}
              className={`${part.highlight ? 'text--bold' : ''}`}
            >
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    );
  };

  /**
   * Determines whether the popover should open.
   *
   * If a callback to get items is defined, only open after the min chars,
   * otherwise just open.
   * @param inputValue
   */
  const shouldPopoverOpen = (inputValue: string) => {
    if (getItems && inputValue.length < 3) {
      return false;
    }
    return true;
  };

  /**
   * Opens the dropdown.
   *
   * Due to not natively hiding the body overflow, we add a class here to hide it.
   *
   * @param event
   */
  const handleOpen = (event: any) => {
    if (!shouldPopoverOpen(event.target.value || selectedValue)) {
      return;
    }
    setOpen(true);
    document.body.classList.add('sg-dropdown-overflow');
  };

  /**
   * Closes the dropdown and performs some cleanup.
   *
   * Due to no native transition support on the Material-UI `Autocomplete` component,
   * this also handles setting the flag to enable the transition if its opened again.
   *
   * Due to not natively hiding the body overflow, we remove the class here to show it again.
   */
  const handleClose = () => {
    setOpen(false);
    tokenSource.cancel();
    if (error) {
      setError(false);
    }

    // Triggers callback for multiple search on dropdown close
    if (multipleSearch && onSelectionChange) {
      onSelectionChange(multipleSearchSelection);
    }

    document.body.classList.remove('sg-dropdown-overflow');
  };

  /**
   * Resets the autocomplete options (items) to an empty array.
   */
  const resetOptions = () => {
    if (options.length || error) {
      setOptions([]);
      setError(false);
    }
  };

  /**
   * Updates the autocomplete options (items).
   *
   * This resets the current items and sets the loading state.
   *
   * Retrieving the items through the `getItems` callback is handled with a debounce.
   * @param inputVal
   */
  const updateItems = async (inputVal: string) => {
    if (!getItems) {
      return;
    }
    setLoading(true);
    setError(false);
    resetOptions();

    if (inputDebounce) {
      clearTimeout(inputDebounce);
    }

    // Cancel the existing token and create a new one
    tokenSource.cancel();
    const source = axios.CancelToken.source();
    setTokenSource(source);

    // Flag to set load/error state if the request errors
    // TODO: get a better solution for handling this, the `catch` block will run AFTER
    // the input value changes and this function fires again, so if a value is entered
    // then a new value is entered shortly after, the first request is cancelled,
    // the second request sets the loading state to true, but the catch block from the
    // first request will set it to false.
    let request = true;

    // Use a debounce on the input to prevent multiple api requests
    const debounce = window.setTimeout(async () => {
      request = false;

      try {
        const i = await getItems(inputVal, source.token);
        setOptions(i);
        setLoading(false);
        setError(i.length === 0);
      } catch {
        if (request) {
          setLoading(false);
          setError(false);
        }
      }
    }, AppNumConstants.InputDebounce);
    setInputDebounce(debounce);
  };

  /**
   * Sets the state for the input value, if this value was changed either
   * through a reset (clicking the 'x') or deleting all, call the `onReset` callback.
   * @param inputVal
   * @param reason
   */
  const handleInputChange = async (inputVal: any, reason: 'input' | 'reset' | 'clear') => {
    // Programmatic input change
    if (reason === 'reset') {
      return;
    }

    setSelectedValue(inputVal);

    // Input changed due to clicking the 'x'
    if (reason === 'clear' || !inputVal) {
      if (onDelete) {
        onDelete();
      }
      if (getItems) {
        setOpen(false); // Force close the popover when cleared to prevent no results state showing
        resetOptions();
      }
      return;
    }

    // If a getItems callback was provided, use it to fetch the new list
    if (inputVal.length >= minLength) {
      if (isSingle || multipleSearch) {
        updateItems(inputVal);
      }
    } else if (getItems && inputVal.length < 3) {
      setOpen(false);
      setError(false);
      resetOptions();
    }
  };

  /**
   * Sets the selected item (if one exists) or sets it to null and calls
   * `onChange` callback.
   * @param event
   * @param dropdownItem
   */
  const handleChange = (event: any, dropdownItems: DropdownItem | DropdownItem[]) => {
    const i = asArray(dropdownItems);
    setSelectedItem(i);

    // Gets the changed item (added or removed).
    // If the selected item state has more items, one has been removed
    // If it has less items, one has been added.
    let change: DropdownItem[];
    if (selectedItem.length > i.length) {
      change = selectedItem.filter((selected) => !i.includes(selected));
    } else {
      change = i.filter((selected) => !selectedItem.includes(selected));
    }

    const reason = selectedItem.length > i.length ? 'Remove' : 'Add';

    if (onSelectionChange) {
      // Saves new multiple search selection (keeps dropdown open) or triggers callback with new selection (automatically closes dropdown)
      if (multipleSearch && open) {
        setMultipleSearchSelection(asArray(dropdownItems));
      } else {
        onSelectionChange(asArray(dropdownItems), change[0], reason);
      }
    }
  };

  /**
   * Updates multiple search selected items to match filters state.
   */
  useEffect(() => {
    if (multipleSearch) {
      setMultipleSearchSelection(item);
    }
  }, [item, multipleSearch]);

  /**
   * Sets the local `selectedValue` and `selectedItem` state when the item prop changes.
   */
  useEffect(() => {
    if (isSingle) {
      setSelectedValue(item?.value || '');
      setSelectedItem(asArray(item));
    } else {
      setSelectedItem(item);
    }
  }, [isSingle, item]);

  /**
   * Sets the local `selectedValue` state when the value prop changes.
   */
  useEffect(() => {
    if (value) {
      setSelectedValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (grouped) {
      setOptions(items.sort((a, b) => (a.groupId > b.groupId ? -1 : 1)));
    } else {
      setOptions(items);
    }
  }, [grouped, items]);

  useEffect(() => {
    if (inputRef.current && multipleSearch) {
      const parent = inputRef.current.children[0].firstChild as HTMLDivElement;
      const list = Array.from(parent.getElementsByClassName('MuiChip-root'));
      const count = list.filter((c: any) => c.offsetTop > (44 - 5)).length;

      setTagLimit(list.length - count);
    }
  }, [multiple, multipleSearch, selectedItem]);

  /**
   * Override the default popper component to support custom placement.
   */
  const AutocompletePopper = useMemo(() => (p: PopperProps) => (
    <Popper
      {...p}
      style={popperWidth ? { width: popperWidth } : {}}
      placement={placement}
    />
  ), [placement, popperWidth]);

  const getDefaultProps = () => ({
    disableCloseOnSelect: multipleSearch,
    autoHighlight: true,
    clearOnEscape: true,
    open,
    clearText: '',
    openText: '',
    closeText: '',
    closeIcon: (<CloseOutlinedIcon color="primary" />),
    popupIcon: (<ChevronDownOutlinedIcon color="primary" />),
    ref: autocompleteRef,
    options,
    loading,
    openOnFocus: !getItems,
    noOptionsText: noResultsText,
    getOptionSelected: (target: DropdownItem, source: DropdownItem) => target.id === source.id,
    getOptionLabel: (option: any) => option.value || "",
    getOptionDisabled: (option: any) => disabledList.includes(option.id),
    forcePopupIcon: !getItems,
    ListboxComponent: MenuList,
    PopperComponent: AutocompletePopper,
    renderInput: (params: any) => (
      <TextField
        {...params}
        error={error}
        placeholder={(multipleSearch && selectedItem.length) ? '' : placeholder}
      />
    ),
    loadingText: (
      <div className="sg-autocomplete-loading__container">
        <CircularProgress size={18} />
        <span className="loading__label">Loading...</span>
      </div>
    ),
  });

  /**
  * The function called by useImperativeHandle to expose setting AutoComplete from a parent
  * component.
  */
  const setValueFromParent = (newValue:string) => {
    handleInputChange(newValue, 'input');
    handleOpen({ target: { value: newValue } });
  };

  useImperativeHandle(forwardedRef, () => ({
    setValue: (newValue: string) => {
      setValueFromParent(newValue);
    },
    closeDropdown: () => {
      handleClose();
    },
  }));

  return (
    <FormControl className="sg-dropdown">
      {label && (
        <InputLabel
          shrink
        >
          {label}
        </InputLabel>
      )}

      {multipleSearch && (
        <MaterialAutocomplete
          {...getDefaultProps()}
          className={`sg-autocomplete sg-autocomplete--multipleSearch ${selectedValue ? 'sg-autocomplete--value' : ''}`}
          multiple
          disabled={disabled}
          value={selectedItem}
          renderOption={(dropdownItem: DropdownItem, { inputValue }) => getRenderOption(dropdownItem, inputValue)}
          limitTags={tagLimit}
          ref={inputRef}
          onOpen={(event: any) => handleOpen(event)}
          onClose={() => handleClose()}
          onInputChange={(event: any, val: string, reason: 'input' | 'reset' | 'clear') => handleInputChange(val, reason)}
          onChange={(event: any, dropdownItem: any) => handleChange(event, dropdownItem)}
          onBlur={() => setSelectedValue('')}
        />
      )}

      {(isMultiple && !multipleSearch) && (
        <MaterialAutocomplete
          {...getDefaultProps()}
          className={`sg-autocomplete ${selectedValue ? 'sg-autocomplete--value' : ''}`}
          multiple
          disableClearable
          disabled={disabled}
          value={selectedItem}
          renderOption={(dropdownItem, { inputValue }) => getRenderOption(dropdownItem, inputValue)}
          renderTags={() => (<span />)}
          onOpen={(event) => handleOpen(event)}
          onClose={() => handleClose()}
          onInputChange={(event: any, val: string, reason: 'input' | 'reset' | 'clear') => handleInputChange(val, reason)}
          onChange={(event: any, dropdownItem: any) => handleChange(event, dropdownItem)}
        />
      )}
      {isSingle && (
        <MaterialAutocomplete
          {...getDefaultProps()}
          className={`sg-autocomplete ${selectedValue ? 'sg-autocomplete--value' : ''}`}
          multiple={false}
          disabled={disabled}
          value={item}
          inputValue={selectedValue}
          renderOption={(dropdownItem, { inputValue }) => getRenderOption(dropdownItem, inputValue)}
          onOpen={(event) => handleOpen(event)}
          onClose={() => handleClose()}
          onInputChange={(event: any, val: string, reason: 'input' | 'reset' | 'clear') => handleInputChange(val, reason)}
          onChange={(event: any, dropdownItem: any) => handleChange(event, dropdownItem)}
        />
      )}
    </FormControl>
  );
};

export const Autocomplete = React.forwardRef(AutocompleteRenderFunction);
