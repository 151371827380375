import {Action} from '../action';
import {ReducerAction} from '../reducer-action';
import {initialAuth0LinkingState} from './auth0-linking-inital-state';
import {Auth0LinkingState} from './auth0-linking-state';
import {removeApiResults, removeFromStorage, StorageKey} from '../../utils/storage-utils';

export const auth0LinkingReducer = (state: Auth0LinkingState, action: Action) => {
  switch (action.type) {
    case ReducerAction.Set:
      return {
        ...state,
        ...action.payload,
      };
    case ReducerAction.Reset:
      removeApiResults();
      removeFromStorage(StorageKey.Auth0LinkingState);
      return initialAuth0LinkingState;
    default:
      return state;
  }
};
