import {
  Checkbox, Chip, FormControl, InputLabel, InputLabelProps, MenuItem, Select
} from '@material-ui/core';
import deepEqual from 'deep-equal';
import React, {
  useCallback, useEffect, useImperativeHandle, useRef, useState
} from 'react';
import { DropdownItem } from './dropdown-item';
import { DropdownHandler, DropdownProps } from './dropdown-props';
import './dropdown.scss';

/**
 * A simple dropdown, wraps the Material-UI [Select](https://material-ui.com/components/selects/#select) component.
 *
 * This can be used as a single (default) or multiple (by passing the `multiple` prop).
 *
 * @param props The dropdown props, these extend the [Select props](https://material-ui.com/api/select/#select-api).
 */
const DropdownRenderer: React.ForwardRefRenderFunction<DropdownHandler, DropdownProps> = (props, ref) => {
  const {
    items,
    item,
    value,
    multiple,
    label,
    placeholder,
    enableDelete,
    onSelectionChange,
    onDelete,
    inputRef,
    inputProps,
    ...rest
  } = props;
  /**
   * Gets an array of values from the `item` prop.
   *
   * If the prop is an array, it will map the array to strings of the item value.
   * If the prop is not an array, it will wrap the item value as an array.
   */
  const getValues = useCallback(() => {
    if (Array.isArray(item)) {
      return item.map((i) => i.value);
    }
    if (item) {
      return [item.value ? item.value : ''];
    }
    return [''];
  }, [item]);

  const [single] = useState(!multiple);
  const [multi] = useState(multiple);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DropdownItem[]>([item]);
  const [selectedValue, setSelectedValue] = useState<string[]>(getValues());
  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>(items);
  const [overflowEnabled, setOverflowEnabled] = useState(false);
  const [overflowCount, setOverflowCount] = useState(0);
  const chipsRef = useRef<HTMLDivElement>(null);

  const clearId = 'clear-all';

  /**
   * Resets the selected items and values.
   */
  const resetItems = useCallback(() => {
    setSelectedItem([]);
    setSelectedValue(multiple ? [] : ['']);
  }, [multiple]);

  /**
   * Gets the menu items elements.
   */
  const menuItems = () => (
    dropdownItems.map((i) => (
      <MenuItem
        disabled={i.disabled}
        key={i.id}
        value={i.value}
        className={multiple ? 'Mui-multiple' : ''}
      >
        {multiple && <Checkbox checked={!!selectedItem.find((s) => s.id === i.id)} />}
        {i.value}
      </MenuItem>
    ))
  );

  /**
   * Updates the selected value(s) and item(s).
   */
  const updateSelected = useCallback((val: any) => {
    let selected: DropdownItem[];
    if (Array.isArray(val)) {
      setSelectedValue(val);
      // Get the selected items by mapping the selected values and
      // finding the values from the list of items.
      selected = val.map((v: any) => dropdownItems.find((i) => i.value === v)) as DropdownItem[];
    } else {
      setSelectedValue([val]);
      // Get the selected item by filtering the items array by the value.
      selected = dropdownItems.filter((i) => i.value === val);
    }

    setSelectedItem(selected);
    return { selected };
  }, [dropdownItems]);

  /**
   * Handles the 'clear all' in a multi select dropdown.
   */
  const handleReset = () => {
    resetItems();

    if (onSelectionChange) {
      onSelectionChange([]);
    }
    if (onDelete) {
      onDelete();
    }
  };

  /**
   * Handles the selection change callback for non autocomplete dropdowns.
   *
   * Sets the selected value(s) when an item has been selected.
   * @param event
   */
  const handleChange = (event: any, val: any) => {
    // If the reset button was clicked, do nothing.
    if (event.currentTarget.id === clearId) {
      return;
    }

    // Do nothing if single and selecting the same item.
    if (!multiple && [selectedValue] === val && val) {
      return;
    }

    const { selected } = updateSelected(val);

    // If a callback has been provided through the props, call it with the selected item
    if (onSelectionChange) {
      onSelectionChange(selected);
    }
  };

  /**
   * Removes the selected chip from the list of items.
   * @param chip The chip, this matches the `value` property of an item.
   */
  const removeChip = (chip: string) => {
    const newValues = selectedValue.filter((i) => i !== chip);
    const newItems = selectedItem.filter((i) => i.value !== chip);

    setSelectedValue(newValues);
    setSelectedItem(newItems);

    if (onSelectionChange) {
      onSelectionChange(newItems);
    }
  };

  const toggleOpen = (event: any) => {
    // If the event target is the svg delete icon, return.
    // The Material-UI dropdown has no native support for "delete" chips
    // in a Select component with the multiple attribute.
    if (['path', 'svg'].includes(event.target.nodeName)) {
      return;
    }
    setOpen(!open);
  };

  useEffect(() => {
    if (chipsRef.current && multiple) {
      const list = Array.from(chipsRef.current.childNodes as NodeList);

      const parent = chipsRef.current.offsetParent as HTMLDivElement;
      const count = list.filter((c: any) => c.offsetTop > (parent.offsetHeight - 5)).length;
      setOverflowCount(count);
      setOverflowEnabled(count >= 1);
    }
  }, [multiple, selectedValue]);

  /**
   * Sets the local `selectedValue` and `selectedItem` state when the item prop changes.
   */
  useEffect(() => {
    setSelectedValue(getValues());
    setSelectedItem(Array.isArray(item) ? item : [item]);
  }, [getValues, item]);

  /**
   * Sets the local dropdownItems state from the items prop.
   */
  useEffect(() => {
    if (!deepEqual(items, dropdownItems)) {
      setDropdownItems(items);
      resetItems();
    }
  }, [dropdownItems, items, resetItems]);

  useImperativeHandle(ref, () => ({
    setValue: (val: any) => {
      updateSelected(val);
    },
  }));

  return (
    <FormControl className="sg-dropdown">
      {label && (
        <InputLabel
          {...rest as InputLabelProps}
          shrink
        >
          {label}
        </InputLabel>
      )}
      {
        // Single
        single && (
          <Select
            {...rest}
            ref={ref}
            inputRef={inputRef}
            inputProps={inputProps}
            displayEmpty
            open={open}
            value={selectedValue[0]}
            onOpen={toggleOpen}
            onClose={toggleOpen}
            onChange={(e) => handleChange(e, e.target.value)}
            renderValue={(v: any) => (
              <>
                {!v && (<span>{placeholder}&#8203;</span>)}
                {v && v}
              </>
            )}
          >
            {menuItems()}
          </Select>
        )
      }
      {
        // Multiple
        multi && (
          <Select
            {...rest}
            ref={ref}
            inputRef={inputRef}
            inputProps={inputProps}
            displayEmpty
            multiple
            className="select__multiple"
            open={open}
            value={selectedValue}
            onOpen={toggleOpen}
            onClose={toggleOpen}
            onChange={(e) => handleChange(e, e.target.value)}
            renderValue={(values: any) => (
              <>
                {!values.length && (<span>{placeholder}&#8203;</span>)}
                <div
                  className="dropdown__chips-container"
                  ref={chipsRef}
                >
                  {values.map(
                    (itemValue: string) => (
                      <span
                        className="dropdown__chip"
                        key={itemValue}
                      >
                        <Chip
                          label={itemValue}
                          onDelete={() => removeChip(itemValue)}
                        />
                      </span>
                    ),
                  )}
                </div>
                {overflowEnabled && <div className="overflow_count">+{overflowCount}</div>}
              </>
            )}
          >
            <div
              className="sg-dropdown clear-all"
              id={clearId}
              key={clearId}
            >
              <div
                className={!selectedItem.length ? 'clear-all--hidden' : ''}
                onClick={handleReset}
                onKeyDown={resetItems}
                role="none"
              >Clear all
              </div>
            </div>
            {menuItems()}
          </Select>
        )
      }
    </FormControl>
  );
};

export const Dropdown = React.forwardRef(DropdownRenderer);
// (Dropdown as any).whyDidYouRender = true;
