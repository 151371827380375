import { FilterValue } from 'api/query/shared/filter-value';
import { ProgenyFilter } from './progeny-filter.enum';
import { DropdownFilterItem } from '../../models/dropdown-filter-item';
import { CheckboxFilter } from '../../models/checkbox-filter';
import { DropdownFilter } from '../../models/dropdown-filter';
import { ProgenyConstants } from './progeny-constants.enum';

/**
 * Gets the values for the Sire / Dam filter.
 */
export const getSireDam = (): DropdownFilterItem[] => [
  { id: '0', value: ProgenyConstants.SireDamValueAny },
  { id: '1', value: ProgenyConstants.SireDamValueSire },
  { id: '2', value: ProgenyConstants.SireDamValueDam },
];

/**
 * Gets the dropdown filter configs.
 */
export const getDropdowns = (): DropdownFilter[] => [
  {
    key: ProgenyFilter.SireDam,
    label: ProgenyConstants.SireDamLabel,
    values: getSireDam(),
  },
];

/**
 * Gets the checkbox filter configs.
 */
export const getCheckboxes = (): CheckboxFilter[] => [
  {
    key: ProgenyFilter.CurrentDrop,
    label: ProgenyConstants.CurrentDropLabel,
    tooltip: ProgenyConstants.CurrentDropTooltip,
  },
  {
    key: ProgenyFilter.MoreThanOneFlock,
    label: ProgenyConstants.MoreThanOneFlockLabel,
    tooltip: ProgenyConstants.MoreThanOneFlockTooltip,
  },
];

/**
 * The initial state for the Progeny filters.
 *
 * Resetting filters will return the progeny filters to this state.
 */
export const progenyDefaultState = new Map<ProgenyFilter, FilterValue>([
  [ProgenyFilter.SireDam, { id: 0, value: ProgenyConstants.SireDamValueAny }],
  [ProgenyFilter.Number, { id: '', value: '' }],
  [ProgenyFilter.CurrentDrop, { id: '', value: false, label: ProgenyConstants.CurrentDropLabel }],
  [ProgenyFilter.MoreThanOneFlock, { id: '', value: false, label: ProgenyConstants.MoreThanOneFlockLabel }],
]);
