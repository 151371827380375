export enum FiltersAction {
  Search = 'Search',
  /**
   * Updates the filter values.
   */
  Update = 'Update',
  /**
   * Resets the filters to their initial state.
   */
  ResetAll = 'ResetAll',
  /**
   * Reset a single filter section (progeny, pedigree etc).
   */
  Reset = 'Reset',
  /**
   * Resets the selected search type.
   */
  ResetSearchType = 'ResetSearchType',
  /**
   * Sets the selected tab for the ASBVs filters.
   */
  SetASBVsTab = 'SetASBVsTab',
  /**
   * Sets the expanded filter, only one can be expanded at a time.
   */
  SetExpandedFilter = 'SetExpandedFilter',
}
