import { AddOutlinedIcon } from 'components/icons/add-outlined';
import { MinusOutlinedIcon } from 'components/icons/minus-outlined';
import React from 'react';
import './expand-collapse-icon.scss';

/**
 * An icon to be used with an `Accordion`.
 *
 * Shows an `AddOutlinedIcon` when collapsed and an `MinusOutlinedIcon` when expanded.
 * The expanded state is determined by the `.MuiAccordionSummary-expandIcon.Mui-expanded` class.
 */
export const ExpandCollapseIcon: React.FC<{ expanded: boolean }> = ({ expanded }) => (
  <span
    className={`expand-collapse-icon ${expanded ? 'expand-collapse-icon--expanded' : ''}`}
  >
    <AddOutlinedIcon className="icon icon--expand" />
    <MinusOutlinedIcon className="icon icon--collapse" />
  </span>
);
