import {
  AppBar, Button, CircularProgress, Container, Link, Toolbar,
} from '@material-ui/core';
import AccountMenu from 'components/account-menu';
import React, { useMemo } from 'react';
import { useActive, useNavigation } from 'react-navi';
import { useBreakpoints } from 'utils/use-breakpoints';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as SGicon } from '../../assets/images/icon-sg-white.svg';
import { ReactComponent as MLAlogo } from '../../assets/images/logo-mla-white.svg';
import { ReactComponent as SGlogo } from '../../assets/images/logo-sheep-genetics-white.svg';
import './header.scss';
import { useUser } from '../../providers/user/use-user';
import { removeUserDetails } from '../../utils/storage-utils';
import { environment } from '../../environment';

/**
 * The main header element. This is always fixed at the top of the screen.
 */
export const Header: React.FC = () => {
  const { phone } = useBreakpoints();
  const {
    user, loginWithRedirect, isLoading, isAuthenticated,
  } = useAuth0();
  const navigation = useNavigation();

  // Hide the register/login on account pages.
  const isAccount = useActive('/account', { exact: false });

  const sgUser = useUser();

  const displayName = useMemo(() => {
    if (sgUser) {
      return `${sgUser.firstName} ${sgUser.lastName}`;
    }
    return '';
  }, [sgUser]);

  const logIn = () => {
    removeUserDetails();
    navigation.navigate('/login');
  };

  return (
    <>
      <AppBar
        id="sg-header"
        className="sg-header"
        position="relative"
        elevation={0}
      >
        <Container>
          <Toolbar className="sg-header__container">
            <div className="sg-header__logo">
              {phone && (
                <Link
                  className="dashboard__link"
                  href="/search/dashboard"
                >
                  <SGicon />
                </Link>
              )}
              {!phone && (
                <>
                  <Link
                    className="dashboard__link"
                    href="/search/dashboard"
                  >
                    <SGlogo />
                  </Link>
                  <MLAlogo />
                </>
              )}
            </div>
            {!isAccount && (
            <>
              <div className="sg-header__buttonContainer">
                <Link
                  href={environment.auth0.myMLAHomeURL}
                  target="_blank"
                >
                  <Button
                    className="sg-header__headerItem"
                    variant="contained"
                    color="primary"
                  >
                    Go to
                    <img
                      className="sg-header__myMLaLogo"
                      src="/images/myMLALogo.png"
                      alt="My MLA Logo"
                    />
                  </Button>
                </Link>
                { (isLoading || (isAuthenticated && !sgUser.hasLoadedData)) ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <div className="sg-header__actions">
                    {sgUser.hasLoadedData && user
                      ? (
                        <AccountMenu
                          name={displayName}
                          email={sgUser.email ?? ''}
                          myMLAIconUrl={user.picture ?? ''}
                        />
                      )
                      : (
                        <>
                          <Button
                            className="sg-header__headerItem"
                            onClick={logIn}
                          >Login / Register
                          </Button>
                        </>
                      )}
                  </div>
                )}
              </div>
            </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
