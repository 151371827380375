import { ResetButton } from 'components/reset-button/reset-button';
import deepEqual from 'deep-equal';
import React, { useEffect, useState } from 'react';
import { asbvsDefaultState } from '../all/asbvs/asbvs-utils';
import { basicDefaultState } from '../all/basic/basic-utils';
import { locationDefaultState } from '../all/location/location-utils';
import { pedigreeDefaultState } from '../all/pedigree/pedigree-utils';
import { progenyDefaultState } from '../all/progeny/progeny-utils';
import { FilterCategory } from '../filter-category.enum';
import { errorsInitialState } from '../filters-utils';
import { individualDefaultState } from '../individual/individual-utils';
import { useFilters } from '../use-filters';
import { ResetFiltersProps } from './reset-filters-props';

/**
 * Wraps the `ResetButton` component to allow resetting the filters state.
 * @param props
 */
export const ResetFilters: React.FC<ResetFiltersProps> = ({ category }, ...props) => {
  const [enabled, setEnabled] = useState(false);
  const { filters, resetFilters } = useFilters();

  useEffect(() => {
    let e;
    switch (category) {
      case FilterCategory.Individual:
        e = deepEqual(filters.individual, individualDefaultState)
          && deepEqual(filters.errors, errorsInitialState);
        break;
      case FilterCategory.All:
      default:
        e = deepEqual(filters.basic, basicDefaultState)
          && deepEqual(filters.progeny, progenyDefaultState)
          && deepEqual(filters.pedigree, pedigreeDefaultState)
          && deepEqual(filters.location, locationDefaultState)
          && deepEqual(filters.asbv, asbvsDefaultState)
          && deepEqual(filters.errors, errorsInitialState);
        break;
    }

    setEnabled(!e);
  }, [filters, category]);

  return (
    <ResetButton
      {...props}
      disabled={!enabled}
      onClick={() => resetFilters(category)}
    >
      Reset Filters
    </ResetButton>
  );
};
