import { useCallback } from 'react';
import { getInitialStateByKey } from '.';
import { IndividualFilter } from '../../shared/individual-filter.enum';
import { ASBVsFilterValue } from './all/asbvs/asbvs-filter-value';
import { ASBVsTab } from './all/asbvs/asbvs-tab.enum';
import { BasicFilter } from './all/basic/basic-filter.enum';
import { LocationFilter } from './all/location/location-filter.enum';
import { PedigreeFilter } from './all/pedigree/pedigree-filter.enum';
import { ProgenyFilter } from './all/progeny/progeny-filter.enum';
import { FilterCategory } from './filter-category.enum';
import { FiltersAction } from './filters-action';
import { useFiltersContext } from './filters-context';
import { Filters } from './filters.enum';

/**
 * Allows access to the filters state along with helper functions to dispatch updates.
 */
export const useFilters = () => {
  const { state, dispatch } = useFiltersContext();

  /**
   * Updates the `FiltersState` with the new filters.
   * @param filters
   */
  const updateFilters = useCallback((filters: any) => dispatch({
    type: FiltersAction.Update,
    payload: filters,
  }), [dispatch]);

  /**
   * Updates filters state with the new Basic filters.
   * @param filters
   */
  const updateBasicFilters = (filters: Map<BasicFilter, any>) => updateFilters({ basic: filters });

  /**
   * Updates the individual filters on the filters state.
   * @param filters
   */
  const updateIndividualFilters = (filters: Map<IndividualFilter, any>) => updateFilters({ individual: filters });

  /**
   * Updates the progeny filters on the filters state.
   * @param filters
   */
  const updateProgenyFilters = (filters: Map<ProgenyFilter, any>) => updateFilters({ progeny: filters });

  /**
   * Updates filters state with the new Pedigree filters.
   * @param filters
   */
  const updatePedigreeFilters = (filters: Map<PedigreeFilter, any>) => updateFilters({ pedigree: filters });

  /**
   * Updates filters state with the new Location filters.
   * @param filters
   */
  const updateLocationFilters = (filters: Map<LocationFilter, any>) => updateFilters({ location: filters });

  /**
   * Updates filters state with the new ASBV filters.
   * @param filters
   */
  const updateASBVsFilters = (filters: Map<string, ASBVsFilterValue>, tab?: ASBVsTab) => {
    updateFilters({ asbv: filters });
    if (tab) {
      dispatch({ type: FiltersAction.SetASBVsTab, payload: tab });
    }
  };

  /**
   * Updates filters state with the new filter errors.
   * @param filters
   */
  const updateFiltersError = (filters: Map<Filters, number>) => updateFilters({ errors: filters });

  /**
   * Sets the expanded filter, only one can be expanded at a time.
   * @param id The filter accordion id.
   */
  const setExpandedFilter = (id: string) => dispatch({
    type: FiltersAction.SetExpandedFilter,
    payload: id,
  });

  /**
   * Resets the filters to their intial state.
   */
  const resetFilters = useCallback((category: FilterCategory) => dispatch({
    type: FiltersAction.ResetAll,
    payload: category,
  }), [dispatch]);

  /**
   * Resets a single filter map.
   * @param key
   */
  const resetFilter = (key: Filters) => dispatch({
    type: FiltersAction.Reset,
    payload: {
      [key]: getInitialStateByKey(key),
    },
  });

  /**
   * Gets whether a search should be enabled based on entered values and errors.
   *
   * - Individual: Requires at least one valid input and no errors.
   * - All: Requires no errors.
   */
  const searchEnabled = useCallback((category: FilterCategory) => {
    switch (category) {
      case FilterCategory.Individual:
        return !!(state.errors.get(Filters.Individual) === 0
          && (state.individual.get(IndividualFilter.FlockId)?.value || state.individual.get(IndividualFilter.AnimalId)?.value));
      case FilterCategory.All:
      default:
        return state.errors.get(Filters.Basic) === 0
          && state.errors.get(Filters.Progeny) === 0
          && state.errors.get(Filters.Pedigree) === 0
          && state.errors.get(Filters.Location) === 0
          && state.errors.get(Filters.ASBVS) === 0;
    }
  }, [state.errors, state.individual]);

  return {
    filters: state,
    basic: state.basic,
    individual: state.individual,
    progeny: state.progeny,
    pedigree: state.pedigree,
    location: state.location,
    asbv: state.asbv,
    errors: state.errors,
    updateFilters,
    updateBasicFilters,
    updateIndividualFilters,
    updateProgenyFilters,
    updatePedigreeFilters,
    updateASBVsFilters,
    updateLocationFilters,
    updateFiltersError,
    resetFilter,
    resetFilters,
    asbvsTab: state.asbvsTab,
    expandedFilter: state.expandedFilter,
    setExpandedFilter,
    searchEnabled,
  };
};
