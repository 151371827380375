/**
 * Valid email address.
 */
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/**
 * Valid password.
 *
 * Requires:
 * - 1 lowercase
 * - 1 uppercase
 * - 1 number
 * - 8 characters
 */
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
/**
 * Must be exactly 4 digits.
 */
export const postcodeRegex = /^[0-9]{4}$/;
/**
 * A number with up to two decimal places.
 */
export const twoDecimalNumberRegex = /^[0-9]+(\.[0-9]?[0-9])?$/;
export const validStudNameRegex = /^[a-z/d/!@#$%^&*(),.?":{}|<> ]{1,40}$/;
export const validFlockIdRegex = /^[0-9/d/!@#$%^&*(),.?":{}|<> ]{1,6}$/;
/**
 * 16 digits separated by comma, up to 20.
 */
export const validAnimalIdLongRegex = /^([a-zA-Z0-9]{16}\s*,?\s*){1,20}$/;
/**
 * 6 digits separated by comma, up to 20.
 */
export const validAnimalIdShortRegex = /^([a-zA-Z0-9]{6}\s*,?\s*){1,20}$/;

/**
 * Must be number or +
 */
export const phoneNumberRegex = /^[0-9+ ]+$/;

/**
 * Must be up to five digits with up to 2 decimal places
 */
export const priceRegex = /^[0-9]{1,5}(\.[0-9]?[0-9])?$/;


/**
 * Validates an input is a positive numeric value.
 * @param text
 */
export const positiveNumeric = (text: string | undefined): boolean => (!text ? true : !!text.match(/^[1-9]\d*$/));

/**
 * Validates a stud or flock id input.
 *
 * Up to 40 alpha characters OR up to 6 numeric characters, both with space and special characters.
 * @param text
 */
export const validStudFlockID = (text: string | undefined): boolean => (!text ? true : !!text.match(new RegExp(`(${validFlockIdRegex.source})|(${validStudNameRegex.source})`, 'i')));

/**
 * Validates a Sire or Dam ID.
 *
 * Up to 16 alphanumeric characters.
 * @param text
 */
export const validSireDamId = (text: string | undefined): boolean => (!text ? true : !!text.match(/^[a-zA-Z0-9]{16}$/));

/**
 * Validates an ASBV value (numbers with two decimal)
 * @param text
 */
export const validASBVValue = (text: string | undefined): boolean => (!text ? true : !!text.match(/^-?(\d+)?([.]?\d{0,2})?$/));

/**
 * Validates an ASBV percentile (0-100).
 * @param text
 */
export const validASBVPercentile = (text: string | undefined): boolean => (!text ? true : !!text.match(/(^0*\d{2}$)|(^[1-9]?\d|100)$/));

/**
 * Validates an Animal ID.
 *
 * Up to 16 numeric characters for full Animal ID
 * or 6 numeric characters for short version of Animal ID.
 * @param text
 * @param shortVersion
 */
export const validAnimalId = (text: string | undefined, shortVersion: boolean) => {
  let validLength: number;
  if (shortVersion) {
    validLength = 6;
  } else {
    validLength = 16;
  }
  const reg = new RegExp(`^[0-9]{${validLength}}$`);
  return !text ? true : !!text.match(reg);
};

/**
 * Returns error count. Depend if value was peviously valid it manage number of errors - one per input.
 * @param isValid present state of validation
 * @param wasValid previous state of validation
 * @param errState number of filter errors from filter state
 */
export const getErrorCount = (isValid: any, wasValid: any, errState: number) => {
  let newErState: number;
  if (wasValid && !isValid) {
    newErState = errState + 1;
  } else if (!wasValid && isValid) {
    newErState = errState ? errState - 1 : 0;
  } else {
    newErState = errState;
  }

  return newErState;
};
