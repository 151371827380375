import React, { useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Backdrop, CircularProgress, useTheme } from '@material-ui/core';
import { environment } from '../../../environment';

/**
 * Login page.
 * This acts as an intercept page to determine what the user should do when logging in.
 *
 *
 * If using the myMLA web page as a redirect:
 * 1. Check if the user has an access_token. If so redirect to /search/dashboard
 * 2. If not redirect user to myMLA with a redirect back to this page.
 *
 * If not using the myMLA web page as a redirect:
 * Simply login normally calling Auth0 directly. This will redirect to /search/dashboard
 * @param props
 */
export const Login: React.FC = (props) => {
  const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
  // const shouldUseMyMLALoginFlow = environment.auth0.shouldUseMyMlaLoginFlow;
  const theme = useTheme();

  /**
   * Direct Auth0 Login Flow:
   * Redirect to Auth0 and obtain the token from them directly.
   * No redirect from myMLA.
   */
  const directAuth0LoginFlow = useCallback(() => {
    console.log('REACT_APP_USE_MYMLA_LOGIN_FLOW=false');
    loginWithRedirect();
  }, [loginWithRedirect]);

  /**
   * MyMLA Login Flow:
   * 1. Attempt to silently log in
   * 2. If you cannot log in, redirect to the myMLA site.
   */
  // const myMlaLoginFlow = useCallback(async () => {
  //   console.log('using myMLALoginFlow, REACT_APP_USE_MYMLA_LOGIN_FLOW=true');
  //   try {
  //     setTimeout(() => directAuth0LoginFlow(), 60000);
  //     await getAccessTokenSilently();
  //     window.location.href = '/search/dashboard';
  //   } catch (e) {
  //     // User is not logged in or error occurred Go to myMLA
  //     window.location.href = environment.auth0.myMlaRedirectUrl;
  //   }
  // }, [getAccessTokenSilently, directAuth0LoginFlow]);

  useEffect(() => {
    // if (shouldUseMyMLALoginFlow) {
    //   myMlaLoginFlow();
    // } else {
    directAuth0LoginFlow();
    // }
  }, [directAuth0LoginFlow]);

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: theme.zIndex.drawer + 1 }}
        open
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
