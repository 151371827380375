/**
 * The actions used for updating the search state.
 */
export enum SearchAction {
  SetFiltersStep = 'SetFiltersStep',
  SetCurrentFilters = 'SetCurrentFilters',
  SetCurrentPrime = 'SetCurrentPrime',
  SetDataLoading = 'SetDataLoading',
  Reset = 'reset',
}
