import React, {
  memo, useEffect,
} from 'react';
import { useNavigation } from 'react-navi';
import ReactGA from 'react-ga';
import { environment } from 'environment';

/**
 * Initializes and tracks page views for Google Analytics.
 */
export const Analytics: React.FC = memo(() => {
  const navigation = useNavigation();

  useEffect(() => {
    ReactGA.initialize(environment.gaTrackingId);
    navigation.subscribe((route) => {
      if (route.type === 'ready') {
        ReactGA.pageview(route.url.href);
      }
    });
  }, [navigation]);

  return (
    <></>
  );
});
