import { Action } from 'providers/action';
import { ASBVsState } from './asbvs-state';
import { ASBVsAction } from './asbvs-action.enum';

export const asbvsReducer = (state: any, action: Action): ASBVsState => {
  switch (action.type) {
    case ASBVsAction.SetItems:
      return {
        ...state,
        items: action.payload,
      };
    case ASBVsAction.SetASBVs:
      return {
        ...state,
        asbvs: action.payload,
      };
    case ASBVsAction.SetFocussedASBV:
      return {
        ...state,
        focussedASBV: action.payload,
      };
    case ASBVsAction.SetSelectedASBVs:
      return {
        ...state,
        selectedASBVs: action.payload,
      };
    case ASBVsAction.SetSelectedTooltip:
      return {
        ...state,
        selectedTooltip: action.payload,
      };
    case ASBVsAction.SetSelectedTab:
      return {
        ...state,
        tab: action.payload,
      };
    case ASBVsAction.Reset:
      return {
        ...state,
        selectedASBVs: [],
      };
    default:
      return state;
  }
};
