import { Collapse } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { usePrime } from '../prime/use-prime';
import { useSearch } from '../use-search';
import { All } from './all/all';
import { FilterCategory } from './filter-category.enum';
import { FiltersStep } from './filters-step.enum';
import './filters.scss';
import { Individual } from './individual/individual';

export const Filters: React.FC = () => {
  const { filtersStep, setFiltersStep } = useSearch();
  const { primeFilters } = usePrime();

  /**
   * Adds or removes an overflow to the body element to avoid jarring
   * when switching between results/filters due to space being added/removed
   * by the vertical scrollbar.
   */
  const setOverflow = useCallback((overflow: boolean) => {
    if (primeFilters.searchType === FilterCategory.Individual) {
      return;
    }
    if (overflow) {
      document.body.classList.add('overflow');
    } else {
      document.body.classList.remove('overflow');
    }
  }, [primeFilters.searchType]);

  /**
   * Sets the show filter flag when a search type has been selected.
   */
  useEffect(() => {
    if (primeFilters.searchType && filtersStep !== FiltersStep.Results) {
      setFiltersStep(FiltersStep.Full);
    }
  }, [filtersStep, primeFilters.searchType, setFiltersStep]);

  return (
    <div className="sg-filters">
      <Collapse
        in={filtersStep !== FiltersStep.Results}
        mountOnEnter
        unmountOnExit
        onEnter={() => setOverflow(true)}
        onEntered={() => setOverflow(false)}
      >
        {primeFilters.searchType === FilterCategory.Individual && (
          <Individual />
        )}
        {primeFilters.searchType === FilterCategory.All && (
          <All />
        )}
      </Collapse>
    </div>
  );
};
