import {
  DialogProps, Container, Grid, Paper,
} from '@material-ui/core';
import React from 'react';

export const dialogProps: DialogProps = {
  open: false,
  disableBackdropClick: true,
  PaperComponent: (p) => (
    <Paper {...p} elevation={0}>
      <Container>
        <Grid className="dialog__grid" item sm={10}>
          <Paper {...p}>{p.children}</Paper>
        </Grid>
      </Container>
    </Paper>
  ),
};
