import React from 'react';
import { TabPanelProps } from './tab-panel-props';

/**
 * A tab panel component to display tab's content.
 * @param props passes children and values that control if tab panel is shown.
 */
export const TabPanel: React.FC<TabPanelProps> = (props) => (
  <div>
    {props.value === props.index && <div>{props.children}</div>}
  </div>
);
