import { Action } from 'providers/action';
import { FilterCategory } from '../filters/filter-category.enum';
import { FiltersAction } from '../filters/filters-action';
import { getPrimeDefaultState } from './prime-context';
import { PrimeState } from './prime-state';

export const primeReducer = (state: PrimeState, action: Action): PrimeState => {
  switch (action.type) {
    case FiltersAction.Update:
      return { ...state, ...action.payload };
    case FiltersAction.ResetAll:
      return getPrimeDefaultState();
    case FiltersAction.ResetSearchType:
      return {
        ...state,
        searchType: FilterCategory.None,
      };
    default:
      return state;
  }
};
