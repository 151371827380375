import { Exclude, Expose, Transform } from 'class-transformer';
import { Database } from 'shared/database.enum';
import { ASBVPercentile } from './asbv-percentile';

@Exclude()
export class ASBVPercentiles {
  @Expose({ name: 'dbType' })
  db: Database;

  @Expose({ name: 'asbvId' })
  asbv: string;

  @Expose()
  average: number;

  /**
   * Determines whether the industry average section should be displayed.
   *
   * If the average is a number (including 0), then display it.
   * If an average does not exist, do not display.
   */
  @Expose()
  @Transform((value, obj) => typeof obj.average === 'number')
  displayAverage: boolean;

  @Expose()
  percentiles: ASBVPercentile[];
}
