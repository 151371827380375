import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class Stud {
  @Expose()
  studName: string;

  @Expose()
  flockId: string;
}
