import { Container, Grid } from '@material-ui/core';
import { ASBV } from 'api/query/asbv/asbv';
import asbvApi from 'api/query/asbv/asbv-api';
import { DropdownItem } from 'components/dropdown/dropdown-item';
import Tabs, { Tab } from 'components/tabs';
import TooltipButton from 'components/tooltip-button';
import React, {
  memo, useCallback, useEffect, useState
} from 'react';
import { useBreakpoints } from 'utils/use-breakpoints';
import { getASBVChips } from '../..';
import { usePrime } from '../../../prime/use-prime';
import FilterAccordion from '../../filter-accordion';
import { Filters } from '../../filters.enum';
import { useFilters } from '../../use-filters';
import ASBVTooltip from './asbv-tooltip';
import { ASBVsConstants } from './asbvs-constants.enum';
import { ASBVsFilterValue } from './asbvs-filter-value';
import ASBVsSection from './asbvs-section';
import { asbvsToMap } from './asbvs-utils';
import './asbvs.scss';
import { useASBVs } from './use-asbvs';

/**
 * The ASBVs filter section.
 */
export const ASBVs: React.FC = memo(() => {
  const { primeFilters: { db } } = usePrime();
  const { asbv, updateASBVsFilters, errors } = useFilters();
  const [asbvs, setASBVs] = useState<ASBV[]>([]);
  const [filterError, setFilterError] = useState<number>(errors.get(Filters.ASBVS) || 0);
  const {
    items, selectedTab, setItems, selectedASBVs, selectedTooltip, setSelectedASBVs,
    toggleTooltip, removeASBVById, setSelectedTab, unfocus,
  } = useASBVs();
  const { phone } = useBreakpoints();

  /**
   * Resets the ASBV min/max values.
   */
  const resetValues = useCallback(() => {
    const selected = [...selectedASBVs];
    for (const s of selected) {
      s.min = '';
      s.max = '';
    }
  }, [selectedASBVs]);

  /**
   * Sets the open tooltip and removes the selected ASBV.
   *
   * Removing the ASBV allows losing focus on the selected input.
   * @param asbv
   */
  const onTooltipOpen = (asbvFilter: ASBVsFilterValue) => {
    unfocus();
    toggleTooltip(asbvFilter);
  };

  const onTabChange = (tab: number) => {
    resetValues();
    setSelectedTab(tab);
  };

  /**
   * Removes an ASBV by removing the chip.
   * @param asbvId
   */
  const deleteASBV = (asbvId: string) => {
    const selected = [...selectedASBVs];
    const selectedASBV = selected.find((s) => s.asbv === asbvId) as ASBVsFilterValue;
    selected.splice(selected.indexOf(selectedASBV, 1));
    setSelectedASBVs(selected);

    updateASBVsFilters(asbvsToMap(removeASBVById(asbvId)), selectedTab);
  };

  /**
   * Creates `DropdownItem`s from the ASBVs.
   */
  const getDropdownItems = useCallback(() => {
    const i: DropdownItem[] = asbvs.map((a) => ({
      id: a.asbvId,
      value: `${a.label} (${a.abbrev})`,
    }));
    setItems(i);
  }, [asbvs, setItems]);

  /**
   * Gets the ASBVs for the selected database.
   */
  useEffect(() => {
    let cancelled = false;
    const getASBVs = async () => {
      const data = await asbvApi.get(db.id);

      if (cancelled) {
        return;
      }

      setASBVs(data);
    };
    getASBVs();

    return () => {
      cancelled = true;
    };
  }, [db.id, setASBVs]);

  /**
   * Creates the `DropdownItem`s when the list of ASBVs change.
   */
  useEffect(() => {
    getDropdownItems();
  }, [asbvs, getDropdownItems]);

  /**
   * Disables any selected ASBVs from the dropdown items.
   */
  useEffect(() => {
    for (const item of items) {
      item.disabled = !!selectedASBVs.find((s) => s.asbv === item.id);
    }
  }, [items, selectedASBVs]);

  /**
   * Updates filter error and pass it to filter accordion.
   */
  useEffect(() => {
    setFilterError(errors.get(Filters.ASBVS) || 0);
  }, [errors]);

  /**
   * Sets the initial selected ASBV values based on the filters state.
   *
   * The filters size can differ to the selected length if the ASBVs
   * are changed through the `CustomiseASBVs` modal.
   */
  useEffect(() => {
    if (asbvs.length && asbv.size !== selectedASBVs.length) {
      const selected = Array.from(asbv.values());
      setSelectedASBVs(selected);
      updateASBVsFilters(asbvsToMap(selected));
    }
  }, [asbv, asbvs, selectedASBVs, setSelectedASBVs, updateASBVsFilters]);

  return (
    <Container className="sg-asbvs">
      <FilterAccordion
        title={ASBVsConstants.Title}
        id={Filters.ASBVS}
        chips={getASBVChips(asbv, selectedTab)}
        onChipDelete={([id]) => deleteASBV(id)}
      >
        <Grid
          className="help-container"
          container
          item
          xs={12}
        >
          <div className="text--sm">{ASBVsConstants.HelpText}</div>
        </Grid>
        <Grid
          className="container--margin"
          container
        >
          <Grid
            container
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Tabs
              value={selectedTab}
              onTabChange={(tab) => onTabChange(tab)}
            >
              <Tab
                tabId="1"
                label="Values"
              >
                <ASBVsSection
                  asbvs={asbvs}
                  selectedASBVs={selectedASBVs}
                  type="value"
                  getActualErCount={filterError}
                />
              </Tab>
              {/* <Tab tabId="2" label="Percentiles">
                <ASBVsSection
                  asbvs={asbvs}
                  selectedASBVs={selectedASBVs}
                  type="percentile"
                  getActualErCount={filterError}
                />
              </Tab> */}
              <Tab
                isDisabled
                tabId="2"
                label=""
              >
                <ASBVsSection
                  asbvs={asbvs}
                  selectedASBVs={selectedASBVs}
                  type="percentile"
                  getActualErCount={filterError}
                />
              </Tab>
            </Tabs>
          </Grid>
          {!phone
            && (
              <Grid
                className="tooltip-container"
                container
                item
                xs={12}
                sm={6}
                md={8}
              >
                {selectedASBVs.map((a) => (
                  <Grid
                    key={a.asbv}
                    className={`tooltip-container__grid ${a.errors ? 'tooltip-container__grid--large' : ''}`}
                  >
                    <TooltipButton
                      type="relative"
                      placement="right"
                      tooltipOpen={selectedTooltip.asbv === a.asbv}
                      tooltip={<ASBVTooltip asbv={(asbvs.find((s) => s.asbvId.toString() === a.asbv.toString()) as ASBV)} />}
                      onTooltipOpen={() => onTooltipOpen(a)}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
        </Grid>
      </FilterAccordion>
    </Container>
  );
});
