import {Sex} from 'shared/sex.enum';

/**
 * Returns true if a value is `null` or `undefined`, else false.
 * @param value
 */
export const isNullOrUndefined = (value: any) => value === null || value === undefined;

/**
 * Converts a `true` or `false` string to a boolean.
 * @param val
 */
export const asBoolean = (val: string|undefined): boolean|undefined => {
  if (val === undefined) {
    return val;
  }
  switch (val?.toLowerCase()) {
    case 'true':
      return true;
    case 'false':
    default:
      return false;
  }
};

/**
 * Converts the value to an array.
 *
 * - If the value is already an array, return the value.
 * - If the value is defined but not an array, convert it to an array.
 * - If the value is undefined, return an empty array.
 * @param val
 */
export const asArray = (val: any): any[] => {
  if (Array.isArray(val)) {
    return val;
  }
  if (val) {
    return [val];
  }
  return [];
};

/**
 * Adds an `s` to the end of the provided value if the number is > 1.
 * @param value
 * @param num
 */
export const asPlural = (value: string, num: number) => {
  if (value && num > 1) {
    return `${value}s`;
  }
  return value;
};

/**
 * Gets the string description of a sex id.
 * @param sex
 */
export const getSexDesc = (sex: Sex) => {
  switch (sex) {
    case Sex.Any:
      return 'Any';
    case Sex.Male:
      return 'Male';
    case Sex.Female:
      return 'Female';
    default:
      throw new Error('Unknown sex provided');
  }
};

/**
 * Returns dash if value is not stated.
 * @param value
 */
export const formatNoValueToDash = (value: any) => (value === null || value === '' ? '—' : value);
