import { asBoolean } from './utils/utils';

export const environment = {
  version: process.env.REACT_APP_VERSION,
  endpoint: (service: string) => {
    let endPointURL = process.env.REACT_APP_ENDPOINT;
    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
      endPointURL = 'http://localhost';
      switch (service.toUpperCase()) {
        case 'QUERY':
          endPointURL += ':5000';
          break;
        case 'AUTH':
          endPointURL += ':5001';
          break;
        case 'ANALYSIS':
          endPointURL += ':5002';
          break;
        case 'SUBMISSION':
          endPointURL += ':5003';
          break;
        case 'REPORT':
          endPointURL += ':5004';
          break;
        default:
          endPointURL = process.env.REACT_APP_ENDPOINT;
          break;
      }
    }
    return endPointURL;
  },
  gaTrackingId: process.env.REACT_APP_GA_ID || '',
  mateselLink: process.env.REACT_APP_MATESEL_LINK_ENDPOINT,
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    logInRedirectUri: process.env.REACT_APP_AUTH0_LOGIN_REDIRECT_URL || '',
    logOutRedirectUri: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URI || '',
    myMlaRedirectUrl: process.env.REACT_APP_AUTH0_MYMLA_REDIRECT_URL || '',
    shouldUseMyMlaLoginFlow: asBoolean(process.env.REACT_APP_USE_MYMLA_LOGIN_FLOW) ?? false,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
    myMLAHomeURL: process.env.REACT_APP_AUTH0_MYMLA_WEBPAGE || '',
  },
};
