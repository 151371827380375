import { FilterValue } from 'api/query/shared/filter-value';
import { IndividualFilter } from '../../../shared/individual-filter.enum';

/**
 * The initial state for the Individual animal filters.
 *
 * Resetting filters will return the individual filters to this state.
 */
export const individualDefaultState = new Map<IndividualFilter, FilterValue>([
  [IndividualFilter.FlockId, { value: '' }],
  [IndividualFilter.AnimalId, { value: '' }],
]);
