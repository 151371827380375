import {
  Accordion as MaterialAccordion, AccordionDetails, AccordionSummary, Button, Divider, IconButton, Slide
} from '@material-ui/core';
import ExpandCollapseIcon from 'components/expand-collapse-icon';
import { ChevronLeftOutlinedIcon } from 'components/icons/chevron-left-outlined';
import { ChevronRightOutlinedIcon } from 'components/icons/chevron-right-outlined';
import React, { useEffect, useRef, useState } from 'react';
import { useBreakpoints } from 'utils/use-breakpoints';
import { AccordionProps } from './accordion-props';
import './accordion.scss';

export const Accordion: React.FC<AccordionProps> = (props) => {
  const {
    title,
    expanded,
    panelSummaryIcon,
    panelSummaryContent,
    enableFullScreenFooterAction,
    fullScreenFooterActionProps,
    fullScreenFooterActionLabel = 'Close',
    enableFullScreenStickyHeader,
    enableFullScreenHeaderBack = enableFullScreenStickyHeader && !enableFullScreenFooterAction,
    fullScreenHeaderAction,
    onExpand,
    children,
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const [full, setFull] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const { phone } = useBreakpoints();
  const fullScreenHeaderRef = useRef<HTMLDivElement>(null);

  /**
   * Gets the expand icon, for mobile use a '>' otherwise use a '+'.
   */
  const getExpandIcon = () => (
    <span hidden={fullScreen}>
      {phone ? <ChevronRightOutlinedIcon /> : <ExpandCollapseIcon expanded={isExpanded} />}
    </span>
  );

  /**
   * Toggles the expanded state.
   * @param e
   */
  const toggleExpanded = (e: boolean) => {
    setFull(e);
    // Use a timeout for phone/fullscreen to allow animation to play out
    setTimeout(() => {
      setIsExpanded(e);
      if (onExpand) {
        onExpand(e);
      }
    }, fullScreen ? 125 : 0);
  };

  /**
   * Adds or removes the botom shadow when scrolling in full screen.
   * @param event
   */
  const scroll = (event: React.UIEvent<HTMLDivElement>) => {
    // eslint-disable-next-line prefer-destructuring
    const target = event.target as HTMLDivElement;
    if (target.scrollTop === 0) {
      fullScreenHeaderRef.current!.classList.remove('shadow-bottom');
    } else {
      fullScreenHeaderRef.current!.classList.add('shadow-bottom');
    }
  };

  /**
   * Sets fullscreen state when in mobile and expanded.
   */
  useEffect(() => {
    setFullScreen(phone && isExpanded);
  }, [phone, isExpanded]);

  /**
   * Sets overflow: hidden on the body when in full screen mode (mobile)
   */
  useEffect(() => {
    if (fullScreen) {
      document.body.classList.add('ovh');
      document.getElementById('root')!.classList.add('ovh');
    } else {
      document.body.classList.remove('ovh');
      document.getElementById('root')!.classList.remove('ovh');
    }
  }, [fullScreen]);

  /**
   * Sets the expanded state when the `expanded` prop changes.
   */
  useEffect(() => {
    setIsExpanded(expanded || false);
  }, [expanded]);

  return (
    <div className="sg-accordion">
      <MaterialAccordion
        square
        elevation={0}
        expanded={isExpanded}
        TransitionProps={{ mountOnEnter: true }}
        onChange={(event: any, e: boolean) => toggleExpanded(e)}
      >
        {/** Header */}
        <AccordionSummary
          disabled={fullScreen}
          expandIcon={getExpandIcon()}
        >
          <div className="accordion-summary">
            {panelSummaryIcon && <span className="accordion-summary__icon">{panelSummaryIcon}</span>}
            <h3 className="accordion-summary__title">{title}</h3>
          </div>
          {panelSummaryContent && panelSummaryContent}
        </AccordionSummary>
        {/** Container, standard expanded (tablet, desktop) */}
        {!fullScreen && (
          <div className="accordion-content">
            <div className="accordion-content__container">
              {/** Panel details */}
              <AccordionDetails>
                {children}
              </AccordionDetails>
            </div>
          </div>
        )}
        {/** Container, full screen (phone) */}
        {fullScreen && (
          <Slide in={full} direction="left">
            <div
              className={`accordion-content ${fullScreen ? 'accordion-content--full' : ''}`}
              onScroll={scroll}
            >
              <div className={`accordion-content__container ${enableFullScreenStickyHeader ? 'accordion-content__container--sticky' : ''}`}>
                {/** Display summary with full screen for phone */}
                <AccordionSummary
                  className="accordion-content__header"
                  ref={fullScreenHeaderRef}
                >
                  {enableFullScreenHeaderBack && (
                    <span className="header__back">
                      <IconButton
                        color="secondary"
                        onClick={() => toggleExpanded(false)}
                      >
                        <ChevronLeftOutlinedIcon />
                      </IconButton>
                    </span>
                  )}
                  <h3 className="header__title">{title}</h3>
                  {fullScreenHeaderAction && (
                    <span className="header__actions">
                      {fullScreenHeaderAction}
                    </span>
                  )}
                </AccordionSummary>
                {/** Panel details */}
                <AccordionDetails>
                  {children}
                </AccordionDetails>
              </div>
              {/** Accordion actions for full screen */}
              {enableFullScreenFooterAction && (
                <div className="accordion-content__actions">
                  <Divider className="actions__divider" />
                  <Button
                    {...fullScreenFooterActionProps}
                    fullWidth
                    onClick={() => toggleExpanded(false)}
                  >
                    {fullScreenFooterActionLabel}
                  </Button>
                </div>
              )}
            </div>
          </Slide>
        )}
      </MaterialAccordion>
      <Divider className={`accordion-divider ${isExpanded ? 'accordion-divider--expanded' : ''}`} />
    </div>
  );
};
